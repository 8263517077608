import React, { Component } from 'react';
import _ from 'lodash';
import {
  withStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Form } from 'react-final-form'

import { FormField } from 'components';

import styles from './styles';
import getFields from './fields';

class UserForm extends Component {

  state = {
    isChangingPassword: false,
    fields: null,
  }

  async componentDidMount() {
    this.setState({
      fields: await getFields(),
    });
  }
  
  validate(values) {
    const { user } = this.props;
    const { isChangingPassword } = this.state;
    let errors = {};

    if (!values.firstName) {
      errors.firstName = 'Privalomas laukas';
    }

    if (!values.email) {
      errors.email = 'Privalomas laukas';
    }

    if (!user || isChangingPassword) {
      if (!values.password) {
        errors.password = 'Privalomas laukas';
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = 'Privalomas laukas';
      }

      if((values.password && values.confirmPassword) && (values.password != values.confirmPassword)) {
        errors.confirmPassword = 'Slaptažodžiai nesutampa';
      }
    }

    return errors;
  }

  render() {
    const {
      classes,
      user,
      onSubmit,
    } = this.props;

    const { isChangingPassword } = this.state;
    const showPasswordFields = (!user && !isChangingPassword) || (user && isChangingPassword);

    return (
      <Form
        initialValues={{ password: '', ...user }}
        onSubmit={onSubmit}
        validate={this.validate.bind(this)}
        render={({ handleSubmit }) => (
          <form
            id="userForm"
            className={classes.form}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            
            {_.map(this.state.fields, field => <FormField key={field.name} {...field}/>)}

            {user ? (
              <div>
                <FormControlLabel
                  label="Keisti slaptažodį"
                  className={classes.checkboxWrap}
                  control={
                    <Checkbox
                      checked={isChangingPassword}
                      onChange={({ target }) => {this.setState({ isChangingPassword: target.checked })}}
                    />
                  }
                />
              </div>
            ) : null}

            {showPasswordFields ? (
              <div>
                <FormField name="password" type="password" label="Slaptažodis" autoComplete="new-password"/>
                <FormField name="confirmPassword" type="password" label="Pakartokite slaptažodį"/>
              </div>
            ) : null}

            
          </form>
        )}
      />
    );
  }
}

export default withStyles(styles)(UserForm);
