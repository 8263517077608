const constants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  
  LOGOUT: 'USERS_LOGOUT',

  TERMS_AGREED: 'USERS_TERMS_AGREED',

  SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

  ACTIVATION_REQUEST: 'USERS_ACTIVATION_REQUEST',
  ACTIVATION_SUCCESS: 'USERS_ACTIVATION_SUCCESS',
  ACTIVATION_FAILURE: 'USERS_ACTIVATION_FAILURE',

  CHECK_EMAIL_REQUEST: 'USERS_CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: 'USERS_CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE: 'USERS_CHECK_EMAIL_FAILURE',

  SESSION_VALID: 'USERS_SESSION_VALID',
  SESSION_INVALID: 'USERS_SESSION_INVALID',
  SESSION_REQUEST: 'USERS_SESSION_REQUEST',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  GETONE_REQUEST: 'USERS_GETONE_REQUEST',
  GETONE_SUCCESS: 'USERS_GETONE_SUCCESS',
  GETONE_FAILURE: 'USERS_GETONE_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  CREATE_REQUEST: 'USERS_CREATE_REQUEST',
  CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
  CREATE_FAILURE: 'USERS_CREATE_FAILURE',

  HARD_DELETE_REQUEST: 'USERS_HARD_DELETE_REQUEST',
  HARD_DELETE_SUCCESS: 'USERS_HARD_DELETE_SUCCESS',
  HARD_DELETE_FAILURE: 'USERS_HARD_DELETE_FAILURE',

  RESET_FORM: 'USERS_RESET_FORM',
};

export default constants;