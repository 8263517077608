import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  Button,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { FormField2, CommonPanel } from 'components';
import ServicePackages from 'views/ServicePackages';
import OrderItemModal from './components/OrderItemModal';
import { twoDecimals, timestampToDate } from 'utils/helpers';

import styles from './styles';

class Container extends React.Component {
  state = {
    showItemModal: false,
    editingItem: null,
    editingItemIdx: null,
  }

  componentDidMount() {
    const { match } = this.props;

    this.props.store.loadData(match && match.params.id ? match.params.id : null);
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  onChange({ name, value }) {
    this.props.store.setRecordValue(name, value);
  }

  onShowItemModal() {
    this.setState({ showItemModal: true });
  }

  onCloseItemModal() {
    this.setState({ showItemModal: false });
  }

  onItemSave(data) {

    if(_.isNil(this.state.editingItemIdx)) {
      this.props.store.addItem(data);
    } else {
      this.props.store.updateItem(this.state.editingItemIdx, data);
    }

    this.onCloseItemModal();
  }

  onAddItem() {
    this.setState({ editingItem: null, editingItemIdx: null });

    this.onShowItemModal();
  }

  onRemoveItem(idx) {
    if (!window.confirm('Ar tikrai norite pašalinti?')) {
      return;
    }

    this.props.store.removeItem(idx);
  }

  onEditItem(idx) {
    const item = this.props.store.record.items[idx];

    this.setState({ editingItem: item, editingItemIdx: idx });
    this.onShowItemModal();
  }

  save(submit = false) {
    const { record } = this.props.store;

    const total = _.get(record, 'totals.grandTotal');

    if(!total || _.toNumber(total) < 0) {
      window.alert('Užsakymo suma negali būti neigiamas skaičius');
      return;
    }

    if (this.props.store.isAdmin && !record.company) {
      window.alert('Neparinkta įmonė, kuriai priskirti užsakymą');
      return;
    }

    if (record._id) {
      this.props.store.update(submit);
    } else {
      this.props.store.create(submit);
    }
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;
    
    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? _.get(record, name) : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderItemRules(item) {
    const { record } = this.props.store;

    const titles = _.map(item.rules, rule => {
      const itemRule = _.find(record.allRules, { rule });

      return itemRule ? itemRule.title.trim() : null;
    });
    
    return (
      <ul style={{ listStyle: 'none' }}>
        {titles.length ? _.map(titles, (title, idx) => <li key={`details-${title}-${idx}`}>{title}</li>) : null}
      </ul>
    )
  }

  renderTable() {
    const { classes } = this.props;
    const { record, getServiceTitle, getServicePackageTitle, isReadonly } = this.props.store;
    const hasItems = record.items && record.items.length;

    const rows = _.map(record.items, (item, idx) => {
      return (
        <TableRow key={`item-${idx}`}>
          <TableCell>{idx + 1}</TableCell>
          <TableCell>{getServiceTitle(item.service)}</TableCell>
          <TableCell>{getServicePackageTitle(item.servicePackage)}</TableCell>
          <TableCell>{this.renderItemRules(item)}</TableCell>
          <TableCell align="center">{item.licences}</TableCell>
          <TableCell align="right">{twoDecimals(item.price)}</TableCell>
          <TableCell className="actions">
            {!isReadonly ? (
              <>
                <Button onClick={() => this.onEditItem(idx)} color="primary">
                  Redaguoti
                </Button>
                
                <Button onClick={() => this.onRemoveItem(idx)} color="primary">
                  Pašalinti
                </Button>
              </>
            ) : null}
          </TableCell>
        </TableRow>
      )
    });

    return (
      <TableContainer>
        <Table
          className={classes.itemsTable}
          // size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Paslauga</TableCell>
              <TableCell>Paketas</TableCell>
              <TableCell>Funkcionalumas</TableCell>
              <TableCell>Darbo vietų</TableCell>
              <TableCell>Kaina, EUR</TableCell>
              <TableCell className="actions" />
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length ? rows : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Nėra pasirinktų paslaugų
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
            )}

            {!this.props.store.isReadonly ? (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onAddItem.bind(this)}
                    // size="small"
                  >
                    Pasirinkti paslaugą
                  </Button>
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
            ) : null}
          </TableBody>

          {hasItems && record.totals ? (
            <TableFooter>
              {this.props.store.isAdmin ? (
                <>
                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      Nuolaida, %
                    </TableCell>
                    <TableCell align="right" style={{ background: '#DFE3E8', paddingRight: 0 }}>
                      {this.renderFormField({ name: 'totals.discountPercent', type: 'number', naked: true, min: 0, className: classes.inputRight })}
                    </TableCell>
                    <TableCell className="actions" />
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      Nuolaida, EUR
                    </TableCell>
                    <TableCell align="right" style={{ background: '#DFE3E8', paddingRight: 0 }}>
                      {this.renderFormField({ name: 'totals.discountAmount', type: 'number', naked: true, min: 0, className: classes.inputRight })}
                    </TableCell>
                    <TableCell className="actions" />
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      Nuolaidos suma, EUR
                    </TableCell>
                    <TableCell align="right">{twoDecimals(record.totals.totalDiscountAmount)}</TableCell>
                    <TableCell className="actions" />
                  </TableRow>
                </>
              ) : null}

              {!this.props.store.isAdmin && _.get(record, 'totals.discountPercent', 0) > 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    Nuolaida, %
                  </TableCell>
                  <TableCell align="right">
                    {twoDecimals(record.totals.discountPercent)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {!this.props.store.isAdmin && _.get(record, 'totals.discountAmount', 0) > 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    Nuolaida, EUR
                  </TableCell>
                  <TableCell align="right">
                    {twoDecimals(record.totals.discountAmount)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}
              
              {!this.props.store.isAdmin && _.get(record, 'totals.totalDiscountAmount', 0) > 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    Nuolaidos suma, EUR
                  </TableCell>
                  <TableCell align="right">{twoDecimals(record.totals.totalDiscountAmount)}</TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {!this.props.store.isAdmin && twoDecimals(record.totals.total) !== twoDecimals(record.totals.correctedTotal) ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    Suma, EUR
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ textDecoration: 'line-through', marginRight: 5 }}>{twoDecimals(record.totals.total)}</span>
                    {twoDecimals(record.totals.correctedTotal)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {this.props.store.isAdmin || twoDecimals(record.totals.total) === twoDecimals(record.totals.correctedTotal) ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    Suma{this.props.store.isAdmin ? ' (apskaičiuota)' : null}, EUR
                  </TableCell>
                  <TableCell align="right">{twoDecimals(record.totals.total)}</TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {this.props.store.isAdmin ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    Suma (galutinė, be PVM), EUR
                  </TableCell>
                  <TableCell align="right" style={{ background: '#DFE3E8', paddingRight: 0 }}>
                    {this.renderFormField({ name: 'totals.correctedTotal', type: 'number', naked: true, min: 0, className: classes.inputRight })}
                  </TableCell> 
                  <TableCell className="actions" />
                </TableRow>
              ) : null}
              
              <TableRow>
                <TableCell colSpan={5} align="right">
                  PVM suma, EUR
                </TableCell>
                <TableCell align="right">{twoDecimals(record.totals.vat)}</TableCell>
                <TableCell className="actions" />
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="right">
                  <strong>Iš viso, EUR su PVM</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{twoDecimals(record.totals.grandTotal)}</strong>
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
    )
  }

  renderItemModal() {
    if (!this.state.showItemModal) return null;

    return (
      <OrderItemModal
        editingItem={this.state.editingItem}
        months={this.props.store.record.months}
        onSave={this.onItemSave.bind(this)}
        onClose={this.onCloseItemModal.bind(this)}
      />
    )
  }

  renderOrderStatus(value) {
    let status = value;
    let color = 'black';

    switch (value) {
      case 'pending':
        status = 'Laukiama administratoriaus patvirtinimo';
        color = 'blue';
        break;

      case 'paid':
        status = 'Aktyvus';
        color = 'green';
        break;

      case 'expired':
        status = 'Nebegalioja';
        color = 'red';
        break;

      case 'draft':
        status = 'Nepateiktas';
        color = 'black';
        break;

      default:
        status = value || '-';
        break;
    }

    return <span style={{ color }}>{status}</span>;
  }

  renderForm() {
    const { record, data } = this.props.store;

    const companyOptions = !this.props.store.isAdmin || !data.companies 
      ? null 
      : _.map(data.companies, company => ({
        label: company.registeredBy 
          ? `${company.name} (${company.registeredBy.fullName}, ${company.registeredBy.email})` 
          : company.name,
        value: company._id,
      }));
    
    const monthsOptions = [
      { label: '3 mėn.', value: 3 },
      { label: '6 mėn. (Nuolaida 10%)', value: 6 },
      { label: '9 mėn. (Nuolaida 20%)', value: 9 },
      { label: '12 mėn. (Nuolaida 30%)', value: 12 },
    ]

    const statusOptions = [
      { label: 'Nepateiktas', value: 'draft' },
      { label: 'Laukiama administratoriaus patvirtinimo', value: 'pending' },
      { label: 'Aktyvus', value: 'paid' },
      { label: 'Nebegalioja', value: 'expired' },
    ];

    return (
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Užsakymo Nr.:</strong> {record.number || '-'}
          </Typography>
          {!this.props.store.isAdmin ? (
            <Typography variant="body1" style={{ marginBottom: 5 }}>
              <strong>Būsena:</strong> {this.renderOrderStatus(record.status)}
            </Typography>
          ) : null}
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Užsakymas galioja nuo:</strong> {record.date ? timestampToDate(record.date) : '-'}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Užsakymas galioja iki:</strong> {record.expiresAt ? timestampToDate(record.expiresAt) : '-'} {record.expiresAt && record.months ? `(${record.months} mėn.)` : null}
          </Typography>
          {record._id ? (
            <>
              <Typography variant="body1" style={{ marginBottom: 5 }}>
                <strong>Viso licencijų:</strong> {record.totalLicences}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: 5 }}>
                <strong>Panaudota licencijų:</strong> {record.licences.length}
              </Typography>
            </>
          ) : null}
        </Grid>

        {this.props.store.isAdmin ? (
          <Grid item md={3}>
            {this.renderFormField({ name: 'company', label: 'Įmonė', type: 'select', options: companyOptions })}
          </Grid>
        ) : null}
        
        {!this.props.store.isReadonly ? (
          <Grid item md={3}>
            {this.renderFormField({ name: 'months', label: 'Užsakymo galiojimo terminas', type: 'select', options: monthsOptions })}
          </Grid>
        ) : null}

        {this.props.store.isAdmin && record._id ? (
          <Grid item md={3}>
            {this.renderFormField({ name: 'status', label: 'Užsakymo būsena', type: 'select', options: statusOptions })}
          </Grid>
        ) : null}

        <Grid item md={12}>
          {this.renderTable()}
        </Grid>
      </Grid>
    )
  }

  renderFormFooter() {
    const { record, isAdmin, isReadonly } = this.props.store;
    const isDisabled = !record || (record && !record.items) || (record.items && !record.items.length);

    let actions = [];
    
    if (!isReadonly || isAdmin) {
      actions.push(
        <Button
          key="save"
          color="primary"
          variant="contained"
          onClick={() => this.save()}
          disabled={isDisabled}
          style={{ margin: '0px 5px' }}
        >
          Išsaugoti
        </Button>
      )
    }
    
    if (!isReadonly && !this.props.store.isAdmin) {
      actions.push(
        <Button
          key="submit"
          color="primary"
          variant="contained"
          onClick={() => this.save(true)}
          disabled={isDisabled}
          style={{ margin: '0px 5px', float: 'right' }}
        >
          Pateikti užsakymą
        </Button>
      )
    }
  

    return actions;
  }
  
  render() {
    const { classes } = this.props;

    return (
      <ServicePackages title="Paslaugų užsakymas">
        <div className={classes.wrap}>
          
          <CommonPanel
            footer={this.renderFormFooter()}
          >
            {this.renderForm()}

            {this.renderItemModal()}
          </CommonPanel>

        </div>
      </ServicePackages>
    )
  }
}

export default compose(withStyles(styles))(observer(Container));