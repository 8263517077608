import React from 'react';
import ReactDOM from 'react-dom';

// Service worker
import * as serviceWorker from './common/serviceWorker';

// AppWrapper
import AppWrapper from 'views/AppWrapper';

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

serviceWorker.unregister();
