const prefix = 'PROJECT_DASHBOARD_';

export const REQUEST = `${prefix}REQUEST`;
export const REQUEST_FAILED = `${prefix}REQUEST_FAILED`;
export const VALUE_CHANGED = `${prefix}VALUE_CHANGED`;
export const TOGGLE_MATERIALS = `${prefix}TOGGLE_MATERIALS`;
export const RESET_USER_INPUT = `${prefix}RESET_USER_INPUT`;
export const GET_TYPES_SUCCESS = `${prefix}GET_TYPES_SUCCESS`;
export const GET_VERSIONS_SUCCESS = `${prefix}GET_VERSIONS_SUCCESS`;
export const GET_FILES_SUCCESS = `${prefix}GET_FILES_SUCCESS`;
export const GET_HIERARCHY_SUCCESS = `${prefix}GET_HIERARCHY_SUCCESS`;
export const GET_MATERIALS_SUCCESS = `${prefix}GET_MATERIALS_SUCCESS`;
export const SELECT_MATERIALS_SUCCESS = `${prefix}SELECT_MATERIALS_SUCCESS`;
export const GET_COMBINATIONS_SUCCESS = `${prefix}GET_COMBINATIONS_SUCCESS`;
export const GET_PRICES_SUCCESS = `${prefix}GET_PRICES_SUCCESS`;
export const RESET_FORM = `${prefix}RESET_FORM`;