import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import { Auth as AuthLayout } from 'layouts';
import styles from './styles';
import { 
  TextField,
  Button,
  withStyles,
} from '@material-ui/core';

import { userService } from 'views/Users/_services';
import { toast } from 'utils/helpers';
import { history } from 'utils/history';

class PasswordReset extends Component {
  
  state = {
    email: '',
    hash: null,
    password: '',
    passwordConfirm: '',
    loading: false,
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.hash) {
      this.setState({ hash: match.params.hash });
    }
  }

  async handleResetSubmit() {
    const { email } = this.state;

    if (!email) {
      return;
    }

    this.setState({ loading: true });

    const { exists, active } = await userService.checkEmail(email);

    if (exists && active) {
      // Exists and activated
      // TODO retrieve expirational hash so reset link can be invalidated automatically
      await userService.resetPassword({ email });
      toast('Slaptažodžio keitimo nuoroda nusiųsta el. paštu', 'success');
      this.setState({ email: '' });
    } else if (exists && !active) {
      // Exists, inactive
      toast('Vartotojas yra neaktyvuotas, todėl slaptažodžio keitimas negalimas', 'error');
      history.push('/login');
    } else {
      // User not exist
      toast('Vartotojas neegzistuoja', 'error');
      history.push('/login');
    }

    this.setState({ loading: false });
  }

  async handlePasswordSubmit() {
    const { password, confirmPassword, hash } = this.state;

    if (!password || !confirmPassword || password != confirmPassword) {
      toast('Slaptažodžiai nesutampa', 'error');
      return;
    }

    this.setState({ loading: true });

    await userService.resetPassword({ password, confirmPassword, hash });

    toast('Slaptažodis pakeistas. Galite prisijungti.', 'success');
    localStorage.removeItem('jwt');
    localStorage.removeItem('state');
    this.setState({ loading: false });
    history.push('/login');
  }

  handleFieldChange = (field, value) => {
    this.setState({ 
      [field]: value,
    });
  }

  renderEmailForm() {
    const { classes } = this.props;
    const { email } = this.state;

    return (
      <React.Fragment>
        <TextField
          className={classes.textField}
          label="El. p. adresas"
          name="email"
          onChange={event =>
            this.handleFieldChange('email', event.target.value)
          }
          type="text"
          value={email || ''}

          autoComplete="off"
        />
        
        <Button
          className={classes.SignUpButton}
          color="primary"
          onClick={() => this.handleResetSubmit()}
          size="large"
          variant="contained"
          disabled={!email}
        >
          Siųsti slaptažodžio keitimo nuorodą
        </Button>
      </React.Fragment>
    )
  }

  renderPasswordForm() {
    const { classes } = this.props;
    const { password, confirmPassword } = this.state;

    return (
      <React.Fragment>
        <TextField
          className={classes.textField}
          label="Naujas slaptažodis"
          name="password"
          onChange={event =>
            this.handleFieldChange('password', event.target.value)
          }
          type="password"
          value={password || ''}

          autoComplete="new-password-reset"
        />

        <TextField
          className={classes.textField}
          label="Slaptažodžio patvirtinimas"
          name="confirmPassword"
          onChange={event =>
            this.handleFieldChange('confirmPassword', event.target.value)
          }
          type="password"
          value={confirmPassword || ''}

          autoComplete="new-password-reset-confirm"
        />
        
        <Button
          className={classes.SignUpButton}
          color="primary"
          onClick={() => this.handlePasswordSubmit()}
          size="large"
          variant="contained"
          disabled={!password && !confirmPassword && password != confirmPassword}
        >
          Pakeisti slaptažodį
        </Button>
      </React.Fragment>
    )
  }

  render() {
    const { classes } = this.props;
    const { loading, hash } = this.state;

    return (
      <AuthLayout title="Slaptažodžio keitimas" loading={loading}>
        <div className={classes.root}>
          <div className={classes.fields}>
            {!hash ? this.renderEmailForm() : this.renderPasswordForm()}
          </div>
        </div>
      </AuthLayout>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(PasswordReset);