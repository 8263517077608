import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { DataTable } from 'components';

import Companies from 'views/Companies';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { companiesList } from './_reducers';
store.injectReducer('companiesList', companiesList);


class CompaniesList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    
    const columns = [
      'ID',
      'LINK',
      'Pavadinimas',
      'Būsena',
      'Įm. kodas',
      'Adresas',
      'Užregistravo',
      'Užregistruota',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/companies/${record._id}`);
      row.push(record.name);
      row.push(record.isActive ? 'aktyvuota' : 'neaktyvuota');
      row.push(record.code);
      row.push(record.address);
      row.push(this.renderOwnerCell(record));
      row.push(moment(record.createdAt).utc().format('YYYY-MM-DD'));
      
      return row;
    });

    return data;
  }

  renderOwnerCell({ registeredBy }) {
    if (!registeredBy || !registeredBy.email) {
      return;
    }

    const { _id, email, fullName } = registeredBy;

    return (
      <a href={`/users/${_id}`} title={email}>{fullName}</a>
    )
  }

  render() {
    const { classes, records, isLoading } = this.props;
    if (isLoading) {
      return (
        <h1>Kraunasi...</h1>
      )
    }
    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <Companies
        title="Įmonės"
      >
        
        <div className={classes.content}>
          <DataTable 
            // title={intl.formatMessage({ id: 'Companies.CompanyList.title', defaultMessage: 'Įmonių sąrašas'})}
            columns={columns}
            data={data}
            createUrl="/companies/create"
            // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
            // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
          />
        </div>

      </Companies>
    );
  }
}

const mapStateToProps = ({ companiesList, dispatch }) => {
  return { 
    ...companiesList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(CompaniesList);