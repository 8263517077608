import { observable, action, decorate, toJS } from 'mobx';
import _ from 'lodash';
import { toast } from 'utils/helpers';
import { history } from 'utils/history';
import {
  getOne,
  create,
  update,
} from 'services/normPackages';
import {
  getTree,
} from 'services/norms';

class Store {
  record = null
  collectionOptions = []

  // Action
  reset = () => {
    this.record = null;
    this.collectionOptions = [];
  }

  // Action
  setRecord = record => {
    this.record = record;
  }

  getOne = async (id) => {
    const data = await getOne(id);

    this.setRecord(data);
  }

  create = async () => {
    const record = toJS(this.record);

    try {
      await create(record);
      toast('Įrašas sukurtas', 'success');
      history.push('/norm-packages');
    } catch (err) {
      toast('Nepavyko išsaugoti', 'danger');
    }
  }

  update = async () => {
    const record = toJS(this.record);
    
    try {
      await update(record._id, record);
      toast('Įrašas atnaujintas', 'success');
      history.push('/norm-packages');
    } catch (err) {
      toast('Nepavyko išsaugoti', 'danger');
    }
  }

  // Action
  setCollectionOptions = data => {
    this.collectionOptions = data;
  }

  getCollectionOptions = async () => {
    const records = await getTree();

    const options = _.map(records, ({ code, title }) => ({
      label: `${code} - ${title}`,
      value: code,
    }));
    
    this.setCollectionOptions(options);
  }
}

decorate(Store, {
  record: observable,
  collectionOptions: observable,
  setRecord: action,
  setCollectionOptions: action,
  reset: action,
});

export default new Store;