import {
  GETALL_SUCCESS,
  GETALL_FAILURE,
  SENDING_REQUEST,
} from './_constants';

const initialState = {
  isLoading: false,
  records: [],
};

export const projectsList = (state = initialState, action) => {
  switch (action.type) {
    
    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GETALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        records: action.records,
      };

    case GETALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state;
  }
}