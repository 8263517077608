import { observable, action, decorate } from 'mobx';
import {
  doSearch,
} from 'services/norms';

class Store {
  results = []
  isSearching = false

  // Action
  setIsSearching = searching => {
    this.isSearching = searching;
  }

  // Action
  setResults = data => {
    this.results = data;
  }

  search = async (search, version) => {
    this.setIsSearching(true);

    const data = await doSearch({ search, version });

    this.setResults(data);
    this.setIsSearching(false);
  }
}

decorate(Store, {
  results: observable,
  isSearching: observable,
  setResults: action,
  setIsSearching: action,
});

export default new Store;