import React, { Component } from 'react';
import NormTreeTableContainer from './NormTreeTableContainer';
import store from './_store';

class NormTreeTable extends Component {

  render() {
    return <NormTreeTableContainer store={store} />
  }
}

export default NormTreeTable;