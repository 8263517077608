// import _ from 'lodash';
import { toast } from 'views/App/_actions';
import { history } from 'utils/history';
import * as companyService from 'views/Companies/shared/_services';
import { 
  SENDING_REQUEST,
  SEARCH_SUCCESS,
  JOIN_REQUEST_SUCCESS,
  RESET_FORM,
  FAILURE,
} from './_constants';

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}

export const search = (code) => async (dispatch) => {
  if (!code) {
    toast('Įveskite įmonės kodą', 'error');
    return;
  }

  dispatch({ type: SENDING_REQUEST });

  try {
    const company = await companyService.searchCode(code);

    dispatch({ type: SEARCH_SUCCESS, company });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }
}

export const sendJoinRequest = (details) => async (dispatch) => {
  if (!details.position) {
    toast('Nenurodėte pareigų įmonėje', 'error');
    return;
  }

  dispatch({ type: SENDING_REQUEST });

  try {
    await companyService.joinRequest(details);

    toast('Prašymas išsiųstas. Apie patvirtintą prašymą prisijungti informuosime el. paštu', 'success');
    dispatch({ type: JOIN_REQUEST_SUCCESS });
    history.push('/');
  } catch (error) {

    try {
      toast(error.response.data.message, 'error');
    } catch(err) {
      toast('Nepavyko užregistruoti', 'error');
    }

    dispatch({ type: FAILURE, error });
  }
}