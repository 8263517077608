import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
} from '@material-ui/core';
import { DataTable } from 'components';
import styles from './styles';

import Users from 'views/Users';

import { getAll } from 'views/Users/_actions';


class UserList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    // Get all users
    dispatch(getAll());
  }

  prepareTableColumns() {
    const columns = [
      'ID',
      'LINK',
      'Vardas',
      'El. paštas',
      'Aktyvuotas',
      'Paskyros',
      'Sukurta',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/users/${record._id}`);
      row.push(record.fullName);
      row.push(record.email);
      row.push(record.isActivated ? 'Taip' : 'Ne');
      row.push(record.accounts.length);
      row.push(moment.parseZone(record.createdAt).format('YYYY-MM-DD'));

      return row;
    });

    return data;
  }
  

  renderUsers() {
    const { classes, isLoading, list } = this.props;
    const { users } = list;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(users);



    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }


    return (
      <DataTable 
        title="Vartotojų sarašas"
        columns={columns} 
        data={data}
        createUrl="/users/create"
      />
    );
  }

    

  render() {
    const { classes } = this.props;
    return (
      <Users title={<FormattedMessage id="usersUserListTitle" defaultMessage="Vartotojų sarašas" />}>
        
        <div className={classes.content}>
          {this.renderUsers()}
        </div>

      </Users>
    );
  }
}

const mapStateToProps = ({ users, dispatch }) => {
  return { 
    ...users,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(UserList);