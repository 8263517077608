import React, { Component } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import { Link as HtmlLink, withStyles, Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import TableSearch from './components/TableSearch';

import { confirmDialog, toast } from 'utils/helpers';

import styles from './styles';

class DataTable extends Component {

  state = {
    defaultOptions: {},
    preparedData: [],
    preparedColumns: [],
  }

  prepareDataAndColumns(data, columns) {
    const { classes } = this.props;

    let preparedData = data;
    let preparedColumns = columns;

    // If ID column exists - hide it
    let idColIdx = _.indexOf(preparedColumns, 'ID');
    if (idColIdx >= 0) {
      // Update ID column to advanced value and set it's options so value will be hidden from user
      preparedColumns[idColIdx] = {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false,
          searchable: false,
          print: false,
          download: false,
        }
      };
    }
    
    // If LINK column exists - hide it and update next column to be rendered as a clickable link
    let linkColIdx = _.indexOf(preparedColumns, 'LINK');
    if (linkColIdx >= 0) {
      // Update LINK column to advanced value and set it's options so value will be hidden from user
      preparedColumns[linkColIdx] = {
        name: 'LINK',
        options: {
          display: 'excluded',
          filter: false,
          searchable: false,
          print: false,
          download: false,
        }
      };

      let nextCol = preparedColumns[linkColIdx + 1];
      
      if (nextCol && typeof nextCol !== 'object') {
        preparedColumns[linkColIdx + 1] = {
          name: nextCol,
          options: {
            customBodyRender: (value, tableMeta) => {
              // Get link for this record
              const url = tableMeta.rowData[linkColIdx];

              // Render a link with title
              return (
                <HtmlLink to={url} component={Link} className={classes.link}>
                  {value}
                </HtmlLink>
              );
            }
          },
        }
      }

    }


    return { preparedData, preparedColumns };
  }

  componentWillMount() {
    const { title, columns, data, intl } = this.props;
    const { preparedData, preparedColumns } = this.prepareDataAndColumns(data, columns);

    const defaultOptions = {
      elevation: 0,
      rowsPerPage: 20,
      searchOpen: true,
      searchPlaceholder: 'Paieška...',
      selectableRows: 'none',
      print: true, // Will need custom print handler because MUIDT just prints what's visible and also causes: https://github.com/gregnb/mui-datatables/issues/750
      textLabels: {
        body: {
          noMatch: <FormattedMessage id="DataTable.noMatch" defaultMessage="Įrašų nerasta" />,
          toolTip: <FormattedMessage id="DataTable.sort" defaultMessage="Rikiuoti" />,
        },
        pagination: {
          next: <FormattedMessage id="DataTable.next" defaultMessage="Kitas" />,
          previous: <FormattedMessage id="DataTable.previous" defaultMessage="Atgal" />,
          rowsPerPage: <FormattedMessage id="DataTable.rowsPerPage" defaultMessage="Įrašų puslapyje" />,
          displayRows: intl.formatMessage({ id: 'DataTable.displayRows', defaultMessage: 'iš'}),
        },
        toolbar: {
          search: <FormattedMessage id="DataTable.search" defaultMessage="Paieška" />,
          downloadCsv: <FormattedMessage id="DataTable.downloadCsv" defaultMessage="Atsisiųsti CSV" />,
          print: <FormattedMessage id="DataTable.print" defaultMessage="Spausdinti" />,
          viewColumns: <FormattedMessage id="DataTable.viewColumns" defaultMessage="Rodomi stulpeliai" />,
          filterTable: <FormattedMessage id="DataTable.filterTable" defaultMessage="Filtravimas" />,
        },
        filter: {
          all: <FormattedMessage id="DataTable.filterAll" defaultMessage="Visi" />,
          title: <FormattedMessage id="DataTable.filterTitle" defaultMessage="Filtravimas" />,
          reset: <FormattedMessage id="DataTable.filterReset" defaultMessage="Atstatyti" />,
        },
        viewColumns: {
          title: <FormattedMessage id="DataTable.showColumns" defaultMessage="Rodomi stulpeliai" />,
          titleAria: <FormattedMessage id="DataTable.showHideColumns" defaultMessage="Rodyti/slėpti stulpelius" />,
        },
        selectedRows: {
          text: <FormattedMessage id="DataTable.selectedRecordsText" defaultMessage="pažymėtų įrašų" />,
          delete: <FormattedMessage id="DataTable.delete" defaultMessage="Pašalinti" />,
          deleteAria: <FormattedMessage id="DataTable.deleteSelected" defaultMessage="Pašalinti pažymėtus" />,
        },
      },
      onRowsDelete: this.onRowsDelete.bind(this),
      responsive: 'scrollFullHeight',
      // responsive: 'scrollMaxHeight',
      downloadOptions: {
        filename: `${_.deburr(title) || 'Export'} - ${moment().format('YYYY-MM-DD')}.csv`,
        separator: ',',
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        // Fix UTF8 issues on exported CSV
        return '\uFEFF' + buildHead(columns) + buildBody(data);
      },
      filterType: 'multiselect',
    };

    this.setState({ defaultOptions, preparedData, preparedColumns });
  }

  async onRowsDelete({ data }) {
    const { onDeleteBatch, onDelete, intl } = this.props;
    const msgConfirmDelete = intl.formatMessage({ id: 'DataTable.confirmDelete', defaultMessage: 'Ar tikrai norite ištrinti pažymėtus įrašus?'});
    const msgCannotDelete = intl.formatMessage({ id: 'DataTable.cannotDelete', defaultMessage: 'Negalima pašalinti'});

    if (data.length === 0) {
      toast(msgCannotDelete, 'error');
      return false;
    }

    // If deleting more than one but handler is undefined
    if (data.length > 1 && !onDeleteBatch) {
      toast(msgCannotDelete, 'error');
      return false;
    }

    // If deleting one but handler is undefined
    if (data.length === 1 && !onDelete) {
      toast(msgCannotDelete, 'error');
      return false;
    }

    // If cancels deletion from dialog
    if (!confirmDialog(msgConfirmDelete)) {
      return false;
    }

    if (data.length > 1) {
      // Deleting batch of records
      await onDeleteBatch(_.map(data, (record) => record.dataIndex));
      console.log('Deleting batch');
    } else {
      // Deleting one record
      await onDelete(data[0].dataIndex);
      console.log('Deleting one');
    }

    // Always return false because table will be newly rendered after item is successfully removed from array
    // and it won't be rendered if removal fails
    return false;
  }

  render() {
    const { defaultOptions, preparedData, preparedColumns } = this.state;
    const { title, createUrl, createHandler, classes, rootStyle, options = defaultOptions } = this.props;

    options.customSearchRender = (searchText, onSearch, onHide, options) => (
      <TableSearch
        searchText={searchText}
        onSearch={onSearch}
        onHide={onHide}
        options={options}
      />
    );

    const finalOptions = _.merge(defaultOptions, options);

    return (
      <div className={classes.root} style={rootStyle}>
        {/* Data table */}
        <MUIDataTable
          title={title}
          columns={preparedColumns}
          data={preparedData}
          options={finalOptions}
        />

        {/* Link to new record form */}
        {createUrl ? (
          <div className={classes.buttonWrapper}>
            <Link
              to={createUrl}
            >
              <Button
                color="primary"
                size="small"
                variant="contained"
                
              >
                <AddIcon className={classes.createButtonIcon} />
                <FormattedMessage id="Common.Add" defaultMessage="Pridėti" />
              </Button>
            </Link>
          </div>
        ): null}

        {/* Link to new record form */}
        {createHandler ? (
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={(e) => createHandler(e)}
              
            >
              <AddIcon className={classes.createButtonIcon} />
              <FormattedMessage id="Common.Add" defaultMessage="Pridėti" />
            </Button>
          </div>
        ): null}
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
)(injectIntl(DataTable));