import React, { Component } from 'react';
import _ from 'lodash';
import {
  withStyles,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core';
import {
  CommonPanel,
} from 'components';
import NumberFormat from 'react-number-format';

import styles from './styles';

import BuildingTypePicker from './components/BuildingTypePicker';

class NtkFilters extends Component {

  onChange(e) {
    const { handleChange, versions } = this.props;
    const data = {
      name: e.target.name,
      value: e.target.value,
    };

    if (!handleChange) {
      return;
    }

    // Set object as pricing value
    if (data.name === 'pricing') {
      data.value = _.find(versions, { code: data.value }) || '';
    }

    handleChange(data);
  }

  componentDidUpdate() {
    const { pricing, versions, handleChange, type, types } = this.props;

    if (!handleChange) {
      return;
    }

    if (!pricing && versions && versions.length > 0) {
      handleChange({
        name: 'pricing',
        value: _.first(versions),
      })
    }

    if (!type && (types && types.length)) {
      handleChange({
        name: 'type',
        value: _.first(types).code,
      })
    }
  }

  renderUserInputs() {
    const {
      classes,
      title,
      unit,
      amount,
      pricing,
      type,
      buildingType,
      handleClearForm,
      handleSaveForm,
    } = this.props;

    if (!pricing || !type || !buildingType) {
      return;
    }

    return (
      <div>
        
        <NumberFormat
          required
          fullWidth
          customInput={TextField}
          allowNegative={false}
          isNumericString
          className={classes.textField}
          label={<span>Įveskite kiekį, {unit || '-'}</span>}
          onBlur={this.onChange.bind(this)}
          name="amount"
          value={amount}
          margin="dense"
          variant="outlined"
        />

        <TextField
          fullWidth
          multiline
          className={classes.textField}
          label="Pastabos"
          onChange={this.onChange.bind(this)}
          name="title"
          value={title}
          margin="dense"
          variant="outlined"
        />

        <Button
          fullWidth
          color="primary"
          onClick={() => handleClearForm()}
          className={classes.resetButton}
        >
          Išvalyti skaičiavimo duomenis
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => handleSaveForm()}
          // className={classes.resetButton}
        >
          Išsaugoti
        </Button>

      </div>
    )
  }

  render() {
    const {
      classes,
      types,
      versions,
      hierarchy,
      type,
      buildingType,
      pricing,
      handleChange,
      filtersOnly,
    } = this.props;

    return (
      <CommonPanel
        title="Statinio duomenys"
        noPadding
      >

        <form autoComplete="off" noValidate onSubmit={e => e.preventDefault()}>
          <div className={classes.field}>
            <TextField
              // disabled
              required
              fullWidth
              select
              className={classes.textField}
              label="Statinių paskirties grupė"
              onChange={this.onChange.bind(this)}
              name="type"
              value={type}
              margin="dense"
              variant="outlined"
            >
              {types ? _.map(types, type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.title}
                </MenuItem>
              )) : null}
            </TextField>

            {versions && versions.length > 0 ? (
              <div>

                <TextField
                  required
                  fullWidth
                  select
                  className={classes.textField}
                  label="Kainyno versija"
                  onChange={this.onChange.bind(this)}
                  name="pricing"
                  value={pricing ? pricing.code : ''}
                  margin="dense"
                  variant="outlined"
                >
                  {_.map(versions, version => (
                    <MenuItem key={version.code} value={version.code}>
                      {version.version}
                    </MenuItem>
                  ))}
                </TextField>

                <BuildingTypePicker
                  hierarchy={hierarchy}
                  buildingType={buildingType}
                  name="buildingType"
                  handleChange={handleChange}
                />

                {!filtersOnly ? this.renderUserInputs() : null}

              </div>
            ) : null}
            
          </div>
        </form>

      </CommonPanel>
    );
  }
}

export default withStyles(styles)(NtkFilters);
