const constants = {
  GETALL_REQUEST: 'ACCOUNTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ACCOUNTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ACCOUNTS_GETALL_FAILURE',

  GETONE_REQUEST: 'ACCOUNTS_GETONE_REQUEST',
  GETONE_SUCCESS: 'ACCOUNTS_GETONE_SUCCESS',
  GETONE_FAILURE: 'ACCOUNTS_GETONE_FAILURE',

  UPDATE_REQUEST: 'ACCOUNTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ACCOUNTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ACCOUNTS_UPDATE_FAILURE',

  CREATE_REQUEST: 'ACCOUNTS_CREATE_REQUEST',
  CREATE_SUCCESS: 'ACCOUNTS_CREATE_SUCCESS',
  CREATE_FAILURE: 'ACCOUNTS_CREATE_FAILURE',

  DELETE_ONE_REQUEST: 'ACCOUNTS_DELETE_ONE_REQUEST',
  DELETE_ONE_SUCCESS: 'ACCOUNTS_DELETE_ONE_SUCCESS',
  DELETE_ONE_FAILURE: 'ACCOUNTS_DELETE_ONE_FAILURE',

  SET_VALUE: 'ACCOUNTS_SET_VALUE',
  RESET_FORM: 'ACCOUNTS_RESET_FORM',
  FAILURE: 'ACCOUNTS_FAILURE',
  DELETE_SUCCESS: 'ACCOUNTS_DELETE_SUCCESS',
};

export default constants;