import { observable, action, decorate } from 'mobx';
// import _ from 'lodash';
import { getAll, getSummary } from 'services/orders';

class Store {
  records = []
  summary = []

  // Action
  reset = () => {
    this.records = [];
    this.summary = [];
  }

  // Action
  setRecords = records => {
    this.records = records;
  }

  // Action
  setSummary = summary => {
    this.summary = summary;
  }

  getAll = async () => {
    const records = await getAll();
    this.setRecords(records);

    const summary = await getSummary();
    this.setSummary(summary);
  }
}

decorate(Store, {
  records: observable,
  summary: observable,
  reset: action,
  setRecords: action,
  setSummary: action,
  getRecords: action,
});

export default new Store;