import {
  SELECT_PROJECT_SUCCESS,
  SELECT_PROJECT_FAILURE,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_TYPES_SUCCESS,
  GET_TYPES_FAILURE,
  GET_VERSIONS_FAILURE,
  GET_VERSIONS_SUCCESS,
  GET_HIERARCHY_FAILURE,
  GET_HIERARCHY_SUCCESS,
  GET_MATERIALS_FAILURE,
  GET_MATERIALS_SUCCESS,
  SELECT_MATERIALS_SUCCESS,
  SELECT_MATERIALS_FAILURE,
  GET_COMBINATIONS_SUCCESS,
  GET_COMBINATIONS_FAILURE,
  GET_CALCULATIONS_SUCCESS,
  GET_CALCULATIONS_FAILURE,
  UPDATE_CALCULATIONS_SUCCESS,
  UPDATE_CALCULATIONS_FAILURE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  SENDING_REQUEST,
  VALUE_CHANGED,
  RESET_FORM,
  RESET_USER_INPUT,
  TOGGLE_MATERIALS,
  SKIP_MATERIALS,
  TOGGLE_RESULTS,
  PROJECT_CLEARED,
  GET_RECORD_SUCCESS,
  GET_RECORD_FAILURE,
  START_LOADING_RECORD,
  FINISH_LOADING_RECORD,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from './_constants';

const initialCalculations = {
  totals: {
    percent: null,
    price: null,
    coefficientsPrice: null,
    depreciationPrice: null,
    tableNumber: null,
    depreciationDuration: null,
    depreciationPercent: null,
    depreciationPricePerUnit: null,
    depreciationPricePerUnitInitial: null,
    buildingAge: '',
    totalBasePrice: null,
    totalCoefficientsPrice: null,
    totalDepreciationPrice: null,
    totalPercent: null,
    totalCoefficientsPercent: null,
    totalDepreciationPercent: null,
  },
  coefficients: [],
  corrections: [],
  percents: [],
};

const initialData = {
  projects: [],
  types: [],
  versions: [],
  hierarchy: [],
  materials: [],
  combinations: [],

  initialMaterials: [], // Loaded after selecting type, all available unchecked options
  currentProject: null, // Selected project
};

const initialRecord = {
  owner: null,
  project: '',
  title: '',
  description: '',
  type: '',
  buildingType: '',
  unit: '',
  amount: '',
  usageStartDate: null,
  pricing: null,
  combinationCode: '',
  wallType: '',
  materials: [],
  // calculations: initialCalculations, // Storing calculated and intermediate results
  createdAt: null,
  updatedAt: null,
  ...initialCalculations,

  // helpers, not neccessary stored in DB
  usageStartDateChanged: false,
};

const initialState = {
  isLoading: false,
  isLoadingRecord: false,
  showMaterials: false,
  skipMaterials: false,
  showResults: false,
  // Property to store originaly loaded record from DB
  loadedRecord: null,
  // Storing data for filling dropdowns, etc.
  data: { ...initialData },
  // Representing recovery price record
  record: { ...initialRecord },
};

export const recoveryPriceForm = (state = initialState, action) => {
  switch (action.type) {
    
    case VALUE_CHANGED:
      return {
        ...state,
        record: {
          ...state.record,
          [action.data.name]: action.data.value,
        }
      };

    case START_LOADING_RECORD:
      return {
        ...state,
        isLoadingRecord: true,
      };

    case FINISH_LOADING_RECORD:
      return {
        ...state,
        isLoadingRecord: false,
      };

    case GET_RECORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadedRecord: action.loadedRecord,
      };

    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    
    case RESET_FORM:
      return {
        ...initialState
      };

    case RESET_USER_INPUT:
      return {
        ...state,
        showResults: false,
        data: {
          ...state.data,
          materials: [],
          combinations: [],
          initialMaterials: [],
        },
        record: {
          ...state.record,
          ...initialCalculations,
          materials: [],
          unit: '',
          combinationCode: '',
        }
      };

    case GET_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          types: action.types,
        }
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          projects: action.projects,
        }
      };

    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          materials: action.materials,
          initialMaterials: action.materials,
        }
      };

    case GET_VERSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          hierarchy: initialData.hierarchy,
          versions: action.versions,
        },
        record: {
          ...state.record,
          pricing: initialRecord.pricing,
        }
      };
    
    case GET_HIERARCHY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          hierarchy: action.hierarchy,
        }
      };

    case SELECT_MATERIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          materials: action.materials,
        }
      };

    case SELECT_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          currentProject: action.project,
        },
        record: {
          ...state.record,
          project: action.project._id,
        }
      };

    case PROJECT_CLEARED:
      return {
        ...state,
        data: {
          ...state.data,
          currentProject: null,
        },
        record: {
          ...state.record,
          project: null,
        }
      };

    case TOGGLE_MATERIALS:
      return {
        ...state,
        showMaterials: action.show,
      };

    case SKIP_MATERIALS:
      return {
        ...state,
        skipMaterials: action.show,
      };

    case TOGGLE_RESULTS:
      return {
        ...state,
        showResults: action.show,
      };

    case GET_COMBINATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          combinations: action.combinations,
        },
        record: {
          ...state.record,
          ...initialCalculations,
        }
      };

    case GET_CALCULATIONS_SUCCESS:
    case UPDATE_CALCULATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: {
          ...action.record,
          usageStartDateChanged: false,
        },
      };

    case CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingRecord: false,
        record: {
          ...state.record,
          _id: action.record._id,
        }
      };


    case CREATE_FAILURE:
    case UPDATE_SUCCESS:
    case UPDATE_FAILURE:
    case GET_HIERARCHY_FAILURE:
    case GET_VERSIONS_FAILURE:
    case GET_TYPES_FAILURE:
    case GET_PROJECTS_FAILURE:
    case SELECT_PROJECT_FAILURE:
    case GET_MATERIALS_FAILURE:
    case SELECT_MATERIALS_FAILURE:
    case GET_COMBINATIONS_FAILURE:
    case GET_CALCULATIONS_FAILURE:
    case UPDATE_CALCULATIONS_FAILURE:
    case GET_RECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingRecord: false,
      }

    default:
      return state;
  }
}