import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
} from '@material-ui/core';
import {
  CommonPanel,
  FormDialog2,
} from 'components';
import ProjectsForm from 'views/Projects/components/ProjectsForm';

import styles from './styles';

class ProjectDetails extends Component {
  
  state = {
    createModalVisible: false,
  }

  componentWillUnmount() {
    this.setState({
      createModalVisible: false,
    });
  }

  onChange(data) {
    const { handleChange } = this.props;
    
    handleChange(data);
  }

  onRowClick(project) {
    this.onChange({ name: 'project', value: project._id });
  }

  onCreateClick() {
    this.setState({
      createModalVisible: true,
    });
  }

  onCreateSubmit(wasSubmitted, newId) {
    const { onCreatedNewProject } = this.props;

    if (onCreatedNewProject) {
      setTimeout(function() {
        onCreatedNewProject(newId)
      }, 1000);
    }

    this.setState({
      createModalVisible: false,
    });
  }

  render() {
    const {
      classes,
      currentProject,
      projects,
      isCollapsed,
    } = this.props;
    
    return (
      <CommonPanel
        title={currentProject ? `Projektas: ${currentProject.title}` : 'Pasirinkite projektą'}
        collapsible
        collapsed={isCollapsed && !this.state.createModalVisible}
        style={{ padding: 10 }}
      >
        <div className={classes.root}>
          <div className={classes.tableWrap}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Pavadinimas</TableCell>
                  <TableCell component="th">Kodas</TableCell>
                  <TableCell component="th">Priklauso kodui</TableCell>
                  <TableCell component="th">Sukūrė</TableCell>
                  <TableCell component="th">Sukūrimo data</TableCell>
                  <TableCell component="th">Atnaujino</TableCell>
                  <TableCell component="th">Atnaujinimo data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {_.map(projects, project => (
                  <TableRow className={[classes.tableRow, ((currentProject && project) && currentProject._id === project._id ? classes.selectedRow : '')].join(' ')} onClick={() => this.onRowClick(project)}>
                    <TableCell style={{ fontWeight: 'bold' }}>{project.title}</TableCell>
                    <TableCell>{project.code || 'Nenurodytas'}</TableCell>
                    <TableCell>{project.parentCode}</TableCell>
                    <TableCell>{project.owner ? project.owner.user.fullName : null}</TableCell>
                    <TableCell>{project.createdAt ? moment(project.createdAt).utc().format('YYYY-MM-DD') : null}</TableCell>
                    <TableCell>{project.updatedBy ? project.updatedBy.user.fullName : null}</TableCell>
                    <TableCell>{project.updatedAt ? moment(project.updatedAt).utc().format('YYYY-MM-DD') : null}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </div>

          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={this.onCreateClick.bind(this)}
            >
              Sukurti naują projektą
            </Button>
          </div>
        </div>

        <FormDialog2
          visible={this.state.createModalVisible}
          component={(
            <ProjectsForm
              embed
            />
          )}
          title="Naujas projektas"
          formId="projectsForm"
          onClose={this.onCreateSubmit.bind(this)}
        />

      </CommonPanel>
    );
  }
}

export default withStyles(styles)(ProjectDetails);
