import _ from 'lodash';
import { canAccess } from 'utils/helpers';
import { userService } from 'views/Users/_services';
import * as companyService from 'views/Companies/shared/_services';
import * as permissionGroupService from 'views/PermissionGroups/shared/_services';

const companyOptions = async () => {
  const companies = await companyService.getAll();

  return _.map(companies, company => ({ 
    value: company._id, 
    label: `${company.name}`,
  }));
}

const userOptions = async () => {
  const users = await userService.getAll();

  return _.map(users, user => ({ 
    value: user._id, 
    label: `${user.firstName} ${user.lastName}`,
  }));
};

const roleGroupsOptions = async () => {
  const permissionGroups = await permissionGroupService.getAll();

  return _.map(permissionGroups, permissionGroup => ({ 
    value: permissionGroup._id, 
    label: `${permissionGroup.title}`,
  }));
}

const statusOptions = () => Promise.resolve([
  {
    value: 'inactive',
    label: 'Neaktyvi',
  },
  {
    value: 'active',
    label: 'Aktyvi',
  },
  {
    value: 'pending',
    label: 'Laukiama patvirtinimo',
  },
  {
    value: 'declined',
    label: 'Atmesta',
  },
]);

const getFields = () => {
  const fields = [];

  if (canAccess('administrator.layout')) {
    fields.push({ name: 'user', label: 'Priklauso vartotojui', type: 'select', options: userOptions });
    fields.push({ name: 'company', label: 'Įmonė', type: 'select', options: companyOptions });
    fields.push({ name: 'status', label: 'Būsena', type: 'select', options: statusOptions });
  } else if (canAccess('accounts.write')) {
    fields.push({ name: 'status', label: 'Būsena', type: 'select', options: statusOptions });
  }
  
  fields.push({ name: 'position', label: 'Pareigos', type: 'text', required: true });
  fields.push({ name: 'comment', label: 'Pastabos', type: 'text' });
  
  if (canAccess('administrator.layout')) {
    fields.push({ name: 'roleGroups', label: 'Priskirtos teisių grupės', type: 'select', options: roleGroupsOptions, multiple: true });
  }

  return fields;
}

export default getFields;