import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
  Button,
} from '@material-ui/core';

import styles from './styles';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from 'components';

import Documents from 'views/Documents';
import DocumentForm from './components/DocumentForm';

import { getOne, resetForm, updateOne, createOne } from 'views/Documents/_actions';
import { menu, menuTitle } from './_menu';

class DocumentDetails extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id) {
      dispatch(getOne(match.params.id));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  renderdocumentDetails(document) {

    return (
      <DocumentForm onSubmit={values => this.onSubmit(values)} document={document} />
    );
  }

  onSubmit(values) {
    const { dispatch } = this.props;

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm() {
    document
      .getElementById('documentForm')
      .dispatchEvent(new Event('submit', { cancelable: true }));
  }

  render() {
    const { classes, isLoading, single } = this.props;
    const { document } = single;

    return (
      <Documents 
        title={document ? `document ${document.title}` : 'Create document'}
        // menu={document ? menu : null}
        // menuTitle={document ? menuTitle : null}
      >
        
        <div className={classes.content}>
          {isLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <Portlet className={classes.root}>
              <PortletHeader>
                <PortletLabel
                  title="Document details"
                />
              </PortletHeader>
              <PortletContent noPadding>
                {this.renderdocumentDetails(document)}
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={event => this.submitForm(event)}
                >
                  Save
                </Button>
              </PortletFooter>
            </Portlet>
          )}
        </div>

      </Documents>
    )
  }
}

const mapStateToProps = ({ documents, dispatch }) => {
  return {
    ...documents,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(DocumentDetails);