import React, { Component } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { 
  withStyles,
  TextField,
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { injectIntl } from 'react-intl';

import styles from './styles';

import { CommonPanel, FormDialog } from 'components';
import CompaniesForm from 'views/Companies/components/CompaniesForm';
import JoinRequest from './components/JoinRequest';
import Companies from 'views/Companies';

import { search, sendJoinRequest, resetForm } from './_actions';
import { getCurrentUser, clearNonDigits } from 'utils/helpers';

// Inject reducers
import { store } from 'utils/store';
import { companiesSearch } from './_reducers';
import { classes } from 'istanbul-lib-coverage';
store.injectReducer('companiesSearch', companiesSearch);

class CompaniesSearch extends Component {

  state = {
    code: '',
    type: '',
    registerCompanyModalVisible: false,
    joinCompanyModalVisible: false,
    isIndividual: false,
    showSearch: false,
  };

  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }

  submit() {
    const { dispatch } = this.props;
    const { code } = this.state;

    if (code) {
      dispatch(search(code));
    }
  }

  registerIndividual() {
    this.setState({
      registerCompanyModalVisible: true,
      isIndividual: true,
      showSearch: false,
      type: 'personal',
    });
  }

  showJoinCompanyModal() {
    this.setState({
      joinCompanyModalVisible: true,
    });
  }

  closeJoinCompanyModal() {
    this.setState({
      joinCompanyModalVisible: false,
    });
  }

  onJoinCompanySubmit({ position, comment }) {
    const { dispatch, company } = this.props;

    const values = {
      position,
      comment,
      company: company._id,
    };

    dispatch(sendJoinRequest(values));
  }

  showRegisterCompanyModal() {
    this.setState({
      isIndividual: false,
      registerCompanyModalVisible: true,
    });
  }

  closeRegisterCompanyModal(wasSubmitted) {
    this.setState({
      registerCompanyModalVisible: false,
      isPersonal: false,
      type: '',
    });

    // Trigger change to show pending company status
    if (wasSubmitted) {
      this.submit();
    }
  }

  renderForm() {
    const { classes } = this.props;
    const { code, showSearch } = this.state;

    return (
      <CommonPanel className={[classes.searchRoot, classes.panel]}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            {showSearch ? (
              <Grid container spacing={1}>
                <Grid item sm={8}>
                  <TextField
                    label="Įmonės kodas"
                    variant="outlined"
                    onChange={e => this.setState({ code: clearNonDigits(e.target.value) })}
                    margin="dense"
                    type="text"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={code}
                    className={classes.searchField}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                
                <Grid item sm={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.submit()}
                    className={classes.btnSearch}
                    fullWidth
                  >
                    Ieškoti
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => this.setState({ showSearch: true })}
                className={classes.btnSearch}
              >
                Registruotis prie įmonės paskyros
              </Button>
            )}
          </Grid>
          <Grid item sm={6}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => this.registerIndividual()}
              className={classes.btnSearch}
            >
              Registruoti savarankiškai dirbančio paskyrą
            </Button>
          </Grid>
        </Grid>

        <div style={{ marginTop: '20px' }}>
          <Typography variant="body2">
            Registruojant naują monę arba prisijungiant prie sistemoje esančios įmonės Jums bus sukuriama įmonės (arba savarankiškai dirbančio) naudojo paskyra.
          </Typography>
        </div>
      </CommonPanel>
    );
  }

  renderAlreadyJoinedMessage({ isActive, userIsPending, userIsDeclined }) {
    let message = 'Jūs jau esate prisijungęs prie įmonės';

    if (userIsPending) {
      message = 'Jūsų paskyra laukia įmonės administratoriaus patvirtinimo';
    } else if (userIsDeclined) {
      message = 'Jūsų prašymas prisijungti buvo atmestas';
    }

    return (
      <React.Fragment>
        <Typography variant="body1" style={{ display: 'inline-block', marginRight: 10 }}>
          <strong>{message}</strong>
        </Typography>

        {userIsDeclined && isActive ? (
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => this.showJoinCompanyModal()}
          >
            Pakartoti prašymą
          </Button>
        ) : null}
      </React.Fragment>
    )
  }

  renderAccountStatus({ userAlreadyJoined, isActive, userIsPending, userIsDeclined, userIsInactive }) {
    if (!isActive) {
      return 'Įmonės paskyra laukia BIMGates administratoriaus patvirtinimo';
    }

    if (!userAlreadyJoined) {
      return 'Neesate prisijungęs prie šios įmonės';
    }

    let message = 'Jūs jau esate prisijungęs prie įmonės';
    if (userIsPending) {
      message = 'Laukiama įmonės administratoriaus patvirtinimo';
    } else if (userIsDeclined) {
      message = 'Prašymas prisijungti buvo atmestas';
    } else if (userIsInactive) {
      message = 'Paskyra deaktyvuota';
    }

    return message;
  }

  renderAccountButton({ userAlreadyJoined, isActive, userIsPending, userIsDeclined, userIsInactive, userIsActive }) {
    if (!isActive || userIsPending) {
      return null;
    }

    let button = (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => this.showJoinCompanyModal()}
      >
        Prisijungti
      </Button>
    )

    if (userAlreadyJoined && (userIsInactive || userIsDeclined)) {
      button = (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => this.showJoinCompanyModal()}
        >
          Pakartoti prašymą
        </Button>
      )
    } else if (userIsActive) {
      return null;
    }

    return button;
  }

  renderCompany() {
    const { company, searched } = this.props;
    const { showSearch } = this.state;

    if (!showSearch) {
      return;
    }

    if (!searched && !company) {
      return;
    }

    if (searched && !company) {
      return (
        <CommonPanel className={classes.panel}>
          <Typography variant="subtitle1">
            Įmonės su nurodytu įmonės kodu sistemoje nėra. Galite užregistruoti įmonę jei turite įmonės atstovavimo teises.
          </Typography>

          <div style={{ marginTop: 10 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.showRegisterCompanyModal()}
            >
              Registruoti naują įmonę
            </Button>
          </div>
        </CommonPanel>
      );
    }

    return (
      <CommonPanel className={classes.panel} noPadding>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pavadinimas</TableCell>
              <TableCell>Įmonės kodas</TableCell>
              <TableCell>Adresas</TableCell>
              <TableCell>Paskyros būsena</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{company.name}</TableCell>
              <TableCell>{company.code}</TableCell>
              <TableCell>{company.address}</TableCell>
              <TableCell>{this.renderAccountStatus(company)}</TableCell>
              <TableCell>{this.renderAccountButton(company)}</TableCell>
              {/* <TableCell align="right">
                {company.userAlreadyJoined ? this.renderAlreadyJoinedMessage(company) : (
                  <React.Fragment>
                    {company.isActive ? (
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => this.showJoinCompanyModal()}
                      >
                        Prisijungti
                      </Button>
                    ) : (
                      <Typography variant="body1">
                        <strong>Laukiama įmonės registracijos patvirtinimo</strong>
                      </Typography>
                    )}
                  </React.Fragment>
                )}
              </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </CommonPanel>
    )
  }

  render() {
    const { classes, company } = this.props;
    const { isIndividual } = this.state;

    const user = getCurrentUser();

    return (
      <Companies
        title={<FormattedMessage id="Companies.CompaniesSearch" defaultMessage="Naujos paskyros registracija" />}
      >
        
        <div className={classes.content}>
          {this.renderForm()}

          {this.renderCompany()}
        </div>

        {/* Company registration form */}
        {this.state.registerCompanyModalVisible ? (
          <FormDialog
            visible={this.state.registerCompanyModalVisible}
            component={(
              <CompaniesForm
                embed
                registering
                prefill={{ code: this.state.code, type: this.state.type, email: user.email, phone: user.phone }}
              />
            )}
            title={isIndividual ? 'Savarankiškai dirbančio paskyros registracija' : 'Įmonės registracija'}
            formId="companiesForm"
            onClose={this.closeRegisterCompanyModal.bind(this)}
          />
        ) : null}

        {/* Company join request form */}
        {company ? (
          <FormDialog
            visible={this.state.joinCompanyModalVisible}
            component={(
              <JoinRequest
                onSubmit={this.onJoinCompanySubmit.bind(this)}
              />
            )}
            title={`Prašymas prisijungti prie įmonės ${company.name.toUpperCase()}`}
            formId="companyJoinForm"
            onClose={this.closeJoinCompanyModal.bind(this)}
          />
        ) : null}
      </Companies>
    );
  }
}

const mapStateToProps = ({ companiesSearch, dispatch }) => {
  return { 
    ...companiesSearch,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(injectIntl(CompaniesSearch));