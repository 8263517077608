import React from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import styles from './styles';

class CommonDialog extends React.Component {

  render() {
    const { visible, children, title, actions, onClose, maxWidth = 'sm', fullWidth = true } = this.props;

    return (
      <Dialog
        open={visible}
        onClose={onClose ? () => onClose() : undefined}
        aria-labelledby="common-dialog-title"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        {title ? <DialogTitle id="common-dialog-title">{title}</DialogTitle> : null}
        
        {children}

        { actions ? <DialogActions>{actions}</DialogActions> : null}
      </Dialog>
    )
  }
}

export default withStyles(styles)(CommonDialog);