import api from 'utils/api';

export const createOne = async (details) => {
  const response = await api.post('/companies', details);
  const { company } = response.data;

  return company;
}

export const updateOne = async (details) => {
  const response = await api.put(`/companies/${details._id}`, details);
  const { company } = response.data;

  return company;
}

export const getAll = async () => {
  const response = await api.get('/companies');
  const companies = response.data;

  return companies;
}

export const getOne = async (id) => {
  const response = await api.get(`/companies/${id}`);
  const company = response.data;

  return company;
}

export const deleteOne = async (id) => {
  const status = id;
  console.log(`Deleting ${id}...`);
  // TODO faking async call
  await new Promise(resolve => setTimeout(resolve, 3000));

  console.log('Deleted');
  return status;
}

export const deleteBatch = async (ids) => {
  const status = ids;
  console.log(`Deleting ${ids.join(', ')}...`);
  // TODO faking async call
  await new Promise(resolve => setTimeout(resolve, 3000));
  
  console.log('Deleted');
  return status;
}

export const searchCode = async (code) => {
  const response = await api.get(`/companies/code/${code}`);
  const company = response.data;

  return company;
}

export const registerNew = async (details) => {
  const { data } = await api.post('/companies/register', details);

  return data;
}

export const setActive = async (id, isActive) => {
  await api.put(`/companies/${id}/set-active`, { isActive });
}

export const joinRequest = async ({ company, ...rest }) => {
  await api.post(`/companies/${company}/join`, rest);
}