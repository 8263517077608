import { canAccess } from 'utils/helpers';

const typeOptions = () => Promise.resolve([
  {
    label: 'Statytojas (užsakovas)',
    value: 'builder',
  },
  {
    label: 'Sąmatininkas',
    value: 'estimator',
  },
  {
    label: 'Statinio projektuotojas',
    value: 'designer',
  },
  {
    label: 'Rangovas',
    value: 'contractor',
  },
  {
    label: 'Inžinerinių tinklų rangovas',
    value: 'engineering-contractor',
  },
  {
    label: 'Projektо valdytojas',
    value: 'project-manager',
  },
  {
    label: 'Statinio statybos techninis prižiūrėtojas',
    value: 'technical-supervisor',
  },
  {
    label: 'Tyrinėtojas',
    value: 'researcher',
  },
  {
    label: 'Tiekėjas',
    value: 'supplier',
  },
  {
    label: 'Mokslininkas',
    value: 'scientist',
  },
  {
    label: 'Dėstytojas',
    value: 'lecturer',
  },
  {
    label: 'Studentas',
    value: 'student',
  },
  {
    label: 'Kita',
    value: 'other',
  },
]);

const organizationTypeOptions = () => Promise.resolve([
  {
    label: 'Privati įmonė',
    value: 'private-company',
  },
  {
    label: 'Savivaldybė ar savivaldybės įmonė',
    value: 'municipal',
  },
  {
    label: 'Ministerija, agentūra, kita valstybės institucija ar organizacija',
    value: 'government-organization',
  },
  {
    label: 'Valstybės įmonė (PVZ. ESO, Lietuvos geležinkeliai, vandentiekio tinklai ir pan.)',
    value: 'state-company',
  },
  {
    label: 'Dirbu individualiai',
    value: 'individual',
  },
  {
    label: 'Kita',
    value: 'other',
  },
]);

const getFields = async () => {
  let fields = [
    {
      name: 'firstName',
      label: 'Vardas',
      type: 'text',
    },
    {
      name: 'lastName',
      label: 'Pavardė',
      type: 'text',
    },
    {
      name: 'email',
      label: 'El. paštas',
      type: 'email',
    },
    {
      name: 'phone',
      label: 'Telefono nr.',
      type: 'text',
    },
    {
      name: 'types',
      label: 'Naudotojo tipas',
      type: 'select',
      options: typeOptions,
      multiple: true,
    },
    {
      name: 'organizationTypes',
      label: 'Organizacijos tipas',
      type: 'select',
      options: organizationTypeOptions,
      multiple: true,
    },
  ];

  if (canAccess('administrator.layout')) {
    fields.push({ name: 'isActivated', label: 'Vartotojas aktyvuotas', type: 'checkbox' });
  }

  return fields;
};

export default getFields;