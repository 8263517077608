import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import { getAll as getAllServices } from 'services/services';
import { getAll as getAllServicePackages } from 'services/servicePackages';
import { getSettings } from 'services/settings';

const DEFAULT_RECORD = {
  licences: 0,
}

class Store {
  record = { ...DEFAULT_RECORD }
  services = [];
  servicePackages = [];
  selectedService = null;
  settings = {};
  individualPackagePrices = [];
  

  // Action
  reset = (skipLoadedData = false) => {
    this.record = !skipLoadedData 
      ? { ...DEFAULT_RECORD } 
      : { ...DEFAULT_RECORD, months: this.record.months };
    this.selectedService = null;
    this.individualPackagePrices = [];

    if (!skipLoadedData) {
      this.settings = {};
      this.services = [];
    }

    this.setDefaultServicePackage();
  }

  // Action
  setRecord = record => {
    this.record = record;
  }

  // Action
  setIndividualPackagePrices = prices => {
    this.individualPackagePrices = prices;
  }

  // Action
  setSettings = settings => {
    this.settings = settings;
  }

  // Action
  setServices = services => {
    this.services = services;
  }

  // Action
  setServicePackages = servicePackages => {
    this.servicePackages = servicePackages;
  }

  // Action
  setSelectedService = serviceId => {
    let service = null;

    if (serviceId) {
      service = _.find(this.services, s => s._id === serviceId);
    }

    this.selectedService = service;
  }

  setRecordValue = (field, value) => {
    this.record[field] = value;

    switch (field) {
      case 'service':
        this.setSelectedService(value);
        break;

      case 'servicePackage':
        // if (value !== 'individual') this.setRecordValue('rules', []);
        this.setRecordValue('rules', []);
        break;

      case 'rules':
        this.calcIndividualPrices();
        break;

      default:
        break;
    }

    this.calculateItemPrice();
  }

  calcIndividualPrices() {
    const { rules, licences } = this.record;
    const prices = [];
    let priceForMonths = 0;
    let additionalLicencePrice = 0;

    if (!rules || !rules.length || !this.settings.services) this.setIndividualPackagePrices(prices);

    _.forEach(rules, rule => {
      const price = _.get(_.find(this.selectedService.rules, { rule }), 'priceMonth', 0);
      priceForMonths = priceForMonths + _.toNumber(price);
    });

    _.forEach(this.settings.services.pricingRange, ({ months, discountPercent }) => {
      const price = priceForMonths * (1 - (discountPercent / 100)) * months;

      prices.push({ months, price });
    });

    this.setIndividualPackagePrices(prices);

    let licenceDiscountPercent = this.getLicenceDiscount(licences);
    additionalLicencePrice = (priceForMonths * (1 - (licenceDiscountPercent / 100)));
    this.record.firstLicencePrice = priceForMonths;
    this.record.additionalLicencePrice = additionalLicencePrice;
  }

  getLicenceDiscount(licences) {
    for (const range of this.settings.services.licencingRange) {
      const from = range.from || 1;
      const to = range.to || 9999;
      
      if (_.inRange(licences, from + 1, to + 1)) {
        // Found require range in settings to pick discount
        return range.discountPercent;
      }
    }

    return 0;
  }

  calculateItemPrice() {
    if (!this.selectedService || !this.settings.services) return;

    const { months, licences, servicePackage } = this.record;
    let priceForMonths = 0;

    if (servicePackage !== 'individual') {
      const pkg = _.find(this.selectedService.packages, { servicePackage });
      if (pkg) {
        const item = _.find(pkg.prices, { months });
        priceForMonths = item ? item.price : 0;
      }
    } else {
      const item = _.find(this.individualPackagePrices, { months });
      priceForMonths = item ? item.price : 0;
    }

    let licenceDiscountPercent = this.getLicenceDiscount(licences);
    // for (const range of this.settings.services.licencingRange) {
    //   const from = range.from || 1;
    //   const to = range.to || 9999;
      
    //   if (_.inRange(licences, from + 1, to + 1)) {
    //     // Found require range in settings to pick discount
    //     licenceDiscountPercent = range.discountPercent;
    //     break;
    //   }
    // }

    let price = 0;
    let discountAmount = 0;
    let additionalLicencePrice = 0;

    // First licence - full price
    const firstLicence = priceForMonths;

    if (licenceDiscountPercent === 0) {
      price = priceForMonths * licences;
    } else {
      // Apply discount to all remaining licences
      additionalLicencePrice = (priceForMonths * (1 - (licenceDiscountPercent / 100)));
      const restLicences = additionalLicencePrice * (licences - 1);

      price = firstLicence + restLicences;
      discountAmount = (priceForMonths * licences) - price;
    }

    this.record.price = price;
    this.record.discountAmount = discountAmount;
    this.record.discountPercent = licenceDiscountPercent;

    this.record.firstLicencePrice = priceForMonths;
    this.record.additionalLicencePrice = additionalLicencePrice;
  }

  setDefaultServicePackage = () => {
    if (!this.servicePackages || !this.settings.services || this.record.servicePackage) return;

    const defaultPackage = _.find(this.servicePackages, { title: 'Bazinis' });
    this.setRecordValue('servicePackage', defaultPackage ? defaultPackage._id : 'individual');
  }

  loadData = async (editingRecord = null) => {
    const services = await getAllServices();
    const servicePackages = await getAllServicePackages();
    const settings = await getSettings();

    this.setServices(services);
    this.setServicePackages(servicePackages);
    this.setSettings(settings);

    this.setDefaultServicePackage();

    // Load existing item for editing
    if (editingRecord) {
      const record = { ...editingRecord };
      record.servicePackage = record.servicePackage || 'individual';
      this.setRecordValue('service', record.service);
      this.setRecord(record);
      
      if (record.servicePackage === 'individual') this.calcIndividualPrices();
    }
  }

}

decorate(Store, {
  record: observable,
  services: observable,
  settings: observable,
  individualPackagePrices: observable,
  servicePackages: observable,
  selectedService: observable,
  setRecord: action,
  calculateItemPrice: action,
  setSettings: action,
  setRecordValue: action,
  setIndividualPackagePrices: action,
  setServices: action,
  setServicePackages: action,
  setSelectedService: action,
  reset: action,
});

export default new Store;