import React, { Component } from 'react';
import _ from 'lodash';
import { DataTable } from 'components';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
} from '@material-ui/core';

import styles from './styles';

import Accounts from 'views/Accounts';

import { getAll, batchDelete } from 'views/Accounts/_actions';

class AccountList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    const columns = [
      'ID',
      'LINK',
      'Pavadinimas',
      'El. paštas',
      'Įmonė',
      'Būsena',
      'Pareigos',
      'Sukurta',
      'Atnaujinta',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/accounts/${record._id}`);
      row.push(record.user ? record.user.firstName : 'IŠTRINTAS VARTOTOJAS')
      row.push(record.user ? record.user.email : 'IŠTRINTAS VARTOTOJAS')
      row.push(record.company ? record.company.name : null);
      row.push(record.status);
      row.push(record.position);
      row.push(record.createdAt);
      row.push(record.updatedAt);
      return row;
    });

    return data;
  }

  onDelete(indexes) {
    const { dispatch, list: { accounts } } = this.props;

    const ids = _.map(indexes, index => accounts[index]._id);

    if (ids.length) {
      dispatch(batchDelete(ids));
    }
  }

  renderAccounts() {
    const { classes, isLoading, list } = this.props;
    const { accounts } = list;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(accounts);

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <DataTable
        title="Paskyros"
        columns={columns}
        data={data}
        createUrl="/accounts/create"
        onDeleteBatch={indexes => this.onDelete(indexes)}
        onDelete={index => this.onDelete([index])}
      />
    );
  }



  render() {
    const { classes } = this.props;
    return (
      <Accounts
        title={<FormattedMessage id="accountsAccountList" defaultMessage="Paskyros" />}
      >
        
        <div className={classes.content}>
          {this.renderAccounts()}
        </div>

      </Accounts>
    );
  }
}

const mapStateToProps = ({ accounts, dispatch }) => {
  return { 
    ...accounts,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(AccountList);