import api from 'utils/api';

const servicePath = '/services/norms';

export const getPackages = async (params) => {
  const response = await api.post(`${servicePath}/packages`, params);
  const records = response.data;

  return records;
}

export const getVersions = async () => {
  const response = await api.post(`${servicePath}/versions`);
  const records = response.data;

  return records;
}

export const getTree = async (params) => {
  const response = await api.post(`${servicePath}/tree`, params);
  const records = response.data;

  return records;
}

export const getParameters = async (params) => {
  const response = await api.post(`${servicePath}/parameters`, params);
  const records = response.data;

  return records;
}

export const getDetails = async (params) => {
  const response = await api.post(`${servicePath}/details`, params);
  const records = response.data;

  return records;
}

export const doSearch = async (params) => {
  const response = await api.post(`${servicePath}/search`, params);
  const records = response.data;

  return records;
}

export const doCalculate = async (params) => {
  const response = await api.post(`${servicePath}/calculate`, params);
  const records = response.data;

  return records;
}

export const getExcelExport = async (params) => {
  const response = await api.post(`${servicePath}/export-excel`, params);
  const records = response.data;

  return records;
}