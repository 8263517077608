import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import { Form } from 'react-final-form';

import styles from './styles';
import fields from './fields';

import { CommonPanel } from 'components';
import { FormField } from 'components';
import PermissionGroups from 'views/PermissionGroups';
import PermissionPicker from './components/PermissionPicker';

import { getOne, setValue, updateOne, createOne, resetForm } from './_actions';


// Inject reducers
import { store } from 'utils/store';
import { permissionGroupsForm } from './_reducers';
store.injectReducer('permissionGroupsForm', permissionGroupsForm);

class PermissionGroupsForm extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id) {
      dispatch(getOne(match.params.id));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }

  validate(values) {
    let errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }

    return errors;
  }

  onSubmit(values) {
    const { dispatch, permissions } = this.props;
    values.permissions = permissions;

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm() {
    document
      .getElementById('permissionGroupsForm')
      .dispatchEvent(new Event('submit', { cancelable: true }));
  }

  onPermissionsChange(selected) {
    const { dispatch } = this.props;

    dispatch(setValue('permissions', selected));
  }

  render() {
    const { classes, record, permissions } = this.props;

    return (
      <PermissionGroups
        title="Teisių grupė"
      >
        
        <CommonPanel>
          <Form
            initialValues={record}
            onSubmit={this.onSubmit.bind(this)}
            validate={this.validate.bind(this)}
            render={({ handleSubmit }) => (
              <form
                id="permissionGroupsForm"
                className={classes.form}
                onSubmit={handleSubmit}
              >
                
                {_.map(fields, field => <FormField key={field.name} {...field}/>)}
                
                <PermissionPicker
                  selected={permissions}
                  onChange={this.onPermissionsChange.bind(this)}
                />
              </form>
            )}
          />

          <Button
            color="primary"
            variant="contained"
            onClick={event => this.submitForm(event)}
          >
            Save
          </Button>

        </CommonPanel>

      </PermissionGroups>
    );
  }
}

const mapStateToProps = ({ permissionGroupsForm, dispatch }) => {
  return { 
    ...permissionGroupsForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(PermissionGroupsForm);