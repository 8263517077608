import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { DataTable } from 'components';

import ClassifierGroups from 'views/ClassifierGroups';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { classifierGroupsList } from './_reducers';
store.injectReducer('classifierGroupsList', classifierGroupsList);


class ClassifierGroupsList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    
    const columns = [
      'ID',
      'LINK',
      'Kodas',
      'Pavadinimas',
      'Pastabos',
      'Priklauso grupei',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/classifier-groups/${record._id}`);
      row.push(record.code);
      row.push(record.title);
      row.push(record.comment);
      row.push(record.parent);
      
      return row;
    });

    return data;
  }

  render() {
    const { classes, records, isLoading } = this.props;
    if (isLoading) {
      return (
        <h1>Kraunasi...</h1>
      )
    }
    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <ClassifierGroups
        title="Klasifikatoriaus grupės"
      >
        
        <div className={classes.content}>
          <DataTable 
            // title={intl.formatMessage({ id: 'Companies.CompanyList.title', defaultMessage: 'Įmonių sąrašas'})}
            columns={columns}
            data={data}
            createUrl="/classifier-groups/create"
            // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
            // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
          />
        </div>

      </ClassifierGroups>
    );
  }
}

const mapStateToProps = ({ classifierGroupsList, dispatch }) => {
  return { 
    ...classifierGroupsList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ClassifierGroupsList);