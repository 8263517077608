import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Loader } from 'components';
import { validateSession } from 'views/Users/_actions';

class PrivateRoute extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    
    dispatch(validateSession());
  }

  render() {
    const { 
      component: Component, 
      isLoading,
      isAuthenticated,
      canReloadOnLinkClick,
      ...rest
    } = this.props;

    if (isLoading) {
      return (
        <Loader />
      );
    }
    
    return (
      <Route
        {...rest} render={props => (
          isAuthenticated
            ? <Component {...props} key={canReloadOnLinkClick ? Math.random() : null} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )}
      />
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { ...app };
}

export default connect(mapStateToProps)(PrivateRoute);
