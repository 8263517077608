import ProjectsList from './components/ProjectsList';
import ProjectsForm from './components/ProjectsForm';
import ProjectsDashBoard from './components/ProjectsDashBoard';

export default [
  {
    key: 'list',
    component: ProjectsList,
    path: '/projects',
    public: false,
  },
  {
    key: 'list',
    component: ProjectsDashBoard,
    path: '/projects/:id/dashboard',
    public: false,
  },
  // {
  //   key: 'list',
  //   component: ProjectModel,
  //   path: '/projects/model/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectsDocuments,
  //   path: '/projects/documents/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectMembers,
  //   path: '/projects/members/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectCommunication,
  //   path: '/projects/communication/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectHistory,
  //   path: '/projects/history/:id',
  //   public: false,
  // },
  {
    key: 'create',
    component: ProjectsForm,
    path: '/projects/create',
    public: false,
  },
  {
    key: 'edit',
    component: ProjectsForm,
    path: '/projects/:id',
    public: false,
  },
 
];