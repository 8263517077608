import AccountList from './components/AccountList';
import AccountDetails from './components/AccountDetails';

export default [
  {
    key: 'list',
    component: AccountList,
    path: '/accounts',
    public: false,
  },
  {
    key: 'create',
    component: AccountDetails,
    path: '/accounts/create',
    public: false,
  },
  {
    key: 'edit',
    component: AccountDetails,
    path: '/accounts/:id/:action',
    public: false,
  },
  {
    key: 'edit',
    component: AccountDetails,
    path: '/accounts/:id',
    public: false,
  }
];