import axios from 'axios';

import { history } from './history';
import { API_URL } from './config';
import { toast } from './helpers';

const api = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  // timeout: 1000,
  headers: {'Content-Type': 'application/json'},
});

api.interceptors.request.use((config) => {
  // Send current locale
  const locale = localStorage.getItem('locale') || 'lt';
  config.headers['X-LOCALE'] = locale;
  
  // Send auth token if available
  const token = localStorage.getItem('jwt');
  if ( token != null ) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, err => Promise.reject(err));

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (!error.response) {
    return Promise.reject(error);
  }
  let message = null;
  switch (error.response.status) {
    // Access not allowed
    case 403:
      message = error.response.data.message || 'Neturite teisės atlikti šį veiksmą';
      toast(message, 'error');

      history.push('/');
      break;
    
    // Bad credentials or invalid token
    case 401:
      console.log('Authorization failed');
      localStorage.removeItem('jwt');
      localStorage.removeItem('state');

      // history.push('/');
      window.location.href = '/login';
      break;

    default:
      return Promise.reject(error);
  }
});

export default api;
