import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/accounts';

export const getAll = async (params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}

export const checkAccess = async () => {
  const { data } = await api.get(`${servicePath}/check-access`);
  return data;
}

export const addLicence = async (id, details) => {
  const { data } = await api.post(`${servicePath}/${id}/licences`, details);
  return data;
}

export const removeLicence = async (id, licenceId) => {
  const { data } = await api.delete(`${servicePath}/${id}/licences/${licenceId}`);
  return data;
}

export const getDetails = async (id) => {
  const { data } = await api.get(`${servicePath}/${id}/details`);
  return data;
}

export const setActive = async (id) => {
  const response = await api.get(`/accounts/${id}/set`);
  const account = response.data;

  return account;
}