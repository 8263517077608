import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/orders';

export const getAll = async (params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}

export const getOne = async (id) => {
  const response = await api.get(`${servicePath}/${id}`);
  const records = response.data;

  return records;
}

export const create = async (details, params) => {
  const query = objectToQueryString(params);
  const response = await api.post(`${servicePath}${query ? '?' + query : ''}`, details);
  const record = response.data;

  return record;
}

export const update = async (id, details, params) => {
  const query = objectToQueryString(params);
  const response = await api.put(`${servicePath}/${id}${query ? '?' + query : ''}`, details);
  const record = response.data;

  return record;
}

export const calculate = async (details) => {
  const response = await api.post(`${servicePath}/calculate`, details);
  const record = response.data;

  return record;
}

export const getSummary = async (params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}/summary${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}