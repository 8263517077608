import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
} from '@material-ui/core';

import styles from './styles';

import { DataTable } from 'components';
import Documents from 'views/Documents';
import { getAll } from 'views/Documents/_actions';

class DocumentList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  onDocumentsSelect(documents) {
    // eslint-disable-next-line no-console
    console.log({ documents });
  }

  prepareTableColumns() {
    const { intl } = this.props;
    const columns = [
      'ID',
      'LINK',
      intl.formatMessage({ id: 'documentsDocumentsTableTitle', defaultMessage: 'Pavadinimas'}),
      intl.formatMessage({ id: 'documentsDocumentsTableParentProject', defaultMessage: 'Priklauso projektui'}),
      intl.formatMessage({ id: 'documentsDocumentsTableType', defaultMessage: 'Tipas'}),
      intl.formatMessage({ id: 'documentsDocumentsTableSize', defaultMessage: 'Dydis'}),
      intl.formatMessage({ id: 'documentsDocumentsTableCreated', defaultMessage: 'Sukurta'}),
      intl.formatMessage({ id: 'documentsDocumentsTableCreatedBy', defaultMessage: 'Sukūrė'}),
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/documents/${record._id}`);
      row.push(record.title);
      row.push(record.project ? record.project.title : '');
      row.push(record.type);
      row.push(record.size);
      row.push(moment(record.createdAt).utc().format('YYYY-MM-DD'));
      row.push(record.createdBy);
      
      return row;
    });

    return data;
  }

  renderDocuments() {
    const { classes, isLoading, list, intl } = this.props;
    const { documents } = list;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(documents);

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <DataTable 
        title={intl.formatMessage({ id: 'documentsDocumentList', defaultMessage: 'Dokumentai'})}
        columns={columns}
        data={data}
        createUrl="/documents/create"
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Documents
        title={<FormattedMessage id="documentsDocumentList" defaultMessage="Dokumentai" />}
      >
        
        <div className={classes.content}>
          {this.renderDocuments()}
        </div>

      </Documents>
    );
  }
}

const mapStateToProps = ({ documents, dispatch }) => {
  return { 
    ...documents,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(injectIntl(DocumentList));