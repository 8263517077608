import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  withStyles,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import styles from './styles';
import getFields from './fields';

import { CommonPanel } from 'components';
import { FormField2 } from 'components';
import Projects from 'views/Projects';
import { getOne, updateOne, createOne, resetForm, valueChanged } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { projectsForm } from './_reducers';
store.injectReducer('projectsForm', projectsForm);

class ProjectsForm extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match && match.params.id) {
      dispatch(getOne(match.params.id));
      return;
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }


  validate(values) {
    let errors = {};

    if (!values.code) {
      errors.code = 'Privalomas laukas';
    }

    if (!values.title) {
      errors.title = 'Privalomas laukas';
    }

    return errors;
  }

  submitForm() {
    const { onSubmit = null, dispatch } = this.props;
    const { record } = this.props;

    if (record._id) {
      // Update
      dispatch(updateOne(record));
    } else {
      // Create
      dispatch(createOne(record, onSubmit));
    }
  }

  onChange({ name, value }) {
    const { dispatch } = this.props;
    
    dispatch(valueChanged({ name, value }));
  }

  renderFormField({ name, ...field }) {
    const { record } = this.props;
    
    return (
      <FormField2
        {...field}
        name={name}
        autoComplete={`project-${name}`}
        key={name}
        value={record ? record[name] : ''}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderForm() {
    const fields = getFields();

    return (
      <div>
        {_.map(fields, field => this.renderFormField(field))}
      </div>
    )
  }

  render() {
    const { embed, record } = this.props;

    // If embeded in dialog
    if (embed) {
      return (
        <React.Fragment>
          <DialogContent>
            {this.renderForm()}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.submitForm.bind(this)}
            >
              Išsaugoti
            </Button>
            <Button
              onClick={() => this.props.onCancel()}
              color="primary"
            >
              Atšaukti
            </Button>
          </DialogActions>
        </React.Fragment>
      )
    }

    return (
      <Projects
        title={record ? `Projektas: ${record.title}` : 'Projektas'}
        breadcrumbs={[
          { title: 'Projektai', to: '/projects' },
          { title: record ? (record.title || '-') : '' },
        ]}
      >
        <CommonPanel
          footer={(
            <Button
              color="primary"
              variant="contained"
              onClick={this.submitForm.bind(this)}
            >
              Išsaugoti
            </Button>
          )}
        >
          {this.renderForm()}
        </CommonPanel>
      </Projects>
    );
  }
}

const mapStateToProps = ({ projectsForm, dispatch }) => {
  return { 
    ...projectsForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ProjectsForm);