import React from 'react';
import Container from './Container';
import store from './_store';

class NormVersionPicker extends React.Component {

  render() {

    return <Container {...this.props} store={store} />;
  }
}

export default NormVersionPicker;