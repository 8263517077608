export default () => ({
  root: {},
  wrap: {
    background: '#ffffff',
    padding: 0,
    maxWidth: '1200px',
  },
  itemsTable: {

    '& th, td': {
      border: '1px solid #DFE3E8',

      '&.actions': {
        border: '1px solid #ffffff',
      },
    },
  },
  inputRight: {
    textAlign: 'right',

    '& input': {
      textAlign: 'right',
      paddingRight: 0,
    },
  }
});
