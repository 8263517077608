import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Typography,
} from '@material-ui/core';

// import { Dashboard as DashboardLayout } from 'layouts';
import Projects from 'views/Projects';

import { FeaturedItem } from 'components';
import { getProject } from './_actions';
import { history } from 'utils/history';
import styles from './styles';
import { checkAccess } from 'services/accounts';

// Inject reducers
import { store } from 'utils/store';
import { projectsDashboard } from './_reducers';
import RecoveryPriceTable from 'components/RecoveryPriceTable';
store.injectReducer('projectsDashboard', projectsDashboard);


class ProjectsDashboard extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;
    
    if (match.params.id) {
      dispatch(getProject(match.params.id));
    }
  }

  async checkDemoAccess() {
    try {
      await checkAccess();

      history.push(`/recovery-price/create?project=${this.props.project.id}`);
    } catch(err) {
      window.alert(err.response.data.message);
    }
  }

  renderServiceButtons() {
    const { classes, project } = this.props;
    
    if (!project) {
      return;
    }

    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FeaturedItem
            className={classes.item}
            subtitle="Paslauga"
            title="Atkuriamoji vertė"
            footerLink={`/recovery-price/create?project=${project.id}`}
            footerClickHandler={() => this.checkDemoAccess()}
            footerText="Naujas skaičiavimas"
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, isLoading, project } = this.props;

    const title = (
      <span>
        <FormattedMessage id="Project" defaultMessage="Projektas" />
        {project ? (
          <React.Fragment>
            <Typography variant="h5">{project.title}</Typography>
          </React.Fragment>
        ) : null}
      </span>
    );

    return (
      <Projects
        title={title}
        // menu={menu}
        // menuTitle={menuTitle}
        isContentLoading={isLoading}
        breadcrumbs={[
          { title: 'Projektai', to: '/projects' },
          { title: project ? project.title : '' },
          { title: 'Apžvalga' },
        ]}
      >

        {project && !isLoading ? (
          <div className={classes.root}>
            {this.renderServiceButtons()}

            <div className={classes.verticalBlock}>
              <RecoveryPriceTable projectId={project.id} />
            </div>
          </div>
        ) : null}

      </Projects>
    );
  }
}

const mapStateToProps = ({ projectsDashboard, dispatch }) => {
  return { 
    ...projectsDashboard,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(injectIntl(ProjectsDashboard));
