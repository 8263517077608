import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import styles from './styles';

import { LocaleSwitcher } from 'components';

class Auth extends Component {

  renderTitle() {
    const { classes, title } = this.props;
    return !title ? null : (
      <Typography
        className={classes.title}
        variant="h2"
      >
        {title}
      </Typography>
    );
  }

  renderSubtitle() {
    const { classes, subtitle } = this.props;
    return !subtitle ? null : (
      <Typography
        className={classes.subtitle}
        variant="body1"
      >
        {subtitle}
      </Typography>
    );
  }

  render() {
    const { classes, children, loading = false } = this.props;

    return (
      <div className={classes.root}>
        {loading ? (
          <LinearProgress 
            style={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
          /> 
        ) : null}
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <div className={classes.quote} />
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <LocaleSwitcher />
              </div>
              <div className={classes.contentBody}>
                <form autoComplete="off" className={classes.form}>
                  {this.renderTitle()}
                  {this.renderSubtitle()}

                  {children}

                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ dispatch, app }) => {
  return { dispatch, ...app };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Auth);
