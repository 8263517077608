export default theme => ({
  root: {},
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  formControl: {
    marginTop: theme.spacing(2),
    // minWidth: 120,
    // maxWidth: 300,
    width: '100%',
  },
});
