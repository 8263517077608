import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Button,
  Grid,
  // CircularProgress,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import Companies from 'views/Companies';
import { CommonPanel, CommonDialog, FormField2 } from 'components';
import { getCurrentAccount, toast, timestampToDate } from 'utils/helpers';
import { history } from 'utils/history';


import styles from './styles';

class ServicesManager extends React.Component {
  state = {
    showModal: false,
    record: {},
  }

  componentDidMount() {

    const { company } = getCurrentAccount();
    if (!company) {
      toast('Su šia paskyra nėra susietos įmonės', 'error');
      history.push('/');
      return;
    }

    this.props.store.setCompany(company);
    this.props.store.loadData();
  }

  onShowModal(idx) {
    const account = this.props.store.data.accounts[idx];
    this.props.store.setAccount(account);
    this.props.store.loadServices();

    this.setState({ showModal: true });
  }

  onModalClose() {
    this.props.store.setOrderItemDetails(null);

    this.setState({ showModal: false, record: {} });
  }

  onModalSave() {
    this.props.store.createAccountLicence({ ...this.state.record });

    this.onModalClose();
  }

  async onRemoveLicence(accountId, licenceId) {

    if (window.confirm('Ar tikrai norite pašalinti priskirtą darbo vietą?')) {
      await this.props.store.removeAccountLicence(accountId, licenceId);
    }
  }

  onChange({ name, value }) {
    const record = this.state.record || {};
    record[name] = value;

    switch (name) {
      case 'service':
        if (this.props.store.accountHasService(value)) {
          window.alert('Paslauga šiai paskyrai jau priskirta');
          return;
        }

        this.props.store.loadServicePackages(value);
        break;

      case 'orderItem':
        this.props.store.setOrderItemDetails(value);
        break;

      default:
        break;
    }

    this.setState({
      record,
    });
  }

  renderFormField({ name, ...field }) {
    const { record } = this.state;
    
    return (
      <FormField2
        {...field}
        name={name}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderModalForm() {
    const { classes } = this.props;
    const { record } = this.state;
    const { orderItemDetails, data } = this.props.store;

    const serviceOptions = _.map(data.services, ({ title, _id }) => ({
      label: title, 
      value: _id,
    }));

    const servicePackageOptions = _.map(data.servicePackages, pkg => ({
      label: `${pkg.title} (galioja iki ${timestampToDate(pkg.expiresAt)}, liko ${pkg.freeLicences} darbo vt.)`,
      value: pkg._id,
    }));

    return (
      <div style={{ padding: '0px 10px 10px' }}>
        <Grid container spacing={1}>
          <Grid item xs>
            {this.renderFormField({ name: 'service', label: 'Paslauga', type: 'select', options: serviceOptions })}

            {record.service && data.servicePackages.length ? this.renderFormField({ name: 'orderItem', label: 'Priskiriamas paslaugų paketas', type: 'select', options: servicePackageOptions }) : null}

            {record.service && !data.servicePackages.length ? (
              <Typography variant="body1" style={{ marginTop: 10, textAlign: 'center'}}>
                Nėra galiojančių licencijų pasirinktai paslaugai.
                <Link to="/orders/create" className={classes.link}>Užsakyti</Link>
              </Typography>
            ) : null}
          </Grid>
          
          {orderItemDetails ? (
            <Grid item xs>
              <TableContainer>
                <Table className={classes.descriptionTable} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.descriptionLabel}>
                        Užsakymas:
                      </TableCell>
                      <TableCell className={classes.descriptionValue}>
                        <a href="#">{orderItemDetails.order.number}</a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.descriptionLabel}>
                        Galioja iki:
                      </TableCell>
                      <TableCell className={classes.descriptionValue}>
                        {timestampToDate(orderItemDetails.expiresAt)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.descriptionLabel}>
                        Darbo vietų:
                      </TableCell>
                      <TableCell 
                        className={classes.descriptionValue}
                        style={{ fontWeight: 'bold', color: `${orderItemDetails.freeLicences > 0 ? 'green' : 'red' }` }}
                      >
                        {orderItemDetails.licences} viso / {orderItemDetails.freeLicences} laisvos
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} className={classes.descriptionLabel}>
                        Funkcionalumas:
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        {orderItemDetails.ruleTitles.join('; ')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
        </Grid>
      </div>
    )
  }

  renderModal() {
    const { showModal } = this.state;
    const { orderItemDetails, account } = this.props.store;

    let actions = [];

    if (orderItemDetails && orderItemDetails.freeLicences > 0) {
      actions.push(
        <Button key="cancel" onClick={this.onModalSave.bind(this)} color="primary" variant="contained">
          Priskirti darbo vietą
        </Button>
      );
    }

    actions.push(
      <Button key="save" onClick={this.onModalClose.bind(this)} color="primary">
        Atšaukti
      </Button>
    );

    return (
      <CommonDialog
        visible={showModal}
        title={`Darbuotojas ${account ? account.user.fullName : '-'}`}
        onClose={() => this.onModalClose()}
        actions={actions}
        maxWidth="xs"
      >
        {this.renderModalForm()}
      </CommonDialog>
    )
  }

  renderTable() {
    const { classes } = this.props;
    const { accounts } = this.props.store.data;

    if (!accounts.length) return null;

    let rows = [];

    _.forEach(accounts, (account, idx) => {
      const accountHeaderRow = (
        <TableRow key={`account-${account._id}`}>
          <TableCell colSpan={3} className={classes.accountHeaderRow}>
            {account.user ? account.user.fullName : '-'}
          </TableCell>
          <TableCell className={classes.accountHeaderRow} style={{ textAlign: 'right' }}>
            <Button key="cancel" onClick={() => this.onShowModal(idx)} color="primary" size="small">
              Pridėti
            </Button>
          </TableCell>
          <TableCell className="actions" />
        </TableRow>
      )

      rows.push(accountHeaderRow);

      const accountLicenceRows = _.map(account.licences, licence => (
        <TableRow key={licence._id}>
          <TableCell>{licence.service.title}</TableCell>
          <TableCell>{licence.servicePackage ? licence.servicePackage.title : 'Individualus'}</TableCell>
          <TableCell>{licence.description}</TableCell>
          <TableCell>{timestampToDate(licence.expiresAt)}</TableCell>
          <TableCell className="actions">
            <Button onClick={() => this.onRemoveLicence(account._id, licence._id)} color="primary" size="small">
              Pašalinti
            </Button>
          </TableCell>
        </TableRow>
      ));

      rows.push(accountLicenceRows);
    });

    return (
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Paslauga</TableCell>
              <TableCell>Paketas</TableCell>
              <TableCell>Funkcionalumas</TableCell>
              <TableCell>Galioja iki</TableCell>
              <TableCell className="actions" style={{ width: '25%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Companies title="Įmonės paslaugų paketų valdymas">
        <div className={classes.wrap}>
          
          <CommonPanel>
            {this.renderTable()}
          </CommonPanel>

        </div>

        {this.renderModal()}
      </Companies>
    )
  }
}

export default compose(withStyles(styles))(observer(ServicesManager));