import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

// Shared components
import {
  CommonPanel,
} from 'components';

// Component styles
import styles from './styles';

class NtkMaterialPickerHorizontal extends Component {
  state = {
    selectedMaterials: [],
  }

  onChange({ target }) {
    const { handleChange } = this.props;
    const [ type, value ] = target.value.split('|');
    const { selectedMaterials } = this.state;
    const existing = _.find(selectedMaterials, { type });

    if (target.checked) {
      // Add to selection

      if (existing) {
        // Type was already selected
        existing.values = [...existing.values, value];
      } else {
        // Type wasn't selected yet
        selectedMaterials.push({ type, values: [value] });
      }
    } else {
      // Remove from selection

      _.remove(existing.values, val => val === value);
      _.remove(selectedMaterials, material => material.values.length === 0);
    }

    this.setState({
      selectedMaterials,
    });

    handleChange({
      name: 'materials',
      value: selectedMaterials,
    });
  }

  renderTable() {
    const { classes, materials, initialMaterials, recordMaterials } = this.props;

    // Maximum number of columns for table
    let maxMaterials = 1;
    _.forEach(initialMaterials, ({ values }) => {
      const length = values.length;

      maxMaterials = maxMaterials < length ? length : maxMaterials;
    });
    
    // Build table rows
    const rows = _.map(initialMaterials, ({ type, values }) => {
      let emptyColumns = maxMaterials;
      // Collect material options (combine type with value for actual control value)
      const checkboxes = _.map(values, (value) => {
        // Disable all unchecked inputs if there's no available materials left
        // otherwise disable inputs by material availability
        const material = _.find(materials.length ? materials : recordMaterials, { type });
        const disabled = material ? material.values.indexOf(value) === -1 : true;

        emptyColumns = emptyColumns - 1;
        
        return (
          <TableCell key={`cols-${emptyColumns}`} className={classes.columns}>
            <FormControlLabel
              className={classes.material}
              key={value}
              control={<Checkbox onChange={this.onChange.bind(this)} value={[type, value].join('|')} />}
              label={value}
              disabled={disabled}
            />
          </TableCell>
        );
      });

      if (emptyColumns > 0) {
        checkboxes.push(<TableCell key={`cols-empty-${emptyColumns}`} colSpan={emptyColumns} />);
      }

      // Render material table
      return (
        <TableRow key={type}>
          <TableCell className="headcol narrow">
            <strong>{type}</strong>
          </TableCell>
          {checkboxes}
        </TableRow>
      )
    })

    return (
      <div className="ntk-table">
        <Table size="small" className={classes.table}>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </div>
    )
  }

  render() {

    return (
      <CommonPanel
        title={<FormattedMessage id="buildingMaterials" defaultMessage="Statinio parametrai" />}
        noPadding
      >   
        {this.renderTable()}
      </CommonPanel>
    );
  }
}

export default withStyles(styles)(NtkMaterialPickerHorizontal);
