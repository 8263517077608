import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

import {
  CommonPanel,
} from 'components';

// Component styles
import styles from './styles';

class CalculatedResults extends Component {

  onChange(e) {
    const { handleChange } = this.props;
    const data = {
      name: e.target.name,
      value: e.target.value,
    };

    handleChange(data);
  }

  render() {
    const {
      classes,
      totals,
      unit,
      usageStartDate,
      wallTypes,
      wallType,
      handleChange,
      type,
      buildingType,
    } = this.props;

    if (wallTypes) {
      if (wallTypes.length === 1 && _.first(wallTypes) !== wallType) {
        handleChange({ name: 'wallType', value: _.first(wallTypes) });
      }
    }

    return (
      <CommonPanel
        title={<FormattedMessage id="recoveryPriceCalculationResults" defaultMessage="Skaičiavimų rezultatai" />}
        noPadding
      >
          
        <Table className={classes.table}>
          <TableBody>

            {buildingType ? (
              <TableRow>
                <TableCell component="th" scope="row" style={{ width: '250px' }}>Statinių tipas pagal paskirtį</TableCell>
                <TableCell align="right">{buildingType.titleFull}</TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell component="th" scope="row">
                Kainyno nuoroda: <a href="#"><strong>{totals.tableNumber}</strong></a>
              </TableCell>
              <TableCell align="right">
                {totals.depreciationPricePerUnit || '-'} EUR / {unit}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">Statinio vertė be ypatumų koef.</TableCell>
              <TableCell align="right">{totals.basePrice || '-'} EUR</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">Statinio vertė su ypatumų koef.</TableCell>
              <TableCell align="right">{totals.coefficientsPrice || '-'} EUR</TableCell>
            </TableRow>

            {/* {type === 'NTK pastatai' ? ( */}
            <TableRow>
              <TableCell component="th" scope="row">Statinio vertė patikslinta pagal konstrukcijas</TableCell>
              <TableCell align="right">{totals.totalRevisedPrice || '-'} EUR</TableCell>
            </TableRow>
            {/* ) : null} */}
            
            <TableRow>
              <TableCell className={classes.noBottomBorder} component="th" scope="row">Statinio vidutinė gyvavimo trukmė</TableCell>
              <TableCell className={classes.noBottomBorder} align="right">{totals.depreciationDuration || '-'} m.</TableCell>
            </TableRow>

            {type === 'NTK pastatai' ? (
              <TableRow>
                <TableCell className={classes.noTopBorder} style={{ paddingTop: 0 }} component="th" scope="row" colSpan={2}>
                  <span>kai sienų medžiaga:</span>
                  <RadioGroup
                    row
                    name="wallType"
                    className={classes.radioGroup}
                    value={wallType}
                    onChange={this.onChange.bind(this)}
                  >
                    {_.map(wallTypes, (wallType) => (
                      <FormControlLabel key={wallType} value={wallType} control={<Radio />} label={wallType} />
                    ))}
                  </RadioGroup>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow>
              <TableCell component="th" scope="row">Statinio eksploatacijos pradžia</TableCell>
              <TableCell align="right">
                <NumberFormat
                  format="####"
                  placeholder={'-'}
                  mask="_"
                  customInput={TextField}
                  className={classes.textField}
                  name="usageStartDate"
                  value={usageStartDate}
                  onBlur={this.onChange.bind(this)}
                  onKeyDown={({ key, target }) => {
                    if (key == 'Enter') {
                      target.blur();
                    }
                  }}
                  margin="dense"
                  variant="outlined"
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">Statinio amžius</TableCell>
              <TableCell align="right">{totals.buildingAge || '-'} m.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">Statinio nusidėvėjimas</TableCell>
              <TableCell align="right">{totals.depreciationPercent || '-'} %</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.boldCell} component="th" scope="row">Statinio vertė su nusidėvėjimu</TableCell>
              <TableCell className={classes.boldCell} align="right">{totals.totalDepreciatedPrice || '-'} EUR</TableCell>
            </TableRow>

          </TableBody>
        </Table>

      </CommonPanel>
    );
  }
}

export default withStyles(styles)(CalculatedResults);
