import { observable, action, decorate, toJS } from 'mobx';
import _ from 'lodash';
import { history } from 'utils/history';
import { toast, canAccess } from 'utils/helpers';

import {
  create,
  update,
  calculate,
} from 'services/orders';
import { getAll as getAllServices } from 'services/services';
import { getAll as getAllServicePackages } from 'services/servicePackages';
import { getAll as getAllCompanies } from 'services/companies';
import { getOne as getOneOrder } from 'services/orders';

const DEFAULT_RECORD = {
  months: 12,
  status: 'draft',
}

class Store {
  record = { ...DEFAULT_RECORD };
  data = {};
  isReadonly = true;
  isAdmin = false;

  // Action
  reset = () => {
    this.record = { ...DEFAULT_RECORD };
    this.data = {};
    this.isReadonly = true;
    this.isAdmin = false;
  }

  // Action
  setRecord = (record = {}) => {
    this.record = record;
  }

  // Action
  setData = (data = {}) => {
    this.data = data;
  }

  // Action
  setIsReadonly = isReadonly => {
    this.isReadonly = isReadonly;
  }

  // Action
  setIsAdmin = () => {
    const isAdmin = canAccess('administrator.layout');
    this.isAdmin = isAdmin;
  }

  // Action
  setRecordValue = (field, value) => {
    // this.record[field] = value;

    _.set(this.record, field, value);

    switch(field) {
      case 'totals.discountPercent':
      case 'totals.discountAmount':
        _.set(this.record, 'totals.correctedTotal', null);
        this.doRecalc();
        break;

      case 'totals.correctedTotal':
        this.doRecalc();
        break;

      case 'months':
        this.setRecordValue('totals.correctedTotal', null);
        this.doRecalc();
        break;
      
      default:
        break;
    }
  }

  doRecalc = _.debounce(() => this.recalculate(), 500)

  addItem = async (item) => {
    let items = this.record.items || [];
    items.push(item);

    this.setRecordValue('items', items);
    this.setRecordValue('totals.correctedTotal', null);
    await this.recalculate();
  }

  updateItem = async (idx, data) => {
    let items = this.record.items;
    items[idx] = { ...data };

    this.setRecordValue('items', items);
    this.setRecordValue('totals.correctedTotal', null);
    await this.recalculate();
  }

  removeItem = async (idx) => {
    let items = this.record.items;

    if (!items || !items.length) return;

    items.splice(idx, 1);
    this.setRecordValue('totals.correctedTotal', null);
    await this.recalculate();
  }

  getServiceTitle = _id => {
    const service = this.data.services ? _.find(this.data.services, { _id }) : null;

    return service ? service.title : _id;
  }

  getServicePackageTitle = _id => {
    const servicePackage = this.data.servicePackages ? _.find(this.data.servicePackages, { _id }) : null;

    return servicePackage 
      ? servicePackage.title 
      : 'Individualus';
  }

  recalculate = async () => {
    const record = { ...this.record };

    const recalculated = await calculate(record);
    this.setRecord(recalculated);
  }

  create = async (submit = false) => {
    const record = toJS(this.record);

    try {
      const order = await create(record, submit ? { submit } : null);
      toast(`Užsakymas ${order.number} pateiktas`, 'success');
      history.push('/orders');
    } catch (err) {
      toast('Nepavyko pateikti užsakymo', 'danger');
    }
  }

  update = async (submit = false) => {
    const record = toJS(this.record);

    try {
      const order = await update(record._id, record, submit ? { submit } : null);
      toast(`Užsakymas ${order.number} atnaujintas`, 'success');
      history.push('/orders');
    } catch (err) {
      toast('Nepavyko atnaujinti užsakymo', 'danger');
    }
  }

  loadData = async (orderId = null) => {
    this.setIsAdmin();

    let data = {};
    data.services = await getAllServices();
    data.servicePackages = await getAllServicePackages();
    
    if (this.isAdmin) {
      data.companies = await getAllCompanies();
    }

    this.setData(data);

    if (orderId) {
      await this.loadRecord(orderId);
    }

    this.configAccess();
  }

  loadRecord = async (orderId) => {
    const record = await getOneOrder(orderId);

    this.setRecord(record);
  }

  configAccess = () => {
    let isReadonly = true;
    if (this.record.status === 'draft' || this.record.status === 'pending') {
      isReadonly = false;
    }
    this.setIsReadonly(isReadonly);
  }
}

decorate(Store, {
  record: observable,
  data: observable,
  isReadonly: observable,
  isAdmin: observable,
  setRecord: action,
  setData : action,
  setRecordValue: action,
  setIsReadonly: action,
  setIsAdmin: action,
  reset: action,
});

export default new Store;