import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  Typography,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { FormField2 } from 'components';
import styles from './styles';

class NormsDependencyTable extends React.Component {

  onChange({ name, value }) {
    const { dependencies = [], onChange } = this.props;

    let selectedDependencies = [ ...dependencies ];

    if (value) {
      // Checked
      selectedDependencies.push(name);
    } else {
      // Unchecked
      const idx = _.indexOf(selectedDependencies, name);
      selectedDependencies.splice(idx, 1);
    }

    onChange(selectedDependencies);
  }

  render() {
    const { classes, dependencies } = this.props;
    const { options } = this.props.store;

    return (
      <div className={classes.root}>
        <Typography variant="body2" style={{ marginBottom: 5, fontWeight: 'bold' }}>
          Į funkcionalumą įeina pasirinkti normatyvų paketai:
        </Typography>

        <TableContainer style={{ maxHeight: '100px' }}>
          <Table size="small" className={classes.table}>
            <TableBody>
              {_.map(options.normPackages, normPackage => (
                <TableRow key={`rules-dep-${normPackage.label}`}>
                  <TableCell>
                    {normPackage.label}
                  </TableCell>
                  <TableCell>
                    <FormField2
                      type="checkbox"
                      name={normPackage.value}
                      value={_.includes(dependencies, normPackage.value)}
                      onChange={this.onChange.bind(this)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(NormsDependencyTable));
