import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  withStyles,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { CommonPanel } from 'components';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
    // padding: '0px',
  },
  item: {
    height: '100%'
  }
});

class Dashboard extends Component {

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title={<FormattedMessage id="dashboard" defaultMessage="Apžvalga" />}>
        <div className={classes.root} style={{ maxWidth: '1200px' }}>
          <CommonPanel>
            <Typography variant="body2">
              Sveiki,
              <br />
              <br />
              Sveikiname sėkmingai prisijungus prie <a target="_blank" href="https://bimgates.lt">bimgates.lt</a> statybos normatyvų ir kainynų elektroninės žinių bazės ir paslaugų sistemos (BETA versija).
            </Typography>

            <Typography variant="body2" style={{ marginTop: 20 }}>
              <strong>Dėmesio!</strong>
              <br />
              <br />
              Visos e. duomenų bazės teisės priklauso UAB Sistela ir negali būti kopijuojama ar perdaroma į bet kokią kitą formą ir naudojama komerciniais ar kitais tikslais be autorių sutikimo.
              <br />
              <br />
              Jei nenorite, kad Jūsų registracija būtų išsaugota sistemoje, prašome parašyti prašymą į <a href="mailto:info@bimgates.lt">info@bimgates.lt</a>
              <br />
              <br />
              Pagarbiai,
              <br />
              bimgates.lt administratorius
            </Typography>
          </CommonPanel>

          <div style={{ marginTop: 20 }}>
            <CommonPanel>
              <Typography variant="body2">
                Darbui su sistema pasirinkite Jūsų sukurtą paskyrą arba sukurkite naują
              </Typography>

              <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => window.document.getElementById('li-accounts').click()}
                  >
                    Mano paskyros
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => window.document.getElementById('li-accounts-create').click()}
                  >
                    Sukurti paskyrą
                  </Button>
                </Grid>
              </Grid>
            </CommonPanel>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Dashboard);
