export default theme => ({
  root: {
    position: 'relative',
    border: '1px solid #DFE3E8',
  },
  link: {
    color: 'inherit',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  buttonWrapper: {
    position: 'absolute',
    margin: 0,
    top: 7,
    right: 220,
  },
  createButtonIcon: {
    marginRight: theme.spacing(1),
  },
});
