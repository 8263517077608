import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { CommonDialog, FormField2 } from 'components';
import PricingTable from '../PricingTable';
import RulesDependencyTable from './components/RulesDependencyTable';
import NormsDependencyTable from './components/NormsDependencyTable';
import { twoDecimals } from 'utils/helpers';
import styles from './styles';

class ServiceRules extends React.Component {
  state = {
    showModal: false,
    record: null,
    recordIdx: null,
  }

  calcPrices(priceMonth = 0) {
    const { settings } = this.props.store;
    const prices = [];

    if (settings.services) {
      const monthly = _.toNumber(priceMonth);

      _.forEach(settings.services.pricingRange, ({ months, discountPercent = 0 }) => {
        const discountRatio = 1 - (_.toNumber(discountPercent) / 100);
        const price = monthly * _.toNumber(months) * discountRatio;

        prices.push({ months, price });
      });
    }

    return prices;
  }

  onModalShow(recordIdx = null, record = null) {
    this.setState({ showModal: true, record, recordIdx });
  }

  onModalClose() {
    this.setState({ showModal: false, record: null, recordIdx: null });
  }

  onChange({ name, value }) {
    const record = this.state.record || {};
    record[name] = value;
    
    if (name === 'priceMonth') {
      record.prices = this.calcPrices(value);
    }

    this.setState({
      record,
    });
  }

  onSave() {
    const { recordIdx, record } = this.state;

    if (!record || !record.rule || !record.title) {
      window.alert('Kodas ir pavadinimas - privalomi');
      return;
    }

    let rule = {};

    if (_.isNumber(recordIdx)) {
      // Editing
      rule = this.props.store.record.rules[recordIdx];
    }

    rule.title = record.title;
    rule.rule = record.rule;
    rule.visible = record.visible === true;
    rule.priceMonth = record.priceMonth;
    rule.prices = this.calcPrices(record.priceMonth);
    rule.dependencies = record.dependencies || [];
    rule.normPackages = record.normPackages || [];

    if (!_.isNumber(recordIdx)) {
      this.props.store.record.rules.push(rule);
    }

    this.onModalClose();
    return rule;
  }

  onDelete(recordIdx) {
    if (!window.confirm('Ar tikrai norite pašalinti?')) {
      return;
    }

    this.props.store.record.rules.splice(recordIdx, 1);
  }

  renderFormField({ name, ...field }) {
    const { record } = this.state;
    
    return (
      <FormField2
        {...field}
        name={name}
        key={name}
        value={record ? record[name] : (field.multiple ? [] : '')}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderModalForm() {
    const { record } = this.state;
    const dependencies = record ? record.dependencies : [];
    const normPackages = record ? record.normPackages : [];
    const currentRule = record ? record.rule : null;

    return (
      <div style={{ padding: '0px 10px 10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {this.renderFormField({ name: 'title', label: 'Pavadinimas', type: 'text' })}
          
            {this.renderFormField({ name: 'rule', label: 'Kodas (sisteminis)', type: 'text' })}
          
            {this.renderFormField({ name: 'priceMonth', label: 'Kaina mėnesiui, EUR', type: 'number' })}

            {this.renderFormField({ name: 'visible', label: 'Rodomas užsakyme', type: 'checkbox' })}
          </Grid>

          <Grid item xs={12}>
            <PricingTable item={{...this.state.record}} />
          </Grid>

          <Grid item xs={12}>
            <RulesDependencyTable
              currentRule={currentRule}
              dependencies={dependencies}
              onChange={dependencies => this.onChange({ name: 'dependencies', value: dependencies })}
            />
          </Grid>

          <Grid item xs={12}>
            <NormsDependencyTable
              dependencies={normPackages}
              onChange={normPackages => this.onChange({ name: 'normPackages', value: normPackages })}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  renderModal() {
    const { showModal } = this.state;

    const actions = [
      (
        <Button key="cancel" onClick={this.onSave.bind(this)} color="primary" variant="contained">
          Išsaugoti
        </Button>
      ),
      (
        <Button key="save" onClick={this.onModalClose.bind(this)} color="primary">
          Atšaukti
        </Button>
      )
    ];

    return (
      <CommonDialog
        visible={showModal}
        title="Paslaugos funkcionalumas"
        onClose={() => this.setState({ showModal: false })}
        actions={actions}
        maxWidth="xs"
      >
        {this.renderModalForm()}
      </CommonDialog>
    )
  }

  renderRows() {
    const { record } = this.props.store;
    const { classes } = this.props;

    if (!record) {
      return null;
    }

    return _.map(record.rules, (rule, idx) => {

      return (
        <TableRow key={idx} title={!rule.visible ? 'Nerodomas užsakyme' : null}>
          <TableCell
            className={!rule.visible ? classes.disabledRule : ''}
          >
            {rule.title}
          </TableCell>
          <TableCell>{rule.rule}</TableCell>
          <TableCell align="center">
            {rule.dependencies && rule.dependencies.length ? '+' : '-'}
            {rule.normPackages && rule.normPackages.length ? <span title="Normatyvų paketai"> / N</span> : null}
          </TableCell>
          <TableCell className={classes.textRight}>{twoDecimals(rule.priceMonth)}</TableCell>
          <TableCell className="actions">
            <Button
              color="primary"
              onClick={() => this.onModalShow(idx, { ...rule })}
            >
              Redaguoti
            </Button>

            <Button
              color="primary"
              onClick={() => this.onDelete(idx)}
            >
              Pašalinti
            </Button>
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.root}>
          <Typography variant="h5">
            Funkcionalumas
          </Typography>

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '30%' }}>Pavadinimas</TableCell>
                  <TableCell>Kodas</TableCell>
                  <TableCell>Priklauso nuo kito</TableCell>
                  <TableCell>Kaina mėnesiui, EUR</TableCell>
                  <TableCell className="actions" style={{ width: '25%' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderRows()}
              </TableBody>
            </Table>
          </TableContainer>

          <Button 
            variant="contained"
            color="primary"
            size="small"
            onClick={this.onModalShow.bind(this)}
            style={{ marginTop: '10px' }}
          >
            Pridėti funkcionalumą
          </Button>
        </div>

        {this.renderModal()}
      </>
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(ServiceRules));
