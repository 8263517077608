import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import _ from 'lodash';

import { saveState, loadState } from 'utils/helpers';

import { app } from 'views/App/_reducers';
import { users } from 'views/Users/_reducers';
import { documents } from 'views/Documents/_reducers';
import { accounts } from 'views/Accounts/_reducers';
import { permissions } from 'views/Permissions/_reducers';

import { recoveryPriceForm } from 'views/RecoveryPrice/components/RecoveryPriceForm/_reducers';
import { recoveryPriceList } from 'views/RecoveryPrice/components/RecoveryPriceList/_reducers';

// Define the Reducers that will always be present in the application
const staticReducers = {
  app,
  users,
  documents,
  accounts,
  permissions,
  recoveryPriceForm,
  recoveryPriceList,
}

// Attempt to load state from localStorage
const persistedState = loadState();

// Configure the store
const configureStore = (initialState) => {
  const storeObj = createStore(
    createReducer(),
    initialState,
    applyMiddleware(thunkMiddleware),
  );

  // Add a dictionary to keep track of the registered async reducers
  storeObj.asyncReducers = {}

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  storeObj.injectReducer = (key, asyncReducer) => {
    storeObj.asyncReducers[key] = asyncReducer
    storeObj.replaceReducer(createReducer(storeObj.asyncReducers))
  }

  // Return the modified store
  return storeObj;
}

const createReducer = (asyncReducers) => {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

// Store can handle reducer injection
export const store = configureStore(persistedState);

// Persist state regularly
store.subscribe(_.throttle(() => {
  const { app } = store.getState();
  saveState({
    // Persist only `app` reducer
    app,
  });
}, 2000));