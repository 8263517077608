import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  Button,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { CommonPanel } from 'components';
import { timestampToDate } from 'utils/helpers';
import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    this.props.store.loadData(this.props.accountId);
  }

  componentWillUnmount() {
    this.props.store.reset();
  }
  
  render() {
    const { classes } = this.props;
    const { record } = this.props.store;

    if (!record.position) return null;

    return (
      <CommonPanel title="Aktyvios paskyros informacija">
        <div>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Įmonė:</strong> {record.company.name} (Į. k. {record.company.code})
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Įmonės administratorius:</strong> {record.company.admin}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Pareigos:</strong> {record.position}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 5 }}>
            <strong>Paslaugų licencijos:</strong> {!record.licences.length ? <span style={{ color: 'red' }}>Nėra priskirtų licencijų. Dėl licencijų susisiekite su įmonės administratoriumi</span> : null}
          </Typography>
        </div>

        {record.licences.length ? (
          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Paslauga</TableCell>
                  <TableCell>Funkcionalumas</TableCell>
                  <TableCell>Galioja iki</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(record.licences, (licence, idx) => (
                  <TableRow key={`${licence.service}-${idx}`}>
                    <TableCell>{licence.service}</TableCell>
                    <TableCell>
                      <ul style={{ listStyle: 'none'}}>
                        {_.map(licence.ruleTitles, title => <li key={title}>{title}</li>)}
                      </ul>
                    </TableCell>
                    <TableCell>{timestampToDate(licence.expiresAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </CommonPanel>
    )
  }
}

export default compose(withStyles(styles))(observer(Container));