import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { getCurrentAccount } from 'utils/helpers';
import { history } from 'utils/history';
import { DataTable, DataTableEditAction as EditAction } from 'components';

import Projects from 'views/Projects';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { projectsList } from './_reducers';
store.injectReducer('projectsList', projectsList);


class ProjectsList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  prepareTableColumns() {
    
    const columns = [
      'ID',
      'LINK',
      'Kodas',
      'Priklauso kodui',
      'Projekto pavadinimas',
      'Sukūrė',
      'Sukūrimo data',
      'Atnaujino',
      'Atnaujinimo data',
      {
        // name: 'actions',
        // label: 'Veiksmai',
        options: {
          filter: false,
          sort: false,
          searchable: false,
          print: false,
          download: false,
          viewColumns: false,
        }
      }
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/projects/${record._id}/dashboard`);
      row.push(record.code || 'Nenurodytas');
      row.push(record.parent ? record.parent.code : null);
      row.push(record.title);
      row.push(record.owner ? record.owner.user.fullName : null);
      row.push(record.createdAt ? moment(record.createdAt).utc().format('YYYY-MM-DD') : null);
      row.push(record.updatedBy ? record.updatedBy.user.fullName : null);
      row.push(record.updatedAt ? moment(record.updatedAt).utc().format('YYYY-MM-DD') : null);
      row.push(<EditAction url={`/projects/${record._id}`} />);
      
      return row;
    });

    return data;
  }

  createNew() {
    const account = getCurrentAccount();
    const { records } = this.props;

    if (account.isDefault && records.length) {
      window.alert('Pasiektas DEMO aplinkos projektų kiekio limitas')
      return;
    }

    history.push('/projects/create');
  }

  render() {
    const { classes, records, isLoading } = this.props;
    if (isLoading) {
      return (
        <h1>Kraunasi...</h1>
      )
    }
    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    return (
      <Projects
        title="Projektai"
        breadcrumbs={[
          { title: 'Projektai' },
        ]}
      >
        
        <div className={classes.content}>
          <DataTable 
            // title={intl.formatMessage({ id: 'Companies.CompanyList.title', defaultMessage: 'Įmonių sąrašas'})}
            columns={columns}
            data={data}
            // createUrl="/projects/create"
            createHandler={this.createNew.bind(this)}
            // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
            // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
          />
        </div>

      </Projects>
    );
  }
}

const mapStateToProps = ({ projectsList, dispatch }) => {
  return { 
    ...projectsList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ProjectsList);