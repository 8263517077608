import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';


// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  item: {
    height: '100%'
  }
});

class Classifier extends Component {
  render() {
    const { classes, title, children, menu, menuTitle } = this.props;

    return (
      <DashboardLayout 
        specialMenu={menu}
        specialMenuTitle={menuTitle}
        title={title}
      >

        <div className={classes.root}>
          {children}
        </div>

      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Classifier);