import {
  SENDING_REQUEST,
  SEARCH_SUCCESS,
  JOIN_REQUEST_SUCCESS,
  FAILURE,
  RESET_FORM,
} from './_constants';

const initialState = {
  isLoading: false,
  company: null,
  searched: false,
};

export const companiesSearch = (state = initialState, action) => {
  switch (action.type) {
    
    case RESET_FORM:
      return {
        ...initialState,
      };

    case SENDING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case JOIN_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        company: action.company,
        searched: true,
      };

    case FAILURE:
      return {
        ...state,
        isLoading: false,
        searched: false,
      }

    default:
      return state;
  }
}