import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import {
  getTree,
  getPackages,
  getDetails,
  doCalculate,
  getExcelExport,
} from 'services/norms';
import { 
  scrollToElement,
  blinkElement,
  b64toBlob,
  blobToFileDownload,
} from 'utils/helpers';

class Store {
  // Observable
  data = []

  // Observeable
  touchedItems = []

  // Observable
  loading = false

  // Observable
  version = null

  // Observable
  lastExpandedCodePath = null

  doRecalc = _.debounce((item) => this.recalculate(item), 3000)

  // Action
  reset = () => {
    this.data = [];
    this.touchedItems = [];
    this.loading = false;
    this.version = null;
    this.lastExpandedCodePath = null;
  }

  // Action
  setData = data => {
    this.data = data;
  }

  // Action
  setTouchedItems(data) {
    this.touchedItems = data;
  }

  // Action
  addTouchedItem(item) {
    // Check if not already in
    if (_.find(this.touchedItems, ({ code }) => code === item.code)) return; 

    this.touchedItems.push(item);
  }

  // Action
  setLoading = loading => {
    this.loading = loading;
  }

  // Action
  setLastExpandedCodePath = lastExpandedCodePath => {
    this.lastExpandedCodePath = lastExpandedCodePath;
  }

  // Action
  setVersion = version => {
    this.version = version;

    if (version) {
      this.loadData();
    }
  }

  loadData = async () => {
    this.setLoading(true);

    // const version = this.version.code;
    // const data = await getPackages({ version });
    const data = await getPackages();

    this.setData(data);

    if (this.lastExpandedCodePath) {
      try {
        this.expandPath(this.lastExpandedCodePath.split(';'));
      } catch (err) {
        console.log('Cannot expand code path');
      }
    }

    this.setLoading(false);
  }

  // Action
  handleExpand = item => {

    item.expanded = !item.expanded;
    
    if (!item.expanded) {
      this.setLastExpandedCodePath(null);
    } else {
      this.setLastExpandedCodePath(item.codePath);
    }

    if (!item.expanded) {
      return;
    }

    this.loadNext(item);
  }

  loadNext = async (item) => {
    if (item.type && item.type === 'package') {
      return;
    }

    this.setLoading(true);

    const data = await getTree({ code: item.code, version: this.version.code });
    const hasChildren = data.length > 0;
    item.hasChildren = hasChildren;

    if (hasChildren) {
      const children = _.map(data, row => ({ ...row, parentTitle: item.title }));
      item.children = children;
    } else {
      this.loadDetails(item);
    }

    this.setLoading(false);
  }

  // Action
  handleParameterExpand = item => {
    item.expanded = !item.expanded;
  }

  // Action
  updateItemWithDetails = (item, details) => {
    item.details = details;
  }

  loadDetails = async (item, forceLoad = false) => {
    if (item.details && !forceLoad) return;

    this.setLoading(true);

    let details = await getDetails({ code: item.code, version: this.version.code });
    let title = `${item.title} (${item.unit})`;
    
    if (item.code.indexOf('#') > 0) {
      title = `${item.parentTitle} ${title}`;
    }

    title = `${item.code} ${title}`;

    details['title'] = title;
    details['details'] = item.description;

    this.updateItemWithDetails(item, details);

    this.setLastExpandedCodePath(details.codePath);

    await this.recalculate(item);

    this.setLoading(false);

    this.addTouchedItem(item);
  }

  expandPath = async (codes) => {
    this.setLoading(true);

    // Build collection - package dictionary for faster search
    let collectionDict = {};
    _.forEach(this.data, item => {
      if (item.type === 'package') {
        _.forEach(item.children, child => {
          if (!collectionDict[child.code]) {
            collectionDict[child.code] = item;
          }
        });
      }
    });
    
    let rootItem = null;
    let loop = true;
    let codesToLoop = codes;

    // Sequentionally loop through codes and expand branches
    while (loop) {
      const code = _.first(codesToLoop);
      if (!rootItem) {
        rootItem = collectionDict[code];
      }

      const child = _.find(rootItem.children, { code });
      rootItem.expanded = true;
      if (child) {
        await this.loadNext(child);
        rootItem = child;
        rootItem.expanded = true;
      }

      if (codesToLoop.length > 1) {
        codesToLoop = _.slice(codesToLoop, 1);
      } else {
        loop = false;
        
        const el = document.querySelector(`.code-${code.replace('#', '')}`);
        if (el) {
          scrollToElement(el);
          blinkElement(el);
        }
      }
    }

    this.setLoading(false);
  }

  // Reset item
  resetItem = async (item) => {
    item.quantityCalc = 1;
    await this.loadDetails(item, true);
  }

  // Calculations
  recalculate = async (item) => {
    if (!item.details) {
      return await this.loadDetails(item);
    }

    this.setLoading(true);

    const {
      quantityCalc,
      priceCalc,
      amountCalc,
      totals,
      details,
    } = await doCalculate(item);
    
    item.quantityCalc = quantityCalc;
    item.priceCalc = priceCalc;
    item.amountCalc = amountCalc;
    item.totals = totals;
    
    this.updateItemWithDetails(item, details);

    this.setLoading(false);
  }

  // Export to Excel
  exportToExcel = async () => {
    this.setLoading(true);

    const items = this.touchedItems.filter(i => i.isExporting);

    try {
      const base64Response = await getExcelExport({ items, version: this.version });
      
      this.downloadFile('normatyvu-skaiciavimai.xlsx', base64Response);
    } catch (err) {
      console.log(err);
    }

    this.setLoading(false);
  }

  downloadFile = (name, base64Data) => {
    const blob = b64toBlob(base64Data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    blobToFileDownload(name, blob);
  }
}

decorate(Store, {
  data: observable,
  touchedItems: observable,
  loading: observable,
  version: observable,
  lastExpandedCodePath: observable,
  reset: action,
  setData: action,
  setTouchedItems: action,
  addTouchedItem: action,
  setLoading: action,
  setVersion: action,
  setLastExpandedCodePath: action,
  handleExpand: action,
  handleParameterExpand: action,
  updateItemWithDetails: action,
});

export default new Store;