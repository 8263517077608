import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core';

import { DataTable } from 'components';
import { canAccess } from 'utils/helpers';

import styles from './styles';

class UserAccounts extends Component {

  prepareTableColumns() {
    const columns = [
      'ID',
      'LINK',
      'Pareigos',
      'Įmonė',
      'Būsena',
      'Sukurta',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/accounts/${record._id}`);
      row.push(record.position);
      row.push(record.company ? record.company.name : null);
      row.push(record.status);
      row.push(moment.parseZone(record.createdAt).format('YYYY-MM-DD'));

      return row;
    });

    return data;
  }

  render() {
    const { classes, isLoading, records, current } = this.props;
    
    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }


    return (
      <DataTable 
        title="Vartotojo paskyros"
        columns={columns} 
        data={data}
        {...(canAccess('accounts.create') && {createUrl: `/accounts/create?user=${current._id}`})}
      />
    );
  }
}

export default withStyles(styles)(UserAccounts);