import React from 'react';
import { observer } from 'mobx-react';
import { FormField2 } from 'components';

class Container extends React.Component {

  componentDidMount() {
    this.props.store.loadOptions();
  }
  
  componentWillUnmount() {
    this.props.store.setOptions([]);
  }

  render() {
    const { value, name, onChange, label, style, placeholder, defaultFirst } = this.props;
    const { options } = this.props.store;

    let defaultValue;
    if (defaultFirst && !value && options.length) {
      defaultValue = options[0].value;
      onChange({ name, value: defaultValue });
    }

    return (
      <FormField2
        type="select"
        options={options}
        label={label}
        placeholder={placeholder}
        name={name}
        key={name}
        value={value || defaultValue}
        onChange={onChange}
        style={style}
      />
    )
  }
}

export default observer(Container);