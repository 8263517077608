import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import {
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import { CommonDialog, FormField2 } from 'components';
import RulesList from './components/RulesList';
import PricingTable from '../PricingTable';
import styles from './styles';
import { twoDecimals } from 'utils/helpers';

class ServicePackages extends React.Component {
  state = {
    showModal: false,
    record: null,
    recordIdx: null,
  }

  calcPrices(rules) {
    const { record, settings } = this.props.store;
    let prices = [];

    if (settings.services) {
      // Fill empty values for whole range
      _.forEach(settings.services.pricingRange, ({ months }) => {
        prices.push({ months, price: 0 });
      });

      _.forEach(rules, rule => {
        const ruleRecord = _.find(record.rules, { rule });
        if (!ruleRecord) {
          console.log(`Rule [${rule}] not found`);
          return [];
        }

        const monthly = _.toNumber(ruleRecord.priceMonth);

        _.forEach(settings.services.pricingRange, ({ months, discountPercent = 0 }) => {
          const discountRatio = 1 - (_.toNumber(discountPercent) / 100);
          const price = monthly * _.toNumber(months) * discountRatio;

          let found = _.find(prices, { months });
          found.price = found.price + price;
        });

      })
    }
    return prices;
  }

  onModalShow(recordIdx = null, record = {}) {

    record.discountPercent = record.discountPercent || 0;
    record.priceMonth = record.priceMonth || 0;
    record.priceYear = record.priceYear || 0;
    
    this.setState({ showModal: true, record, recordIdx });
  }

  onModalClose() {
    this.setState({ showModal: false, record: {}, recordIdx: null });
  }

  onChange({ name, value }) {
    // TODO improve logic...
    const { record } = this.state;
    record[name] = value;

    if (name === 'rules') {
      record.prices = this.calcPrices(value);
    }

    this.setState({
      record,
    });
  }

  onSave() {
    const { recordIdx, record } = this.state;

    let rule = {};

    if (_.isNumber(recordIdx)) {
      // Editing
      rule = this.props.store.record.packages[recordIdx];
    }

    rule.servicePackage = record.servicePackage;
    rule.rules = record.rules;
    rule.prices = this.calcPrices(record.rules);

    if (!_.isNumber(recordIdx)) {
      this.props.store.record.packages.push(rule);
    }

    this.onModalClose();
    return rule;
  }

  onDelete(recordIdx) {
    if (!window.confirm('Ar tikrai norite pašalinti?')) {
      return;
    }

    this.props.store.record.packages.splice(recordIdx, 1);
  }

  getServicePackageTitle(id) {
    const pkg = _.find(this.props.store.options.servicePackages, { value: id });

    return pkg ? pkg.label : '-';
  }

  renderFormField({ name, ...field }) {
    const { record } = this.state;
    const value = _.keys(record).length ? record[name] : (field.multiple ? [] : '');

    return (
      <FormField2
        {...field}
        name={name}
        key={name}
        value={value}
        onChange={field.onChange || this.onChange.bind(this)}
      />
    );
  }

  renderRulesList() {
    const { record } = this.state;
    let rules = _.keys(record).length ? record.rules : [];

    return (
      <div style={{ marginTop: '20px' }}>
        <RulesList
          rules={rules}
          onChange={selectedRules => this.onChange({ name: 'rules', value: selectedRules })}
        />
      </div>
    )
  }

  renderModalForm() {

    return (
      <div style={{ padding: '0px 10px 10px' }}>
        <Grid container spacing={1}>
          <Grid item xs>
            {this.renderFormField({ name: 'servicePackage', label: 'Paketas', type: 'select', options: this.props.store.options.servicePackages })}
          
            {this.state.record && this.state.record.servicePackage ? (
              <>
                {this.renderRulesList()}

                <div style={{ marginTop: 10 }}>
                  <PricingTable item={{...this.state.record}} />
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    )
  }

  renderModal() {
    const { showModal } = this.state;

    const actions = [
      (
        <Button key="cancel" onClick={this.onSave.bind(this)} color="primary" variant="contained">
          Išsaugoti
        </Button>
      ),
      (
        <Button key="save" onClick={this.onModalClose.bind(this)} color="primary">
          Atšaukti
        </Button>
      )
    ];

    return (
      <CommonDialog
        visible={showModal}
        title="Paslaugos paketas"
        onClose={() => this.setState({ showModal: false })}
        actions={actions}
        maxWidth="xs"
      >
        {this.renderModalForm()}
      </CommonDialog>
    )
  }

  renderRows() {
    const { record, settings } = this.props.store;

    if (!_.keys(record).length) {
      return null;
    }

    return _.map(record.packages, (pkg, idx) => {

      return (
        <TableRow key={idx}>
          <TableCell>{this.getServicePackageTitle(pkg.servicePackage)}</TableCell>
          
          {settings.services ? _.map(settings.services.pricingRange, ({ months }) => {
            const price = _.find(pkg.prices, { months });
            const value = price ? twoDecimals(price.price) : '-';

            return (
              <TableCell key={`months-cell-${months}`}>
                {value}
              </TableCell>
            )
          }) : null}
          
          <TableCell className="actions">
            <Button
              color="primary"
              onClick={() => this.onModalShow(idx, { ...pkg })}
            >
              Redaguoti
            </Button>

            <Button
              color="primary"
              onClick={() => this.onDelete(idx)}
            >
              Pašalinti
            </Button>
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {
    const { classes, store } = this.props;
    const { settings } = store;

    return (
      <>
        <div className={classes.root}>
          <Typography variant="h5">
            Paslaugų paketai
          </Typography>

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '30%' }}>Paketas</TableCell>
                  {settings.services ? _.map(settings.services.pricingRange, ({ months }) => (
                    <TableCell key={`months-header-${months}`}>
                      {months} mėn.
                    </TableCell>
                  )) : null}
                  <TableCell className="actions" style={{ width: '25%' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderRows()}
              </TableBody>
            </Table>
          </TableContainer>

          <Button 
            variant="contained"
            color="primary"
            size="small"
            onClick={this.onModalShow.bind(this)}
            style={{ marginTop: '10px' }}
          >
            Pridėti paketą
          </Button>
        </div>

        {this.renderModal()}
      </>
    )
  }
}

export default compose(withStyles(styles), inject('store'))(observer(ServicePackages));
