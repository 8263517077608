import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ClickAwayListener,
  Box,
  Typography,
  withWidth,
  isWidthUp,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { FormField2 } from 'components';
import { twoOrMoreDecimals, highlightText } from 'utils/helpers';

import styles from './styles';

class NormSearchContainer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      search: null,
      showResults: false,
      selectedCode: null,
    }
    
    this.handleSearch = _.debounce(this.handleSearch, 1000);
  }

  componentWillUnmount() {
    this.props.store.setResults([]);
  }

  handleSearch = (value, version) => {
    if (value.length < 1) {
      // Do not search if less than one char
      this.props.store.setResults([]);
      this.setState({
        showResults: false,
      });
      return;
    }

    this.props.store.search(value, version);
    this.setState({
      showResults: true,
      selectedCode: null,
    });
  };

  onSearchChange({ value }) {
    this.setState({
      search: value,
    });

    this.handleSearch(value);
  }

  onSearchFocus() {
    if (this.props.store.results.length) {
      this.setState({ showResults: true });
    }
  }

  handleSelect(item) {
    const { onSelect } =  this.props;

    if (onSelect) {
      onSelect(item);
    }

    this.setState({
      showResults: false,
      selectedCode: item.code,
      search: null,
    });
  }
  
  onClickAway() {
    this.setState({ showResults: false });
  }

  renderResults() {
    const { classes, store, width } = this.props;

    if (!this.state.showResults || (this.state.showResults && !this.state.search)) {
      return null;
    }

    return (
      <Box boxShadow={3} className={classes.results} style={{ width: isWidthUp('sm', width) ? 'auto' : '100%' }}>
        <div className={classes.resultsWrap}>
          <div className={classes.resultHeadersWrap}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '10%' }}>Rinkinys</TableCell>
                  <TableCell style={{ width: '15%' }}>Kodas</TableCell>
                  <TableCell style={{ width: '15%' }}>Mato vnt.</TableCell>
                  <TableCell style={{ width: '15%' }}>Darbo sąnaudos</TableCell>
                  <TableCell style={{ width: '45%' }}>Komplekso/darbo pavadinimas</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>

          <div className={classes.resultListWrap}>
            <Table size="small">
              <TableBody>
                {store.results.length ? _.map(store.results, item => (
                  <TableRow
                    className={classes.pointer}
                    key={item.rootCode + item.code}
                    onClick={() => this.handleSelect({ ...item })}
                    selected={item.code === this.state.selectedCode}
                    hover
                  >
                    <TableCell style={{ width: '10%' }}>{item.rootCode}</TableCell>
                    <TableCell style={{ width: '15%' }}>{highlightText(item.code, this.state.search)}</TableCell>
                    <TableCell style={{ width: '15%' }}>{item.unit}</TableCell>
                    <TableCell style={{ width: '15%' }}>{twoOrMoreDecimals(item.laborCosts)}</TableCell>
                    <TableCell style={{ width: '45%' }}>{highlightText(item.title, this.state.search)}</TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="h5" className={classes.noResults}>
                        {store.isSearching ? 'Ieškoma...' : 'Rezultatų nerasta'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </Box>
    )
  }

  render() {
    const { classes, className, style, inputStyle } = this.props;

    return (
      <ClickAwayListener onClickAway={this.onClickAway.bind(this)}>
        <div
          className={[classes.root, className].join(' ')}
          styles={style}
        >
          <FormField2
            className={classes.input}
            type="text"
            name="search"
            placeholder="Paieška pagal tekstą..."
            onChange={this.onSearchChange.bind(this)}
            onFocus={this.onSearchFocus.bind(this)}
            value={this.state.search}
            style={inputStyle}
          />
          <span className={classes.icon}/>

          {this.renderResults()}
        </div>
      </ClickAwayListener>
    )
  }
}

export default compose(withStyles(styles), withWidth())(observer(NormSearchContainer));