/* eslint-disable no-undef */
import _ from 'lodash';

// Environment key prefix
const envKeyPrefix = 'REACT_APP_';

// API URL
export const API_URL = _.get(process.env, `${envKeyPrefix}API_URL`);
// Locale errors flag
export const SHOW_LOCALE_ERRORS = _.get(process.env, `${envKeyPrefix}SHOW_LOCALE_ERRORS`) != 'false';
