import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const Loader = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.progress} />
    </div>
  );
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);