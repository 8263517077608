import React from 'react';
import { withStyles } from '@material-ui/core';
import { Form } from 'react-final-form'
import _ from 'lodash';

import { FormField } from 'components';

import styles from './styles';
import getFields from './fields';

const AccountForm = props => {
  const {
    classes,
    account,
    onSubmit,
  } = props;

  const fields = getFields();

  const validate = (values) => {
    let errors = {};
    _.forEach(_.filter(fields, { required: true }), ({ name }) => {
      if (!values[name]) {
        errors[name] = 'Required';
        console.log(`Validate: field "${name}" is required`);
      }
    });

    return errors;
  }

  return (
    <Form
      initialValues={account ? { ...account } : null}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form
          id="accountForm"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          
          {_.map(fields, field => <FormField key={field.name} {...field}/>)}
        </form>
      )}
    />
  );
};

export default withStyles(styles)(AccountForm);
