import React, { Component } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

// Router support
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  IconButton,
  // Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
  FolderOutlined as FolderIcon,
  LabelOutlined as LabelIcon,
  Label as LabelDarkIcon,
  WorkOutline as WorkIcon,
} from '@material-ui/icons';

import { canAccess, getCurrentAccount } from 'utils/helpers';

// Component styles
import styles from './styles';

class Sidebar extends Component {

  handleListItemActive(url) {
    const { classes, location } = this.props;
    return location.pathname === url ? classes.activeListItem : classes.listItem;
  }

  renderListItem({ layoutName, to, label, labelId, icon, isPublic = false }) {
    const { classes } = this.props;
  
    // Hide layouts that are not accessible
    if (!isPublic && !canAccess(layoutName)) {
      return null;
    }

    return (
      <ListItem
        className={this.handleListItemActive(to)}
        component={Link}
        to={to}
        key={`${layoutName}-${labelId}`}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {icon}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={<FormattedMessage id={labelId} defaultMessage={label} />}
        />
      </ListItem>
    )
  }

  renderCommonMenu() {
    const items = [
      // {
      //   layoutName: 'dashboard.layout', 
      //   isPublic: true,
      //   to: '/dashboard',
      //   label: 'Apžvalga',
      //   labelId: 'Dashboard',
      //   icon: <FolderIcon />,
      // },
      {
        layoutName: 'projects.layout', 
        to: '/projects',
        label: 'Projektai',
        labelId: 'Projects',
        icon: <FolderIcon />,
      },
      {
        layoutName: 'documents.layout', 
        to: '/documents',
        label: 'Dokumentai',
        labelId: 'Documents',
        icon: <FolderIcon />,
      },
    ];

    // const { classes } = this.props;
    const listItems = _.compact(_.map(items, item => this.renderListItem(item)));

    if (!listItems.length) {
      return null;
    }

    return (
      <div>
        {/* <Divider className={classes.listDivider} /> */}
        <List
          component="div"
          disablePadding
        >
          {listItems}
        </List>
      </div>
    );
  }

  renderSpecialMenu() {
    const { specialMenu, specialMenuTitle, classes } = this.props;
    const listItems = _.compact(_.map(specialMenu, item => this.renderListItem({ 
      ...item,
      icon: <LabelDarkIcon />
    })));

    if (!listItems.length) {
      return null;
    }

    return (
      <div>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader}>
              {specialMenuTitle}
            </ListSubheader>
          }
        >
          {listItems}
        </List>
      </div>
    );
  }

  renderServiceListMenu() {
    const items = [
      {
        layoutName: 'recovery-price.layout', 
        to: '/recovery-price',
        label: 'Atkuriamoji vertė',
        labelId: 'RecoveryPrice',
        icon: <LabelIcon />,
      },
      {
        layoutName: 'ntk.layout', 
        to: '/ntk',
        label: 'NTK paieška',
        labelId: 'NtkViewer',
        icon: <LabelIcon />,
      },
      {
        layoutName: 'norms.layout', 
        to: '/norms',
        label: 'Normatyvai',
        labelId: 'Norms',
        icon: <LabelIcon />,
      },
      {
        layoutName: 'norms.layout', 
        to: '/norms-calc',
        label: 'Normatyvai (skaičiavimai)',
        labelId: 'NormsCalc',
        icon: <LabelIcon />,
      },
    ];

    const { classes } = this.props;
    const listItems = _.compact(_.map(items, item => this.renderListItem(item)));
    
    if (!listItems.length) {
      return null;
    }

    return (
      <div>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader}>
              <FormattedMessage id="services" defaultMessage="Paslaugos" />
            </ListSubheader>
          }
        >
          {listItems}
        </List>
      </div>
    );
  }

  renderAdminMenu() {
    const { company } = getCurrentAccount();

    const currentCompanyId = company ? company._id : null;
    const items = [
      {
        layoutName: 'users.layout', 
        to: '/users',
        label: 'Vartotojai',
        labelId: 'Users',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'accounts.layout', 
        to: '/accounts',
        label: 'Paskyros',
        labelId: 'Accounts',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'companies.services', 
        to: '/companies/services',
        label: 'Paslaugų paketų valdymas',
        labelId: 'ServicesManager',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'companies.write,permissions.layout', 
        to: '/orders',
        label: 'Paslaugų užsakymai',
        labelId: 'OrdersList',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'companies.write', 
        to: `/companies/${currentCompanyId}`,
        label: 'Įmonės duomenys',
        labelId: 'CompaniesForm',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'companies.layout', 
        to: '/companies',
        label: 'Įmonės',
        labelId: 'Companies',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'permissions.layout', 
        to: '/permissions',
        label: 'Prieigos teisės',
        labelId: 'Permissions',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'templates.layout', 
        to: '/tree-templates',
        label: 'Struktūros šablonai',
        labelId: 'TreeTemplates',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'templates.layout', 
        to: '/templates',
        label: 'Elementų šablonai',
        labelId: 'Template',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'classifier.layout', 
        to: '/classifier',
        label: 'Klasifikatorius',
        labelId: 'Classifier',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'classifier-groups.layout', 
        to: '/classifier-groups',
        label: 'Klasifikatoriaus grupės',
        labelId: 'ClassifierGroups',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'norm-packages.layout', 
        to: '/norm-packages',
        label: 'Normatyvų paketai',
        labelId: 'NormPackages',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'services.layout', 
        to: '/services',
        label: 'Paslaugos',
        labelId: 'Services',
        icon: <WorkIcon />,
      },
      {
        layoutName: 'service-packages.layout', 
        to: '/service-packages',
        label: 'Paslaugų paketai',
        labelId: 'ServicePackages',
        icon: <WorkIcon />,
      },
    ];

    // if (!canAccess('administrator.layout')) {
    //   return;
    // }

    const listItems = _.compact(_.map(items, item => this.renderListItem(item)));

    if (!listItems.length) {
      return null;
    }

    const { classes } = this.props;
    return (
      <div>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader}>
              <FormattedMessage id="administration" defaultMessage="Administravimas" />
            </ListSubheader>
          }
        >
          {listItems}
        </List>
      </div>
    )
  }

  render() {
    const { classes, className, specialMenu, onToggleSidebar } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Scrollbars autoHide>
        <nav className={rootClassName}>
          <div className={classes.logoWrapper}>
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              variant="text"
            >
              <CloseIcon />
            </IconButton>

            <Link
              className={classes.logoLink}
              to="/"
            >
              <img
                alt="bimgates"
                className={classes.logoImage}
                src="/images/logos/logo.svg"
              />
            </Link>
          </div>
          
          {this.renderCommonMenu()}

          {specialMenu ? this.renderSpecialMenu() : null}
          
          {this.renderServiceListMenu()}
          
          {this.renderAdminMenu()}
        </nav>
      </Scrollbars>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { ...app };
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  specialMenu: PropTypes.array,
  specialMenuTitle: PropTypes.string,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(Sidebar);
