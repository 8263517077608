import {
  REQUEST,
  GET_ALL_SUCCESS,
  GET_ALL_FAILURE,
  CLEAR_DATA,
  DELETE_SUCCESS,
} from './_constants';

const initialState = {
  isLoading: false,
  error: null,
  records: [],
};

export const recoveryPriceList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    
    case GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        records: action.records,
      };

    case GET_ALL_FAILURE:
      return {
        ...initialState,
        isLoading: false,
        error: true,
      };

    case CLEAR_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}