import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
  Button,
} from '@material-ui/core';

import styles from './styles';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from 'components';

import Permissions from 'views/Permissions';
import PermissionForm from './components/PermissionForm';

import { getOne, resetForm, updateOne, createOne } from 'views/Permissions/_actions';

class PermissionDetails extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id) {
      dispatch(getOne(match.params.id));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  renderPermissionDetails(permission) {

    return (
      <PermissionForm onSubmit={values => this.onSubmit(values)} permission={permission} />
    );
  }

  onSubmit(values) {
    const { dispatch } = this.props;

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm() {
    document
      .getElementById('permissionForm')
      .dispatchEvent(new Event('submit', { cancelable: true }));
  }

  render() {
    const { classes, isLoading, single } = this.props;
    const { permission } = single;

    return (
      <Permissions 
        title={permission ? `Permission ${permission.name}` : 'Create permission'}
      >
        
        <div className={classes.content}>
          {isLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <Portlet className={classes.root}>
              <PortletHeader>
                <PortletLabel
                  title="Permission details"
                />
              </PortletHeader>
              <PortletContent noPadding>
                {this.renderPermissionDetails(permission)}
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={event => this.submitForm(event)}
                >
                  Save
                </Button>
              </PortletFooter>
            </Portlet>
          )}
        </div>

      </Permissions>
    )
  }
}

const mapStateToProps = ({ permissions, dispatch }) => {
  return {
    ...permissions,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(PermissionDetails);