import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import {
  DataTable,
  CommonPanel
} from 'components';
import _ from 'lodash';
import moment from 'moment';

import styles from './styles';
import { getCurrentAccount } from 'utils/helpers';
import { history } from 'utils/history';
import { getRecords, clearData, batchDelete } from './_actions';

// Inject reducers
import { store } from 'utils/store'
import { recoveryPriceList } from './_reducers';
store.injectReducer('recoveryPriceList', recoveryPriceList);

class RecoveryPriceTable extends Component {

  componentDidMount() {
    const { dispatch, projectId } = this.props;
    dispatch(getRecords(projectId ? { project: projectId } : null));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearData());
  }

  createNew() {
    const account = getCurrentAccount();
    const { records, projectId } = this.props;

    if (account.isDefault && records.length) {
      window.alert('Pasiektas DEMO aplinkos atk. vertės įrašų limitas')
      return;
    }

    const createUrl = `/recovery-price/create${projectId ? '?project=' + projectId : ''}`;
    history.push(createUrl);
  }

  prepareTableColumns(projectId) {
    const columns = [
      'ID',
      'LINK',
      'Skaičiavimo pavadinimas',
      ...(!projectId ? ['Projektas'] : []),
      'Tipas',
      'Kiekis',
      'Mat. vnt.',
      'Visa vertė (su PVM)',
      'Pastabos',
      'Sukūrė',
      'Sukūrimo data',
      'Atnaujino',
      'Atnaujinimo data',
    ];

    return columns;
  }

  prepareTableData(records, projectId) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record.id);
      row.push(`/recovery-price/${record._id}`);
      row.push(record.title || 'Nenurodytas');
      if (!projectId) {
        row.push(record.project ? record.project.title : '-');
      }
      row.push(record.type);
      row.push(record.amount);
      row.push(record.unit);
      row.push(record.totals.totalRevisedPrice ? `${record.totals.totalRevisedPrice} EUR` : '-');
      row.push(this.renderDescriptionValue(record.description));
      row.push(record.owner && record.owner.user ? record.owner.user.fullName : 'Neegzistuoja');
      row.push(record.createdAt ? moment(record.createdAt).utc().format('YYYY-MM-DD') : null);
      row.push(this.renderUpdatedByValue(record));
      row.push(record.updatedAt ? moment(record.updatedAt).utc().format('YYYY-MM-DD') : null);
      return row;
    });

    return data;
  }

  onDelete(indexes) {
    const { dispatch, records } = this.props;

    const ids = _.map(indexes, index => records[index]._id);

    if (ids.length) {
      dispatch(batchDelete(ids));
    }
  }

  renderUpdatedByValue({ updatedBy }) {
    try {
      return updatedBy ? updatedBy.user.fullName : null
    } catch(err) {
      return null;
    }
  }

  renderDescriptionValue(description) {
    if (!description) {
      return null;
    }

    return (
      <span title={description}>
        {_.truncate(description, { length: 60 })}
      </span>
    )
  }

  renderTable() {
    const { classes, isLoading, records, projectId } = this.props;

    const columns = this.prepareTableColumns(projectId);
    const data = this.prepareTableData(records, projectId); 

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <DataTable
        title={projectId ? 'Susiję atkuriamųjų kaštų skaičiavimai' : undefined}
        columns={columns}
        data={data}
        createHandler={this.createNew.bind(this)}
        onDeleteBatch={indexes => this.onDelete(indexes)}
        onDelete={index => this.onDelete([index])}
      />
    );
  }


  render() {
    return (
      <CommonPanel
        noPadding
      >
        {this.renderTable()}
      </CommonPanel>
    );
  }
}

const mapStateToProps = ({ recoveryPriceList, dispatch }) => {
  return { 
    ...recoveryPriceList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(RecoveryPriceTable);