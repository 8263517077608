import NormTreeTable from './components/NormTreeTable';

export default [
  {
    key: 'show',
    component: NormTreeTable,
    path: '/norms',
    public: false,
    canReloadOnLinkClick: false,
  },
];