import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
  Button,
} from '@material-ui/core';

import styles from './styles';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from 'components';

import Accounts from 'views/Accounts';
import AccountForm from './components/AccountForm';

import { getOne, resetForm, updateOne, createOne, setValue, deleteOne } from 'views/Accounts/_actions';
import { setAccount } from 'views/App/_actions';
import { history } from 'utils/history';

class AccountDetails extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id && match.params.action) {
      if (match.params.action === 'switch') {
        dispatch(setAccount(match.params.id));
        history.push('/');
      }
    }

    // Account edit mode
    if (match.params.id) {
      dispatch(getOne(match.params.id));
      return;
    }
    
    // Create new user account mode
    const userId = queryString
      .parse(this.props.location.search, {ignoreQueryPrefix: true })
      .user
    if (userId) {
      dispatch(setValue({ name: 'user', value: userId }));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  renderAccountDetails(account) {

    return (
      <AccountForm
        onSubmit={values => this.onSubmit(values)}
        account={account}
      />
    );
  }

  onSubmit(values) {
    const { dispatch } = this.props;

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  onDelete() {
    const { dispatch, single } = this.props;
    if (window.confirm('Ar tikrai norite pašalinti paskyrą?')) {
      dispatch(deleteOne(single.account._id));
    }
  }

  submitForm() {
    document
      .getElementById('accountForm')
      .dispatchEvent(new Event('submit', { cancelable: true }));
  }

  render() {
    const { classes, isLoading, single } = this.props;
    const { account, permissionGroups } = single;

    return (
      <Accounts 
        title={account ? 'Paskyros duomenys' : 'Sukurti paskyrą'}
      >
        
        <div className={classes.content}>
          {isLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <Portlet className={classes.root}>
              <PortletHeader>
                <PortletLabel
                  title="Paskyros duomenys"
                />
              </PortletHeader>
              <PortletContent noPadding>
                {this.renderAccountDetails(account, permissionGroups)}
              </PortletContent>
              <PortletFooter className={classes.portletFooter}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={event => this.submitForm(event)}
                >
                  Išsaugoti
                </Button>

                {account && account.canBeDeleted ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onDelete.bind(this)}
                    style={{ marginLeft: 10 }}
                  >
                    Pašalinti
                  </Button>
                ) : null}
              </PortletFooter>
            </Portlet>
          )}
        </div>

      </Accounts>
    )
  }
}

const mapStateToProps = ({ accounts, dispatch }) => {
  return {
    ...accounts,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(AccountDetails);