import api from 'utils/api';

const servicePath = '/services/recovery-price';

export const getAll = async (params) => {
  const response = await api.get(`${servicePath}`, { params });
  const records = response.data;

  return records;
}

export const getOne = async (id) => {
  const response = await api.get(`${servicePath}/${id}`);
  const record = response.data;

  return record;
}

export const createOne = async (details) => {
  const response = await api.post(`${servicePath}`, details);
  const record = response.data;

  return record;
}

export const updateOne = async (details) => {
  const response = await api.put(`${servicePath}/${details._id}`, details);
  const { record } = response.data;

  return record;
}

export const deleteOne = async (id) => {
  await api.delete(`${servicePath}/${id}`);
}

export const getTypes = async () => {
  const response = await api.post(`${servicePath}/get-types`);
  const types = response.data;
  
  return types;
}

export const getVersions = async (params) => {
  const response = await api.post(`${servicePath}/get-versions`, params);
  const versions = response.data;
  
  return versions;
}

export const getHierarchy = async (params) => {
  const response = await api.post(`${servicePath}/get-hierarchy`, params);
  const hierarchy = response.data;
  
  return hierarchy;
}

export const getFullHierarchy = async () => {
  const response = await api.post(`${servicePath}/get-full-hierarchy`);
  const hierarchy = response.data;
  
  return hierarchy;
}

export const getMaterials = async (params) => {
  const response = await api.post(`${servicePath}/get-materials`, params);
  const materials = response.data;
  
  return materials;
}

export const getCombinations = async (params) => {
  const response = await api.post(`${servicePath}/get-combinations`, params);
  const combinations = response.data;
  
  return combinations;
}

export const getCalculations = async (params) => {
  const response = await api.post(`${servicePath}/get-calculations`, params);
  const calculations = response.data;
  
  return calculations;
}

export const updateCalculations = async (params) => {
  const response = await api.post(`${servicePath}/update-calculations`, params);
  const calculations = response.data;
  
  return calculations;
}

/**
 * For NTK Viewer only.
 * 
 * @param {Object} params 
 */
export const getNtkPrices = async (params) => {
  const response = await api.post('/services/ntk/get-prices', params);
  const prices = response.data;
  
  return prices;
}

export const getFiles = async (params) => {
  const response = await api.post('/services/ntk/batch-download', params);
  const files = response.data;
  
  return files;
}