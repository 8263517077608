import api from 'utils/api';

const createOne = async (details) => {
  const response = await api.post('/accounts', details);
  const { user } = response.data;

  return user;
}

const updateOne = async (details) => {
  const response = await api.put(`/accounts/${details._id}`, details);
  const { user } = response.data;

  return user;
}

const getAll = async () => {
  const response = await api.get('/accounts');
  const accounts = response.data;

  return accounts;
}

const getOne = async (id, details) => {
  const response = await api.get(`/accounts/${id}`, details ? { params: details } : null);
  const account = response.data;

  return account;
}

const setActive = async (id) => {
  const response = await api.get(`/accounts/${id}/set`);
  const account = response.data;

  return account;
}

const deleteOne = async (id) => {
  await api.delete(`/accounts/${id}`);
}

export const accountService = {
  createOne,
  updateOne,
  getAll,
  getOne,
  setActive,
  deleteOne,
};