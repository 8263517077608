import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';

import RecoveryPrice from 'views/RecoveryPrice';
import { getAll } from './_actions';

import RecoveryPriceTable from 'components/RecoveryPriceTable';

class RecoveryPriceList extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  render() {
    const { classes } = this.props;
    return (
      <RecoveryPrice
        title="Atkuriamosios vertės skaičiavimai"
      >
        
        <div className={classes.content}>
          <RecoveryPriceTable noTitle /> 
        </div>

      </RecoveryPrice>
    );
  }
}

const mapStateToProps = ({ recoveryPriceList, dispatch }) => {
  return { 
    ...recoveryPriceList,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(RecoveryPriceList);