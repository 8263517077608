import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import moment from 'moment';

import { DataTable } from 'components';
import Services from 'views/Services';


import styles from './styles';

class Container extends React.Component {

  componentDidMount() {
    this.props.store.getAll();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  prepareTableColumns() {
    
    const columns = [
      'ID',
      'LINK',
      'Kodas',
      'Pavadinimas',
      'Sukurtas',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/services/${record._id}`);
      row.push(record.code || 'Nenurodytas');
      row.push(record.title);
      row.push(record.createdAt ? moment(record.createdAt).utc().format('YYYY-MM-DD') : null);
      
      return row;
    });

    return data;
  }

  render() {
    const { classes, store } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(store.records);

    return (
      <Services title="Paslaugos">
        <div className={classes.wrap}>
          <DataTable 
            key={data.length}
            // title={intl.formatMessage({ id: 'Companies.CompanyList.title', defaultMessage: 'Įmonių sąrašas'})}
            columns={columns}
            data={data}
            createUrl="/services/create"
            // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
            // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
          />
        </div>
      </Services>
    )
  }
}

export default compose(withStyles(styles))(observer(Container));