import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import styles from './styles';
// import { menuTitle, menu } from './_menu';

class Accounts extends Component {
  render() {
    const { classes, title, children } = this.props;

    return (
      <DashboardLayout 
        // specialMenu={menu}
        // specialMenuTitle={menuTitle}
        title={title}
      >

        <div className={classes.root}>
          {children}
        </div>

      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Accounts);
