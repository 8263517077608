import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  // Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  NotificationsOutlined as NotificationsIcon,
  // Input as InputIcon,
  AccountCircleOutlined as AccountIcon,
} from '@material-ui/icons';

// import { getNotifications } from 'services/notification';
import { history } from 'utils/history';
import { canAccess, getCurrentAccount } from 'utils/helpers';

import { NotificationList } from './components';
import { LocaleSwitcher } from 'components';

import { logout } from 'views/Users/_actions';
import { userService } from 'views/Users/_services';
import { openAccountDialog } from 'views/App/_actions';

import styles from './styles';

class Topbar extends Component {
  signal = true;
  timer = null;
  fetchingNotifications = false;

  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null,
    userMenuAnchorEl: null,
    isUserMenuOpen: false,
  };

  async getNotifications(accountId) {
    const notifications = await userService.getNotifications(accountId);

    if (this.signal) { 
      this.setState({
        notificationsCount: _.countBy(notifications, { status: 'new' }).true || 0,
        notifications,
      });
    }
  }

  async triggerNotificationsUpdate() {
    const { isAuthenticated, currentAccount } = this.props;

    if (isAuthenticated && currentAccount) {
      this.fetchingNotifications = true;
      await this.getNotifications(currentAccount._id);
    }
    this.fetchingNotifications = false;
  }

  async componentDidMount() {
    this.signal = true;
    const { isAuthenticated, currentAccount } = this.props;

    if (!this.fetchingNotifications && isAuthenticated && currentAccount) {
      this.timer = setInterval(async () => {
        await this.triggerNotificationsUpdate();
      }, 30000);
      await this.triggerNotificationsUpdate();
    } else {
      clearInterval(this.timer);
    }
  }

  componentWillUnmount() {
    this.signal = false;
    clearInterval(this.timer);
  }

  handleSignOut = () => {
    const { dispatch } = this.props;

    dispatch(logout());
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = ({ notificationId }) => {
    const { currentAccount } = this.props;
    if (notificationId) {
      try {
        userService.setNotificationOpened(currentAccount._id, notificationId);
      } catch (err) {
        //...
      }
    }

    this.setState({
      notificationsEl: null
    });
  };

  handleUserMenu = (event) => {
    this.setState({ userMenuAnchorEl: event.currentTarget, isUserMenuOpen: true });
  }

  handleUserMenuClose = () => {
    this.setState({ userMenuAnchorEl: null, isUserMenuOpen: false });
  }

  handleAccountSwitch = () => {
    const { dispatch } = this.props;
    this.handleUserMenuClose();

    dispatch(openAccountDialog());
  }

  handleCreateAccount = () => {
    // const { history } = this.props;

    // history.push('/companies/join');

    window.location.href = '/companies/join';
  }

  handleProfile() {
    history.push('/profile');
  }

  handleCompanyEdit() {
    const { company } = getCurrentAccount();

    if (!company) {
      return;
    }

    history.push(`/companies/${company._id}`);
  }

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar,
      onBackButton,
      currentAccount,
      user,
    } = this.props;
    const { notifications, notificationsCount, notificationsEl, userMenuAnchorEl, isUserMenuOpen } = this.state;
    const rootClassName = classNames(classes.root, className, 'top-navbar');
    const showNotifications = Boolean(notificationsEl);

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            {/* Sidebar collapse button */}
            {!isSidebarOpen ? (
              <IconButton
                className={classes.menuButton}
                onClick={onToggleSidebar}
                variant="text"
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            {/* Back button */}
            <IconButton
              className={classes.menuButton}
              onClick={onBackButton}
              variant="text"
              title="Grįžti"
            >
              <ArrowBackIcon />
            </IconButton>
            {/* Title */}
            <Typography
              className={classes.title}
              variant="h5"
            >
              {title}
            </Typography>
            {/* Notifications menu */}
            <IconButton
              className={classes.notificationsButton}
              onClick={this.handleShowNotifications}
            >
              <Badge
                badgeContent={notificationsCount}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <LocaleSwitcher />

            {/* User menu */}
            <div>
              <IconButton
                aria-controls="menu-accounts"
                aria-haspopup="true"
                onClick={this.handleUserMenu}
              >
                <AccountIcon />
              </IconButton>
              <Menu
                id="menu-accounts"
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isUserMenuOpen}
                onClose={this.handleUserMenuClose}
              >
                <MenuItem onClick={this.handleAccountSwitch} id="li-accounts">
                  <FormattedMessage id="accounts" defaultMessage="Paskyros" />
                </MenuItem>
                <MenuItem onClick={this.handleCreateAccount} id="li-accounts-create">
                  <FormattedMessage id="Accounts.Create" defaultMessage="Sukurti naują paskyrą" />
                </MenuItem>
                <MenuItem onClick={this.handleProfile}>
                  <FormattedMessage id="profile" defaultMessage="Profilio duomenys" />
                </MenuItem>
                {canAccess('companies.write') ? (
                  <MenuItem onClick={this.handleCompanyEdit}>
                    <FormattedMessage id="Companies.Edit" defaultMessage="Įmonės duomenys" />
                  </MenuItem>
                ) : null}
                <MenuItem onClick={this.handleSignOut}>
                  <FormattedMessage id="signOut" defaultMessage="Atsijungti" />
                </MenuItem>
              </Menu>
            </div>

            {currentAccount ? (
              <div title={user ? user.email : null}>
                <Typography variant="body2">
                  {user ? user.fullName : null}
                </Typography>

                <Typography variant="body2">
                  {/* {user ? user.email : null} */}
                  {currentAccount.position}
                </Typography>
                
                <Typography variant="caption" display="block">
                  {currentAccount.company ? currentAccount.company.name : 'Įmonė nenurodyta'}
                </Typography>
              </div>
            ) : null}
          </Toolbar>
        </div>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app, dispatch }) => {
  return { dispatch, ...app };
}

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(Topbar);
