import React, { Component } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableFooter,
} from '@material-ui/core';
import {
  CommonPanel,
} from 'components';

import styles from './styles';
import ValueField from 'components/ValueField';

class CalculatedResults extends Component {

  state = {
    collapsed: true,
  }

  onChange = (code, { field, value }) => {
    console.log('onChange@CalculatedResults', code, value);

    const { percents, handleChange } = this.props;

    let target = _.find(percents, { code });
    target[field] = value;

    handleChange({
      name: 'percents',
      value: [...percents],
    });
  };

  renderHeaderColumns() {
    return (
      <TableRow>
        <TableCell component="th" style={{ width: '60px' }}>Kodas</TableCell>
        <TableCell component="th" style={{ minWidth: '210px' }}>Statinio konstrukcijų pavadinimas</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '90px' }}>Konstrukcijų lyginamasis svoris, %</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '120px' }}>Statinio vertė pagal konstrukcijas, EUR</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '90px' }}>Patikslintų konstrukcijų lyginamasis svoris, %</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '90px' }}>Konstrukcijų kainą patikslinantys koeficientai</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '120px' }}>Patikslinta statinio vertė, EUR</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '90px' }}>Statinio nusidėvėjimas, %</TableCell>
        <TableCell component="th" style={{ textAlign: 'center', verticalAlign: 'top', width: '120px' }}>Statinio vertė su nusidėvėjimu, EUR</TableCell>
      </TableRow>
    )
  }

  render() {
    const { percents, totals, classes } = this.props;

    return (
      <CommonPanel
        title={
          <FormattedMessage
            id="recoveryPriceCalculatedTableTitle"
            defaultMessage="Detalizavimas pagal konstrukcinius elementus"
          />
        }
        noPadding
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={collapsed => this.setState({ collapsed })}
      >
        <div style={{ overflowX: 'auto' }}>
          <Table size="small" className={classes.table} style={{ minWidth: '800px' }}>
            <TableHead>
              {this.renderHeaderColumns()}
            </TableHead>
            <TableBody>

              {_.map(percents, (item) => {
                return (
                  <TableRow key={item.title}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell className={classes.cellWithInput}>
                      <ValueField
                        value={item.revisedTitle}
                        initial={item.title}
                        name="revisedTitle"
                        defaultValue={item.revisedTitle}
                        onBlur={value => this.onChange(item.code, value)}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{item.constructionPercent}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{item.constructionPrice}</TableCell>
                    <TableCell className={classes.cellWithInput}>
                      <ValueField
                        inputStyle={{ textAlign: 'center' }}
                        value={item.revisedConstructionPercent}
                        initial={item.constructionPercent}
                        name="revisedConstructionPercent"
                        defaultValue="0"
                        onBlur={value => this.onChange(item.code, value)}
                      />
                    </TableCell>
                    <TableCell className={classes.cellWithInput}>
                      <ValueField
                        inputStyle={{ textAlign: 'center' }}
                        value={item.revisedConstructionCoefficient}
                        initial={item.constructionCoefficient}
                        name="revisedConstructionCoefficient"
                        defaultValue="0"
                        onBlur={value => this.onChange(item.code, value)}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{item.revisedPrice}</TableCell>
                    <TableCell className={classes.cellWithInput}>
                      <ValueField
                        inputStyle={{ textAlign: 'center' }}
                        value={item.depreciationPercent}
                        initial={item.depreciationPercentInitial}
                        defaultValue="0"
                        name="depreciationPercent"
                        onBlur={value => this.onChange(item.code, value)}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{item.depreciatedPrice}</TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={classes.boldCell} colSpan={2}>Viso</TableCell>
                <TableCell className={classes.boldCell} style={{ textAlign: 'center' }}>{totals.totalConstructionPercent}</TableCell>
                <TableCell className={classes.boldCell} style={{ textAlign: 'right' }}>{totals.totalConstructionPrice}</TableCell>
                <TableCell className={classes.boldCell} style={{ textAlign: 'center', paddingRight: '28px' }}>{totals.totalRevisedConstructionPercent}</TableCell>
                <TableCell className={classes.boldCell}>&nbsp;</TableCell>
                <TableCell className={classes.boldCell} style={{ textAlign: 'right' }}>{totals.totalRevisedPrice}</TableCell>
                <TableCell className={classes.boldCell}>&nbsp;</TableCell>
                <TableCell className={classes.boldCell} style={{ textAlign: 'right' }}>{totals.totalDepreciatedPrice}</TableCell>
              </TableRow>
              {this.renderHeaderColumns()}
            </TableFooter>
          </Table>
        </div>

      </CommonPanel>
    );
  }
}

export default withStyles(styles)(CalculatedResults);
