import { observable, action, decorate } from 'mobx';
import _ from 'lodash';
import {
  getVersions
} from 'services/norms';

class Store {
  // Observable
  options = []

  // Action
  setOptions = options => {
    this.options = options;
  }

  loadOptions = async () => {
    const versions = await getVersions();

    const options = _.map(versions, version => ({ value: version, label: version.version }));

    this.setOptions(options);
  }
}

decorate(Store, {
  options: observable,
  setOptions: action,
});

export default new Store;