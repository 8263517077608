import UserList from './components/UserList';
import UserDetails from './components/UserDetails';

export default [
  {
    key: 'list',
    component: UserList,
    path: '/users',
    public: false,
  },
  {
    key: 'create',
    component: UserDetails,
    path: '/users/create',
    public: false,
  },
  {
    key: 'edit',
    component: UserDetails,
    path: '/users/:id',
    public: false,
  },
  {
    key: 'profile',
    component: UserDetails,
    path: '/profile',
    public: false,
  }
];