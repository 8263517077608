import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  CircularProgress,
  Typography,
  Grid,
  Button,
  Link,
} from '@material-ui/core';
import {
  Edit as EditIcon,
} from '@material-ui/icons';
import { observer } from 'mobx-react';

import Norms from 'views/Norms';
import {
  CommonPanel,
  NormSearch,
  NormVersionPicker,
  FormField2,
  CommonDialog,
} from 'components';


import { twoOrMoreDecimals } from 'utils/helpers';
import styles from './styles';

class NormCalcTreeTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paddingTop: 86,
      expanded: [],
      showExportDialog: false,
    }

    this.elRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);

    // this.loadData();
  }

  async loadData() {
    await this.props.store.loadData();

    // Set padding for mobile view
    try {
      const paddingTop = window.document.querySelector('.sticky-table-header').offsetHeight;
      this.setState({ paddingTop });
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
    
    this.props.store.reset();
  }

  handleScroll = _.debounce(() => {
    const node = this.elRef.current;

    if (node !== undefined) {
      const header = node.querySelector('.sticky-table-header');
      const fromViewTop = node.getBoundingClientRect().top;
      const topbarHeight = document.querySelector('.top-navbar').offsetHeight;

      node.style.paddingTop = `${header.offsetHeight}px`;

      if (fromViewTop < 0) {
        header.style.top = Math.abs(fromViewTop - topbarHeight) + 'px';
      } else {
        header.style.top = '0px';
      }
    }
  })

  onTreeRowClick(e, item) {
    if (e.target.tagName === 'INPUT') return;

    this.props.store.handleExpand(item);
  }

  onParameterRowClick(e, item) {
    if (e.target.tagName === 'INPUT') return;

    this.props.store.handleParameterExpand(item);
  }

  onSearchResultSelected({ codePath }) {
    
    this.props.store.expandPath(codePath.split(';'));
  }

  onInputChange(name, value, item, triggerRecalc = true, instantRecalc = false) {
    _.set(item, name, value);

    if (triggerRecalc) this.props.store.doRecalc(item);

    if (instantRecalc) this.props.store.recalculate(item);
  }

  onInputKeyDown(e, item) {
    if (e.keyCode === 13) {
      // Cancel debouncer initiated by onInputChange
      this.props.store.doRecalc.cancel();

      // Recalculate
      this.props.store.recalculate(item);
    }
  }

  onAddCoefficientClick(item) {
    if (!item.details.customCoefficients) {
      item.details.customCoefficients = [];
    }

    const existsEmpty = _.find(item.details.customCoefficients, i => !i.title) !== undefined;
    if (existsEmpty) return;

    item.details.customCoefficients.push({
      code: `${item.details.customCoefficients.length}-${_.random(9999)}`,
      title: null,
      selected: false,
      k1: 1,
      k2: 1,
      k3: 1,
    });
  }

  renderTotalsUnit(value) {
    switch (value) {
      case 'percent':
        return '%'
      
      case 'coef':
        return 'koef.';

      case 'amount':
        return 'suma';

      default:
        return '-';
    }
  } 

  // renderDetailsRow({ details, level }) {
  renderDetailsRow(item) {
    const { code, details } = item;
    const { classes } = this.props;
    
    const contents = (
      <React.Fragment>
        {/* <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 10 }}>
          {details.titleCalc || '-'}
        </Typography> */}

        <div style={{ marginBottom: 10 }}>
          {/* <CommonPanel style={{ padding: 5 }}>
            <table className={classes.table}>
              <thead>
                <tr className="no-hover">
                  <th>Pavadinimas <span style={{ fontWeight: 'normal' }}>(patikslintas)</span></th>
                  <th style={{ width: '13%' }}>Kiekis</th>
                  <th style={{ width: '13%' }}>Kaina</th>
                  <th style={{ width: '13%' }}>Vertė</th>
                </tr>
              </thead>
              <tbody>
                <tr className="no-hover">
                  <td style={{ padding: '2px 8px' }}>
                    <FormField2
                      type="text"
                      name={'details.titleCalc'}
                      value={details.titleCalc}
                      onChange={(e) => {
                        const needsRecalc = e.value === '';
                        this.onInputChange(e.name, e.value, item, needsRecalc);
                      }}
                      placeholder="Darbo pavadinimas..."
                      rows={2}
                      multiline
                      naked
                    />
                  </td>
                  <td style={{ padding: '2px 8px' }}>
                    <FormField2
                      type="number"
                      name="quantityCalc"
                      value={item.quantityCalc || 0}
                      onChange={(e) => this.onInputChange(e.name, e.value, item)}
                      min={0}
                      step={0.0001}
                      naked
                    />
                  </td>
                  <td className={classes.textRight} style={{ padding: '2px 8px' }}>
                    {twoOrMoreDecimals(item.totals ? item.totals.unitAmountPriceVat : 0)}
                  </td>
                  <td className={classes.textRight} style={{ padding: '2px 8px' }}>
                    {twoOrMoreDecimals(item.totals ? item.totals.amountPriceVat : 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </CommonPanel> */}
          
          <CommonPanel style={{ padding: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {!details.editTitle ? (
                  <>
                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                      {details.titleCalc || '-'}
                      <Link title="Tikslinti darbo pavadinimą" onClick={() => details.editTitle = true} style={{ marginLeft: 5 }}>
                        <EditIcon fontSize="small" style={{ verticalAlign: 'top' }} />
                      </Link>
                    </Typography>
                  </>
                ) : (
                  <FormField2
                    type="text"
                    name={'details.titleCalc'}
                    value={details.titleCalc}
                    onChange={e => {
                      const needsRecalc = e.value === '';
                      this.onInputChange(e.name, e.value, item, needsRecalc);
                    }}
                    onKeyDown={e => this.onInputKeyDown(e, item)}
                    onBlur={() => details.editTitle = false}
                    placeholder="Darbo pavadinimas..."
                    rows={3}
                    multiline
                    naked
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <table className={classes.table}>
                  <thead>
                    <tr className="no-hover">
                      <th style={{ width: '33%' }}>Kiekis</th>
                      <th style={{ width: '33%' }}>Kaina</th>
                      <th>Vertė</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="no-hover">
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name="quantityCalc"
                          value={item.quantityCalc || 0}
                          onChange={(e) => this.onInputChange(e.name, e.value, item)}
                          onKeyDown={e => this.onInputKeyDown(e, item)}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td className={classes.textRight} style={{ padding: '2px 8px' }}>
                        {twoOrMoreDecimals(item.totals ? item.totals.unitAmountPriceVat : 0)}
                      </td>
                      <td className={classes.textRight} style={{ padding: '2px 8px' }}>
                        {twoOrMoreDecimals(item.totals ? item.totals.amountPriceVat : 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div style={{ textAlign: 'right', marginTop: 5, marginRight: 5 }}>
                  <Typography variant="body1">
                    <Link onClick={() => this.props.store.resetItem(item)}>
                      Atstatyti pirmines reikšmes
                    </Link>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Resursai"
            collapsible
            collapsed={details.resources.length === 0}
            disabled={details.resources.length === 0}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.resources.length ? (
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th style={{ width: '15%' }}>Kodas</th>
                    <th style={{ width: '35%' }}>Pavadinimas</th>
                    <th style={{ width: '10%' }}>Matas</th>
                    <th style={{ width: '10%' }}>Norma</th>
                    <th style={{ width: '10%' }}>Kiekis</th>
                    <th style={{ width: '10%' }}>Kaina</th>
                    <th style={{ width: '10%' }}>Suma</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(details.resources, (resource, resourceIdx) => (
                    <tr key={`${resource.code}-resource`}>
                      <td className={classes.textCenter}>{resource.code}</td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="text"
                          name={`details.resources[${resourceIdx}].titleCalc`}
                          value={resource.titleCalc}
                          onChange={(e) => {
                            // Apply same value to parameter resources with same code
                            for (const param of details.parameters) {
                              if (!param.resources) continue;
                              for (const paramResource of param.resources) {
                                if (paramResource.code !== resource.code) continue;
                                paramResource.titleCalc = e.value;
                              }
                            }

                            const needsRecalc = e.value === '';
                            this.onInputChange(e.name, e.value, item, needsRecalc);
                          }}
                          onKeyDown={e => this.onInputKeyDown(e, item)}
                          placeholder="Resurso pavadinimas..."
                          naked
                        />
                      </td>
                      <td>{resource.unit}</td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name={`details.resources[${resourceIdx}].normCalc`}
                          value={resource.normCalc}
                          onChange={(e) => {
                            // Clean to recalculate
                            this.onInputChange(`details.resources[${resourceIdx}].quantity`, null, item, true);
                            this.onInputChange(`details.resources[${resourceIdx}].quantityCalc`, null, item, true);

                            // Clean parameters
                            for (const param of item.details.parameters) {
                              for (const paramRes of param.resources) {
                                if (paramRes.code === resource.code) {
                                  paramRes.quantity = 0;
                                  paramRes.normCalc = null;
                                }
                              }
                            }

                            this.onInputChange(e.name, e.value, item);
                          }}
                          onKeyDown={e => this.onInputKeyDown(e, item)}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name={`details.resources[${resourceIdx}].quantity`}
                          value={resource.quantityCalc}
                          onChange={(e) => {
                            // Clean norm to recalculate
                            this.onInputChange(`details.resources[${resourceIdx}].normCalc`, null, item, true);
                            this.onInputChange(`details.resources[${resourceIdx}].quantityCalc`, e.value, item, true);

                            // Clean parameters
                            for (const param of item.details.parameters) {
                              for (const paramRes of param.resources) {
                                if (paramRes.code === resource.code) {
                                  paramRes.quantity = 0;
                                  paramRes.normCalc = null;
                                }
                              }
                            }


                            this.onInputChange(e.name, e.value, item);
                          }}
                          onKeyDown={e => this.onInputKeyDown(e, item)}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name={`details.resources[${resourceIdx}].priceCalc`}
                          value={resource.priceCalc}
                          onChange={(e) => {
                            // Apply same value to parameter resources with same code
                            for (const param of details.parameters) {
                              if (!param.resources) continue;
                              for (const paramResource of param.resources) {
                                if (paramResource.code !== resource.code) continue;
                                paramResource.priceCalc = e.value;
                              }
                            }
                            
                            this.onInputChange(e.name, e.value, item);
                          }}
                          onKeyDown={e => this.onInputKeyDown(e, item)}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.amountCalc)}</td>
                    </tr>
                  ))}

                  {details.parameters.length ? (
                    <React.Fragment>
                      <tr>
                        <td colSpan={7} className={classes.parametersHeaderRow}>
                          Pokyčio parametrai
                        </td>
                      </tr>

                      {_.map(details.parameters, (parameter, paramIdx) => (
                        <React.Fragment key={`${parameter.code}-parameter`}>
                          <tr onClick={(e) => this.onParameterRowClick(e, parameter)}>
                            <td className={classes.parameterCode}>{parameter.code}</td>
                            <td colSpan={3}>{parameter.title}</td>
                            <td style={{ padding: '2px 8px' }}>
                              <FormField2
                                type="number"
                                name={`details.parameters[${paramIdx}].quantityCalc`}
                                value={parameter.quantityCalc || 0}
                                onChange={(e) => {
                                  this.onInputChange('details.titleCalc', null, item, false);

                                  // Empty calculated norm for resources with same code
                                  for (const res of details.resources) {
                                    // if (res.code !== resource.code) continue;
                                    res.normCalc = null;
                                  }

                                  this.onInputChange(e.name, e.value, item)
                                }}
                                onKeyDown={e => this.onInputKeyDown(e, item)}
                                min={0}
                                step={0.0001}
                                naked
                              />
                            </td>
                            <td colSpan={2} />
                          </tr>
                          {parameter.resources && parameter.expanded ? (
                            _.map(parameter.resources, (resource, paramResourceIdx) => (
                              <tr key={`${parameter.code}-${resource.code}-resource`}>
                                <td className={classes.textCenter}>{resource.code}</td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="text"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].titleCalc`}
                                    value={resource.titleCalc}
                                    onChange={(e) => {
                                      // Apply same value to resources with same code
                                      for (const res of details.resources) {
                                        if (res.code !== resource.code) continue;
                                        res.titleCalc = e.value;
                                      }
                                      
                                      const needsRecalc = e.value === '';
                                      this.onInputChange(e.name, e.value, item, needsRecalc);
                                    }}
                                    onKeyDown={e => this.onInputKeyDown(e, item)}
                                    placeholder="Resurso pavadinimas..."
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td>{resource.unit}</td>
                                {/* <td className={classes.textRight}>{twoOrMoreDecimals(resource.norm)}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.quantity)}</td> */}
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="number"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].normCalc`}
                                    value={resource.normCalc}
                                    onChange={(e) => {
                                      this.onInputChange(`details.parameters[${paramIdx}].resources[${paramResourceIdx}].quantity`, null, item, true);

                                      // Empty calculated norm for resources with same code
                                      for (const res of details.resources) {
                                        if (res.code !== resource.code) continue;
                                        res.normCalc = null;
                                      }

                                      this.onInputChange(e.name, e.value, item);
                                    }}
                                    onKeyDown={e => this.onInputKeyDown(e, item)}
                                    min={0}
                                    step={0.0001}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="number"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].quantity`}
                                    value={resource.quantity}
                                    onChange={(e) => {
                                      this.onInputChange(`details.parameters[${paramIdx}].resources[${paramResourceIdx}].normCalc`, null, item, true);

                                      // Empty calculated norm for resources with same code
                                      for (const res of details.resources) {
                                        if (res.code !== resource.code) continue;
                                        res.normCalc = null;
                                      }

                                      this.onInputChange(e.name, e.value, item);
                                    }}
                                    onKeyDown={e => this.onInputKeyDown(e, item)}
                                    min={0}
                                    step={0.0001}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="number"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].priceCalc`}
                                    value={resource.priceCalc}
                                    onChange={(e) => {
                                      // Apply same value to resources with same code
                                      for (const res of details.resources) {
                                        if (res.code !== resource.code) continue;
                                        res.priceCalc = e.value;
                                      }
                                      
                                      this.onInputChange(e.name, e.value, item);
                                    }}
                                    onKeyDown={e => this.onInputKeyDown(e, item)}
                                    min={0}
                                    step={0.0001}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.amountCalc)}</td>
                              </tr>
                            ))
                          ) : null}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : null}
                </tbody>
              </table>
            ) : null}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Kainos sudėtis"
            collapsible
            collapsed={_.indexOf(this.state.expanded, `${item.code}-price-composition`) === -1}
            onCollapse={() => this.setState({ ...this.state, expanded: [ ...this.state.expanded, `${item.code}-price-composition` ] })}
            disabled={!item.totals}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {item.totals ? (
              <table className={this.props.classes.table}>
                <thead>
                  <tr className="no-hover">
                    <th rowSpan={2}>Pavadinimas</th>
                    <th colSpan={3}>Papildomi priskaitymai</th>
                    <th rowSpan={2} style={{ width: '16%' }}>Suma, EUR <br /> <span style={{ fontWeight: 'normal' }}>(su priskaitymais)</span></th>
                  </tr>
                  <tr className="no-hover">
                    <th style={{ width: '8%' }}>Matas</th>
                    <th style={{ width: '15%' }}>Rodiklio reikšmė</th>
                    <th style={{ width: '16%' }}>Kaina, EUR</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Darbo užmokestis */}
                  <tr>
                    <td>Darbo užmokestis</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.pdu.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pdu.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pdu.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pdu.amount)}
                    </td>
                  </tr>
                  {/* Medžiagos */}
                  <tr>
                    <td>Medžiagos</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.pmed.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pmed.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pmed.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pmed.amount)}
                    </td>
                  </tr>
                  {/* Mechanizmų eksploatacija */}
                  <tr>
                    <td>Mechanizmų eksploatacija</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.pmech.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pmech.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pmech.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pmech.amount)}
                    </td>
                  </tr>
                  {/* Soc. draudmas */}
                  <tr>
                    <td>Soc. draudmas</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.sd.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.sd.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.sd.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.sd.amount)}
                    </td>
                  </tr>
                  {/* TOTAL Statybos išlaidos */}
                  <tr className={classes.bold}>
                    <td colSpan={4}>Statybos išlaidos</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.amountConstExp)}
                    </td>
                  </tr>
                  {/* Statybvietės išlaidos */}
                  <tr>
                    <td>Statybvietės išlaidos</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.sti.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.sti.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.sti.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.sti.amount)}
                    </td>
                  </tr>
                  {/* TOTAL Viso tiesioginės išlaidos */}
                  <tr className={classes.bold}>
                    <td colSpan={4}>Viso tiesioginės išlaidos</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.amountDirectExp)}
                    </td>
                  </tr>
                  {/* Pridėtinės išlaidos */}
                  <tr>
                    <td>Pridėtinės išlaidos</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.pr.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pr.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pr.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pr.amount)}
                    </td>
                  </tr>
                  {/* Pelnas */}
                  <tr>
                    <td>Pelnas</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.p.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.p.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.p.price)}
                    </td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.p.amount)}
                    </td>
                  </tr>
                  {/* TOTAL Viso netiesioginės išlaidos */}
                  <tr className={classes.bold}>
                    <td colSpan={4}>Viso netiesioginės išlaidos</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.amountIndirectExp)}
                    </td>
                  </tr>
                  {/* TOTAL Viso kaina */}
                  <tr className={classes.bold}>
                    <td colSpan={4}>Viso kaina</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.amountPrice)}
                    </td>
                  </tr>
                  {/* PVM */}
                  <tr>
                    <td  className={classes.bold}>PVM</td>
                    <td className={classes.textCenter}>{this.renderTotalsUnit(item.totals.pvm.unit)}</td>
                    <td className={classes.textRight}>{twoOrMoreDecimals(item.totals.pvm.norm)}</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.pvm.price)}
                    </td>
                    <td className={[classes.textRight, classes.bold].join(' ')}>
                      {twoOrMoreDecimals(item.totals.pvm.amount)}
                    </td>
                  </tr>
                  {/* TOTAL Viso kaina su PVM */}
                  <tr className={classes.bold}>
                    <td colSpan={4}>Viso kaina su PVM</td>
                    <td className={classes.textRight}>
                      {twoOrMoreDecimals(item.totals.amountPriceVat)}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Darbų sudėtis"
            collapsible
            collapsed
            disabled={!details.details}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.details}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Koeficientai sąnaudoms"
            collapsible
            collapsed={_.indexOf(this.state.expanded, `${item.code}-coefficients`) === -1}
            onCollapse={() => this.setState({ ...this.state, expanded: [ ...this.state.expanded, `${item.code}-coefficients` ] })}
            disabled={!details.coefficients}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.coefficients && details.coefficients.length ? (
              <>
                <Typography variant="caption" display="block" style={{ textAlign: 'right'}}>
                  K1 - darbo sąnaudos, 
                  K2 - mechanizmai, 
                  K3 - medžiagos
                </Typography>

                <table className={this.props.classes.table}>
                  <thead>
                    <tr>
                      <th colSpan={2}>Koeficientų taikymo sąlygos</th>
                      <th style={{ width: '8%' }}>K1</th>
                      <th style={{ width: '8%' }}>K2</th>
                      <th style={{ width: '8%' }}>K3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(details.coefficients, (coefficient, idxCoef) => (
                      <tr key={`${idxCoef}-coefficient`}>
                        <td style={{ textAlign: 'center' }}>
                          <FormField2
                            type="checkbox"
                            name={`details.coefficients[${idxCoef}].selected`}
                            value={coefficient.selected}
                            onChange={(e) => this.onInputChange(e.name, e.value, item, false, true)}
                            naked
                            style={{ padding: 0 }}
                          />
                        </td>
                        <td>{coefficient.title}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k1)}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k2)}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k3)}</td>
                      </tr>
                    ))}

                    {details.customCoefficients && details.customCoefficients.length ? _.map(details.customCoefficients, (coefficient, coefIdx) => (
                      <tr key={coefficient.code}>
                        <td style={{ textAlign: 'center' }}>
                          <FormField2
                            type="checkbox"
                            name={`details.customCoefficients[${coefIdx}].selected`}
                            value={coefficient.selected}
                            onChange={(e) => this.onInputChange(e.name, e.value, item, false, true)}
                            naked
                            style={{ padding: 0 }}
                          />
                        </td>
                        <td>
                          <FormField2
                            type="text"
                            name={`details.customCoefficients[${coefIdx}].title`}
                            value={coefficient.title}
                            onChange={(e) => this.onInputChange(e.name, e.value, item, false)}
                            placeholder="Koeficiento pavadinimas..."
                            naked
                          />
                        </td>
                        <td>
                          <FormField2
                            type="number"
                            name={`details.customCoefficients[${coefIdx}].k1`}
                            value={coefficient.k1}
                            onChange={(e) => this.onInputChange(e.name, e.value, item)}
                            onKeyDown={e => this.onInputKeyDown(e, item)}
                            min={0}
                            step={0.0001}
                            naked
                          />
                        </td>
                        <td>
                          <FormField2
                            type="number"
                            name={`details.customCoefficients[${coefIdx}].k2`}
                            value={coefficient.k2}
                            onChange={(e) => this.onInputChange(e.name, e.value, item)}
                            onKeyDown={e => this.onInputKeyDown(e, item)}
                            min={0}
                            step={0.0001}
                            naked
                          />
                        </td>
                        <td>
                          <FormField2
                            type="number"
                            name={`details.customCoefficients[${coefIdx}].k3`}
                            value={coefficient.k3}
                            onChange={(e) => this.onInputChange(e.name, e.value, item)}
                            onKeyDown={e => this.onInputKeyDown(e, item)}
                            min={0}
                            step={0.0001}
                            naked
                          />
                        </td>
                      </tr>
                    )) : null}
                  </tbody>
                  <tfoot>
                    <tr className={classes.textCenter}>
                      <td colSpan={5}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => this.onAddCoefficientClick(item)}
                        >
                          Pridėti koeficientą
                        </Button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </>
            ) : null}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Nustatymai darbo kainos apskaičiavimams"
            collapsible
            collapsed={_.indexOf(this.state.expanded, `${item.code}-priceSettings`) === -1}
            onCollapse={() => this.setState({ ...this.state, expanded: [ ...this.state.expanded, `${item.code}-priceSettings` ] })}
            disabled={!details.priceSettings}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.priceSettings ? (
              <>
                <table className={this.props.classes.table}>
                  <thead>
                    <tr>
                      <th style={{ width: '12%' }}>Kodas</th>
                      <th>Pavadinimas</th>
                      <th style={{ width: '12%' }}>Matas</th>
                      <th style={{ width: '12%' }}>Rodiklio reikšmė*</th>
                      <th style={{ width: '12%' }}>Suma, EUR <br /> <span style={{ fontWeight: 'normal' }}>(apskaičiuota)</span></th>                    
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(details.priceSettings, (setting, settingIdx) => (
                      <tr key={setting.code}>
                        <td style={{ textAlign: 'center' }}>{setting.code ? setting.code.toUpperCase() : '-'}</td>
                        <td style={{ padding: '2px 8px' }}>
                          <FormField2
                            type="text"
                            name={`details.priceSettings[${settingIdx}].titleCalc`}
                            value={setting.titleCalc}
                            onChange={(e) => {
                              const needsRecalc = e.value === '';
                              this.onInputChange(e.name, e.value, item, needsRecalc);
                            }}
                            onKeyDown={e => this.onInputKeyDown(e, item)}
                            placeholder="Rodiklio pavadinimas..."
                            naked
                          />
                        </td>
                        <td style={{ padding: '2px 8px' }}>
                          <FormField2
                            type="select"
                            options={[
                              { label: '%', value: 'percent' }, 
                              { label: 'Koeficientas', value: 'coef' }, 
                              { label: 'Suma, EUR', value: 'amount' }
                            ]}
                            name={`details.priceSettings[${settingIdx}].unitCalc`}
                            value={setting.unitCalc}
                            onChange={(e) => {
                              const valBefore = setting.unitCalc;
                              const valNew = e.value;
                              
                              if (valBefore === 'percent' && valNew === 'coef') {
                                setting.valueCalc = setting.valueCalc / 100;
                              } else if (valBefore === 'coef' && valNew === 'percent') {
                                setting.valueCalc = setting.valueCalc * 100;
                              } else if (valNew === 'amount') {
                                setting.valueCalc = 0;
                              }

                              this.onInputChange(e.name, valNew, item);
                            }}
                            naked
                            style={{ margin: 0 }}
                          />
                        </td>
                        <td style={{ padding: '2px 8px' }}>
                          <FormField2
                            type="number"
                            name={`details.priceSettings[${settingIdx}].valueCalc`}
                            value={setting.valueCalc}
                            onChange={(e) => this.onInputChange(e.name, e.value, item)}
                            onKeyDown={e => this.onInputKeyDown(e, item)}
                            min={0}
                            step={0.0001}
                            naked
                          />
                        </td>
                        <td className={classes.textRight}>{twoOrMoreDecimals(setting.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <Typography variant="caption" display="block" style={{ marginTop: 10, marginRight: 5, textAlign: 'right' }}>
                  * Norėdami atstatyti originalią rodiklio reikšmę išvalykite reikšmės įvedimo lauką
                </Typography>
              </>
            ) : null}
          </CommonPanel>
        </div>

      </React.Fragment>
    );

    return (
      <tr
        key={`${code}-details`}
        className={[this.props.classes.detailsRow, 'tree-details'].join(' ')}
      >
        <td />
        <td colSpan={5}>
          {contents}
        </td>
      </tr>
    )
  }

  renderFirstColumn({ code, lastLevel, expanded }) {
    const { classes } = this.props;
    return (
      <td className={classes.firstColumn} style={{ width: '20%' }}>
        {!lastLevel && <span className={classes.expander}>{expanded ? '-' : '+'}</span>}
        {code}
      </td>
    )
  }

  renderTableRow(item, isLast) {
    let rows = [];

    const mainRow = (
      <tr
        key={item.code}
        onClick={e => this.onTreeRowClick(e, item)}
        className={[
          item.expanded ? [this.props.classes.expandedRow, 'tree-expanded'].join(' ') : '', 
          `code-${item.code}`.replace('#', ''), 
          `tree-node level-${item.level} ${isLast ? 'level-last' : ''}`,
        ].join(' ')}
      >
        {this.renderFirstColumn(item)}
        <td>{item.title}</td>
        <td style={{ width: '10%', textAlign: 'center' }}>{item.unit}</td>
        {/* <td style={{ width: '10%', textAlign: 'center' }}>{item.quantityCalc}</td> */}
        <td style={{ width: '10%', padding: '2px 8px' }}>
          {item.lastLevel ? (
            <FormField2
              type="number"
              name="quantityCalc"
              value={item.quantityCalc || 0}
              onChange={(e) => this.onInputChange(e.name, e.value, item)}
              onKeyDown={e => this.onInputKeyDown(e, item)}
              min={0}
              step={0.0001}
              naked
            />
          ) : null}
        </td>

        <td style={{ width: '10%', textAlign: 'right' }}>{item.lastLevel ? twoOrMoreDecimals(_.get(item, 'totals.unitAmountPriceVat', 0)) : null}</td>
        <td style={{ width: '10%', textAlign: 'right' }}>{item.lastLevel ? twoOrMoreDecimals(_.get(item, 'totals.amountPriceVat', 0)) : null}</td>
      </tr>
    )
    rows.push(mainRow);

    if (item.expanded && !item.details) {
      const childRows = _.map(item.children, (child, idx) => this.renderTableRow(child, (idx + 1) === item.children.length && !child.expanded))
      rows = _.concat(rows, childRows);
    } else if (item.expanded && item.details) {
      const detailsRow = this.renderDetailsRow(item);
      rows.push(detailsRow);
    }

    return rows;
  }

  renderData() {
    const { data } = this.props.store;

    const rows = _.map(data, item => this.renderTableRow(item));
    // const rows = _.map(_.keys(data), key => this.renderTableRow(data[key]));

    return rows;
  }

  renderSearch() {
    const { version } = this.props.store;
    return (
      <NormSearch
        onSelect={this.onSearchResultSelected.bind(this)}
        inputStyle={{ marginTop: 5 }}
        version={version ? version.code : null}
      />
    );
  }

  renderVersions() {
    const { store } = this.props;

    return (
      <NormVersionPicker
        value={store.version}
        name="version"
        onChange={({ value }) => store.setVersion(value)}
        label="Versija"
        style={{ marginTop: 5, background: '#fff' }}
        defaultFirst
      />
    );
  }

  renderExportButton() {

    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => this.setState({ showExportDialog: true })}
        style={{ marginTop: 8 }}
        disabled={!this.props.store.touchedItems.length}
      >
        Eksportuoti į Excel
      </Button>
    )
  }

  exportAllToggle(checked) {
    const { touchedItems } = this.props.store;

    _.forEach(touchedItems, item => {
      item.isExporting = checked;
    });
  }

  submitExportToExcel() {
    this.setState({ showExportDialog: false });

    this.props.store.exportToExcel();
  }

  renderExportDialog() {
    const { classes, store } = this.props;
    const { touchedItems } = store;
    const exporting = touchedItems.filter(i => i.isExporting);
    const disabled = !exporting.length;
    const allSelected = exporting.length === touchedItems.length;

    return (
      <CommonDialog
        visible={this.state.showExportDialog}
        title="Skaičiavimų eksportavimas Excel formatu"
        onClose={() => this.setState({ showExportDialog: false })}
        actions={[
          <Button
            key="export"
            onClick={this.submitExportToExcel.bind(this)}
            color="primary"
            disabled={disabled}
          >
            Eksportuoti pažymėtus
          </Button>
        ]}
        // maxWidth="xs"
      >
        <div className={classes.dialogContentWrap}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th style={{ width: '5%' }}>
                  <input
                    type="checkbox"
                    onChange={e => this.exportAllToggle(e.target.checked)} 
                    checked={allSelected}
                  />
                </th>
                <th style={{ width: '15%' }}>
                  Kodas
                </th>
                <th>
                  Pavadinimas
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(touchedItems, (item, idx) => {
                let title = _.get(item, 'details.titleCalc', item.title);
                title = item.code && title ? title.replace(item.code, '').trim() : title;

                return (
                  <tr key={`${item.code}-${idx}`}>
                    <td>
                      <input type="checkbox" onChange={e => item.isExporting = e.target.checked} checked={item.isExporting} />
                    </td>
                    <td>
                      {item.code}
                    </td>
                    <td>
                      {title}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </CommonDialog>
    )
  }

  render() {
    const { classes, store } = this.props;

    return (
      <Norms title="Normatyvų skaičiavimai">
        <div className={classes.wrap}>

          <div ref={this.elRef} className="sticky-table-wrap" style={{ paddingTop: this.state.paddingTop }}>
            
            <div className="sticky-table-header">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      {this.renderSearch()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {this.renderVersions()}
                    </Grid>
                    <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
                      {this.renderExportButton()}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.stickyTableWrap}>
                  <table className={classes.table} style={{ tableLayout: 'fixed' }}>
                    <thead>
                      <tr style={{ height: 46 }}>
                        <th style={{ width: '20%' }}>Darbo kodas</th>
                        <th>Pavadinimas</th>                        <th style={{ width: '10%' }}>Matas</th>
                        <th style={{ width: '10%' }}>Kiekis</th>
                        <th style={{ width: '10%' }}>Darbo vnt. kaina</th>
                        <th style={{ width: '10%' }}>Suma</th>
                      </tr>
                    </thead>
                  </table>
                </Grid>
              </Grid>
            </div>

            <div className={classes.tableWrap}>
              <table className={[classes.table, 'norms-tree'].join(' ')} style={{ tableLayout: 'fixed' }}>
                <tbody>
                  {this.renderData()}
                </tbody>
              </table>
            </div>
          </div>

          {store.loading ? (
            <div className={classes.loaderWrap}>
              <CircularProgress />
            </div> 
          ) : null}
        </div>

        {this.renderExportDialog()}
      </Norms>
    )
  }
}

export default compose(withStyles(styles))(observer(NormCalcTreeTableContainer));