import api from 'utils/api';

const servicePath = '/norm-packages';

export const getAll = async () => {
  const response = await api.get(servicePath);
  const records = response.data;

  return records;
}

export const getOne = async (id) => {
  const response = await api.get(`${servicePath}/${id}`);
  const records = response.data;

  return records;
}

export const create = async (details) => {
  const response = await api.post(servicePath, details);
  const record = response.data;

  return record;
}

export const update = async (id, details) => {
  const response = await api.put(`${servicePath}/${id}`, details);
  const record = response.data;

  return record;
}