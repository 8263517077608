import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { NtkFilters, NtkMaterialPickerHorizontal } from 'components';
import { NtkResults } from './components';

import styles from './styles';
import { canAccess } from 'utils/helpers';
import {
  getTypes,
  valueChanged,
  resetForm,
} from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { ntkViewer } from './_reducers';
store.injectReducer('ntkViewer', ntkViewer);

class NtkViewer extends Component {

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getTypes());
  }

  handleChange({ name, value }) {
    if (!name) {
      return;
    }

    console.log({ name, value });
    const { dispatch } = this.props;
    dispatch(valueChanged({ name, value }));
  }

  render() {
    const {
      classes,
      isLoading,
      
      type,
      buildingType,
      pricing,
      
      types,
      versions,
      hierarchy,
      materials,
      initialMaterials,
      prices,
      combinations,
      files,
    } = this.props;

    return (
      <DashboardLayout 
        title={`NTK paieška ${canAccess('ntk.demo') ? 'DEMO' : ''}`}
        isContentLoading={isLoading}
      >

        <div className={classes.root} style={{ maxWidth: '1200px' }}>
          
          <Grid container spacing={0}>

            <Grid item lg={5} xl={5} sm={12} xs={12}>
              
              <div className={classes.panel}>
                <NtkFilters
                  filtersOnly                
                  type={type}
                  buildingType={buildingType}
                  pricing={pricing}
                  
                  types={types}
                  versions={versions}
                  hierarchy={hierarchy}
                  
                  handleChange={this.handleChange.bind(this)}
                />
              </div>
              
            </Grid>

            <Grid item xs={12}>
              
              {materials && materials.length ? (
                <div className={classes.panel}>
                  <NtkMaterialPickerHorizontal
                    materials={materials}
                    initialMaterials={initialMaterials}
                    handleChange={this.handleChange.bind(this)}
                  />
                </div>
              ) : null}
              
            </Grid>

            <Grid item xs={12}>
              
              {prices ? (
                <div className={classes.panel}>
                  <NtkResults
                    files={files}
                    type={type}
                    materials={materials}
                    buildingType={buildingType}
                    data={prices}
                    combinations={combinations}
                  />
                </div>
              ) : null}

            </Grid>

          </Grid>

        </div>

      </DashboardLayout>
    );
  }
}

const mapStateToProps = ({ ntkViewer, dispatch }) => {
  return {
    ...ntkViewer,
    dispatch,
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(NtkViewer);
