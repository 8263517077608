export default theme => ({
  root: {},
  selectField: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  textFieldNaked: {},
  formControl: {
    // paddingTop: theme.spacing(),
    // paddingBottom: theme.spacing(),
  },
  textarea: {
    color: '#12161b',
    padding: '6px 6px 7px',
    border: '1px solid #c4c4c4',
    margin: '2px 0px',
    borderRadius: '4px',
  },
});
