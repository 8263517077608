import api from 'utils/api';

const login = async (email, password) => {
  try {
    const response = await api.post('/auth', { email, password });
    const { token, user, lastAccount } = response.data;

    // Store JWT token in local storage
    localStorage.setItem('jwt', token);

    return { user, lastAccount };
  } catch (error) {
    return Promise.reject(error);
  }
}

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

const validateSession = async () => {
  try {
    await api.get('/session');
    return true;
  } catch (error) {
    return false;
  }
}

const register = async (details) => {
  const response = await api.post('/register', details);
  const { user } = response.data;

  return user;
}

const createOne = async (details) => {
  const response = await api.post('/users', details);
  const { user } = response.data;

  return user;
}

const updateOne = async (details) => {
  const response = await api.put(`/users/${details._id}`, details);
  const user = response.data;

  return user;
}

const getAll = async () => {
  const response = await api.get('/users');
  const users = response.data;

  return users;
}

const getOne = async (id) => {
  const response = await api.get(`/users/${id}`);
  const user = response.data;

  return user;
}

const getUserAccounts = async (id) => {
  const response = await api.get(`/users/${id}/accounts`);
  const user = response.data;

  return user;
}

// TODO move to account services
const getNotifications = async (id) => {
  const response = await api.get(`/accounts/${id}/notifications`);
  const notifications = response.data;

  return notifications;
}

// TODO move to account services
const setNotificationOpened = async (id, notificationId) => {
  await api.get(`/accounts/${id}/notifications/${notificationId}`);
}

const checkEmail = async (email) => {
  const response = await api.post('/check-email', { email });
  const data = response.data;

  return data;
}

const hardDelete = async (id) => {
  return await api.delete(`/users/${id}/hard`);
}

const activate = async (hash) => {
  const response = await api.post('/activate', { hash });
  const data = response.data;

  return data;
}

const resetPassword = async (params) => {
  const response = await api.post('/password-reset', params);
  const data = response.data;

  return data;
}

export const userService = {
  login,
  logout,
  validateSession,
  register,
  getAll,
  getOne,
  updateOne,
  createOne,
  getNotifications,
  setNotificationOpened,
  getUserAccounts,
  checkEmail,
  activate,
  resetPassword,
  hardDelete,
};