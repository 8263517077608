import React, { Component } from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import styles from './styles';

class FormDialog extends Component {

  handleClose(wasSubmitted) {
    const { onClose } = this.props;

    if (onClose) {
      onClose(wasSubmitted);
    } else {
      console.log('Modal close handler not provided');
    }
  }

  handleSubmit() {
    const { formId } = this.props;

    document
      .getElementById(formId)
      .dispatchEvent(new Event('submit', { cancelable: true }));

    // `true` flag identifies that form was submitted
    this.handleClose(true);
  }

  render() {
    const { visible, component, title } = this.props;

    return (
      <Dialog open={visible} onClose={this.handleClose.bind(this)} aria-labelledby="form-dialog-title">
        {title ? (<DialogTitle id="form-dialog-title">{title}</DialogTitle>) : null}
        <DialogContent>
          {component}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit.bind(this)} color="primary">
            Išsaugoti
          </Button>
          <Button onClick={this.handleClose.bind(this)} color="primary">
            Atšaukti
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(FormDialog);