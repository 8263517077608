import { toast as Toast } from 'react-toastify';
import appConstants from './_constants';
import { accountService } from 'views/Accounts/_services';
import { history } from 'utils/history';

export const toast = (message, type = 'info', autoClose = 10000) => {
  Toast[type](message, { autoClose });
}

export const setLocale = (locale = 'lt') => (dispatch) => {
  localStorage.setItem('locale', locale);

  dispatch({ type: appConstants.SET_LOCALE, locale });
}

export const openAccountDialog = () => (dispatch) => {
  dispatch({ type: appConstants.ACCOUNT_DIALOG_OPEN });
}

export const closeAccountDialog = () => (dispatch) => {
  dispatch({ type: appConstants.ACCOUNT_DIALOG_CLOSED });
}

export const setAccount = (accountId) => async (dispatch) => {
  dispatch({ type: appConstants.SET_ACCOUNT_REQUEST });

  try {
    const { account, token } = await accountService.setActive(accountId);
    
    // Update JWT token in local storage
    localStorage.setItem('jwt', token);

    dispatch({ type: appConstants.SET_ACCOUNT_SUCCESS, account });
    toast(`Pasirinkita paskyra: ${account.position}`);
    history.push('/');
  } catch (error) {
    console.log('Account set error');
    // dispatch({ type: appConstants.SET_ACCOUNT_FAILURE, error });
    localStorage.removeItem('jwt');
    localStorage.removeItem('state');
    window.location.href = '/';
  }
}