import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/companies';

// TODO migrate other methods from Views/Companies

export const getAll = async (params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}

export const getServices = async (id, params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}/${id}/services${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}

export const getServicePackages = async (id, service) => {
  const response = await api.get(`${servicePath}/${id}/services/${service}/packages`);
  const records = response.data;

  return records;
}

export const getOrders = async (id, params) => {
  const query = objectToQueryString(params);
  const response = await api.get(`${servicePath}/${id}/orders${query ? '?' + query : ''}`);
  const records = response.data;

  return records;
}