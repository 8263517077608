import React, { Component } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import { Prompt } from 'react-router-dom';

import RecoveryPrice from 'views/RecoveryPrice';
import ProjectDetails from './components/ProjectDetails';
import SiteDetails from './components/SiteDetails';
import CalculatedResults from './components/CalculatedResults';
import MaterialChooser from './components/MaterialChooser';
import CoefficientTable from './components/CoefficientTable';
import CalculatedTable from './components/CalculatedTable';
import CorrectionValues from './components/CorrectionValues';
import {
  getTypes,
  valueChanged,
  resetForm,
  getProjects,
  saveForm,
  getRecord,
  updateForm,
} from './_actions';

import {
  confirmDialog,
} from 'utils/helpers';


// Component styles
const styles = () => ({
  root: {
    padding: '0px',
  },
  item: {
    height: '100%'
  }
});

class RecoveryPriceForm extends Component {
  
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  componentWillUnmount() {
    // Remove the page leave listener
    // window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentDidMount() {
    // Listen for page leave attempt
    // window.addEventListener('beforeunload', this.beforeunload.bind(this));

    const { dispatch, match } = this.props;
    const recordId = match.params.id;
    if (recordId) {
      dispatch(getRecord(recordId));
    } else {
      const projectId = queryString
        .parse(this.props.location.search, {ignoreQueryPrefix: true })
        .project;
      
      dispatch(getProjects(projectId));
      dispatch(getTypes());
    }
  }

  getWallTypes() {
    const { record } = this.props;

    if (!record.materials) {
      return;
    }

    const materialGroup = _.find(record.materials, { type: 'Sienos' });
    if (!materialGroup) {
      return;
    }

    const wallMaterials = materialGroup.values.join(', ').split(', ');

    return wallMaterials;
  }

  handleChange({ name, value }) {
    if (!name) {
      return;
    }

    const { dispatch } = this.props;
    dispatch(valueChanged({ name, value }));
  }

  beforeunload(e) {
    const { record } = this.props;

    if (record.amount) {
      console.log('Cannot leave');
      e.preventDefault();
      e.returnValue = true;
    }
  }

  handleClearForm() {
    const { dispatch, record } = this.props;
    if (confirmDialog('Ar tikrai norite išvalyti skaičiavimo duomenis?')) {
      dispatch(resetForm());
      dispatch(getProjects(record.project));
      dispatch(getTypes());

      if (record._id) {
        // Restore ID if existing record
        dispatch(valueChanged({ name: '_id', value: record._id }, true));
      }
    }
  }

  handleCreatedNewProject(newId) {
    this.props.dispatch(getProjects());

    if (newId) {
      const self = this;
      setTimeout(function() {
        self.handleChange({ name: 'project', value: newId });
      }, 2000)
    }
  }

  handleSaveForm() {
    const { dispatch, record } = this.props;

    if (record._id) {
      dispatch(updateForm());
    } else {
      dispatch(saveForm());
    }
  }
 
  renderContent() {
    const { classes, data, record, showMaterials, skipMaterials, showResults, isLoadingRecord } = this.props;

    return (
      <div className={classes.root} style={{ marginTop: '-20px', maxWidth: '1200px' }}>
        <Prompt
          // when={!record._id && record.amount}
          when={record.amount}
          message="Ar tikrai norite išeiti iš skaičiavimo? Neišsaugoti duomenys bus prarasti."
        />

        <Grid container spacing={2}>

          {record.pricing && record.type && record.buildingType ? (
            <Grid item sm={12} style={{ textAlign: 'right' }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={this.handleSaveForm.bind(this)}
                // className={classes.resetButton}
              >
                Išsaugoti
              </Button>

              <Button
                style={{ marginLeft: 10 }}
                variant="outlined"
                color="primary"
                onClick={this.handleClearForm.bind(this)}
                // className={classes.resetButton}
              >
                Išvalyti skaičiavimo duomenis
              </Button>
            </Grid>
          ) : null}

          <Grid item sm={12}>
            <ProjectDetails
              projects={data.projects}
              currentProject={data.currentProject}
              isCollapsed={isLoadingRecord || data.currentProject != null}
              handleChange={this.handleChange.bind(this)}
              onCreatedNewProject={value => this.handleCreatedNewProject(value)}
            />
          </Grid>

          <Grid item sm={5}>
            <SiteDetails
              handleChange={this.handleChange.bind(this)}
              handleClearForm={this.handleClearForm.bind(this)}
              handleSaveForm={this.handleSaveForm.bind(this)}
              types={data.types}
              versions={data.versions}
              hierarchy={data.hierarchy}

              project={record.project}
              title={record.title}
              description={record.description}
              type={record.type}
              buildingType={record.buildingType}
              unit={record.unit}
              amount={record.amount}
              pricing={record.pricing}
              
              style={{ display: data.currentProject ? 'block' : 'none' }}
            />
          </Grid>

          <Grid item sm={7}>
            {showMaterials ? (
              <MaterialChooser
                materials={data.materials}
                initialMaterials={data.initialMaterials}
                recordMaterials={record.materials}
                handleChange={this.handleChange.bind(this)}
              />
            ) : null}
          </Grid>

          <Grid item sm={5}>
            {(showMaterials || skipMaterials) && showResults ? (
              <div>
                <CorrectionValues
                  corrections={record.corrections}
                  handleChange={this.handleChange.bind(this)}
                />

                <CoefficientTable
                  coefficients={record.coefficients}
                  handleChange={this.handleChange.bind(this)}
                />
              </div>
            ) : null}
          </Grid>
          <Grid item sm={7}>
            {(showMaterials || skipMaterials) && showResults ? (
              <CalculatedResults
                totals={record.totals}
                unit={record.unit}
                wallType={record.wallType}
                type={record.type}
                usageStartDate={record.usageStartDate}
                handleChange={this.handleChange.bind(this)}
                wallTypes={this.getWallTypes()}
                buildingType={record.buildingType}
              />
            ) : null}
          </Grid>
          <Grid item sm={12}>
            {(showMaterials || skipMaterials) && showResults && record.percents.length ? (
              <CalculatedTable
                percents={record.percents}
                totals={record.totals}
                unit={record.unit}
                handleChange={this.handleChange.bind(this)}
              />
            ) : null}
          </Grid>

          {record.pricing && record.type && record.buildingType && (showMaterials || skipMaterials) && showResults && record.percents.length ? (
            <Grid item sm={12} style={{ textAlign: 'right' }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={this.handleSaveForm.bind(this)}
                // className={classes.resetButton}
              >
                Išsaugoti
              </Button>

              <Button
                style={{ marginLeft: 10 }}
                variant="outlined"
                color="primary"
                onClick={this.handleClearForm.bind(this)}
                // className={classes.resetButton}
              >
                Išvalyti skaičiavimo duomenis
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </div>
    )
  }

  render() {
    const { isLoading } = this.props;

    return (
      <RecoveryPrice
        title="Atkuriamoji vertė"
        isContentLoading={isLoading}
      >
        {this.renderContent()}
      </RecoveryPrice>
    );
  }
}

const mapStateToProps = ({ recoveryPriceForm, dispatch }) => {
  return {
    ...recoveryPriceForm,
    dispatch,
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(RecoveryPriceForm);
