import OrdersList from './components/OrdersList';
import OrdersForm from './components/OrdersForm';

export default [
  {
    key: 'list',
    component: OrdersList,
    path: '/orders',
    public: false,
  },
  {
    key: 'create',
    component: OrdersForm,
    path: '/orders/create',
    public: false,
  },
  {
    key: 'edit',
    component: OrdersForm,
    path: '/orders/:id',
    public: false,
  }
];