import React, { Component } from 'react';
import _ from 'lodash';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  Grid,
} from '@material-ui/core';

// import {
//   Add as AddIcon,
// } from '@material-ui/icons';

import { CommonPanel } from 'components';

import styles from './styles';

class PermissionTable extends Component {

  handlePermissionChange({ target }) {
    const { onPermissionChange } = this.props;
    const { name, checked } = target;
    console.log('permission changed', { name, checked });

    const pair = name.split(';');

    if (pair.length == 2) {
      const value = {
        group: pair[0],
        permission: pair[1],
        isAdding: checked,
      };

      onPermissionChange(value);
    }
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    console.log('submit');

    onSubmit();
  }

  renderTable() {
    const { classes, groups, categories, selected } = this.props;
    const filteredCategories = _.filter(categories, category => category.permissions.length);
    const columnCount = groups.length;

    console.log({ filteredCategories, groups, selected });

    return (
      <Table size="small" className={classes.table}>
        {/* Headers */}
        <TableHead>
          <TableRow>
            <TableCell>Funkcionalumas</TableCell>
            {_.map(groups, group => <TableCell key={group._id} component="th" scope="row" className={classes.textCenter}>{group.title}</TableCell>)}
          </TableRow>
        </TableHead>

        {/* Rows */}
        <TableBody>
          {/* Category */}
          {_.map(filteredCategories, category => (
            <React.Fragment key={category._id}>
              <TableRow>
                <TableCell colSpan={columnCount + 1}>
                  <strong>{category.title}</strong>
                </TableCell>
              </TableRow>

              {/* Category permissions */}
              {_.map(category.permissions, permission => (
                <TableRow key={permission._id}>
                  <TableCell>
                    {permission.title}
                  </TableCell>
                  {_.map(groups, (group) => (
                    <TableCell className={classes.textCenter} key={permission._id + '-' + group._id}>
                      <Checkbox
                        checked={_.includes(selected[group._id], permission._id)}
                        onChange={this.handlePermissionChange.bind(this)}
                        name={group._id + ';' + permission._id}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    )
  }

  render() {

    const { classes, groups, categories } = this.props;
    console.log({ groups, categories });

    if (!groups.length || !categories.length) {
      return <h3>Nėra įvestų teisių grupių ir/ar kategorijų</h3>
    }

    return (
      <div className={classes.root}>
        <Grid justify="space-between" container spacing={3} className={classes.actions}>
          <Grid item />
          <Grid item>
            <Button variant="contained" color="primary" onClick={this.handleSubmit.bind(this)}>
              Išsaugoti
            </Button>
          </Grid>
        </Grid>
        
        <CommonPanel noPadding>
          {this.renderTable()}
        </CommonPanel>
      </div>
    )
  }
}

export default withStyles(styles)(PermissionTable);