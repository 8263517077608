import accountConstants from './_constants';

const initialState = {
  // Shared props
  isLoading: false,
  error: null,

  //  List
  list: {
    limit: 10,
    accounts: [],
    selectedAccounts: [],
  },

  // Single
  single: {
    account: null,
    permissionGroups: null,
  }
};

export const accounts = (state = initialState, action) => {
  switch (action.type) {
    case accountConstants.SET_VALUE:
      return {
        ...state,
        single: {
          ...state.single,
          account: {
            ...state.single.account,
            [action.data.name]: action.data.value,
          }
        }
      };

    case accountConstants.UPDATE_REQUEST:
    case accountConstants.CREATE_REQUEST:
    case accountConstants.GETONE_REQUEST:
    case accountConstants.GETALL_REQUEST:
    case accountConstants.DELETE_ONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case accountConstants.GETALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          ...state.list,
          accounts: action.accounts,
        }
      };
    case accountConstants.CREATE_FAILURE:
    case accountConstants.GETONE_FAILURE:
    case accountConstants.GETALL_FAILURE:
    case accountConstants.UPDATE_FAILURE:
    case accountConstants.DELETE_ONE_FAILURE:
    case accountConstants.FAILURE:
      return { 
        ...state,
        isLoading: false,
        error: action.error,
      };
    case accountConstants.GETONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        single: {
          ...state.single,
          account: action.account,
        }
      }
    case accountConstants.CREATE_SUCCESS:
    case accountConstants.UPDATE_SUCCESS:
    case accountConstants.DELETE_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case accountConstants.RESET_FORM:
      return {
        ...state,
        isLoading: false,
        single: {
          ...initialState.single,
        }
      }
    
    case accountConstants.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state
  }
}