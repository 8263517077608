import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { DataTable, CommonPanel } from 'components';
import ServicePackages from 'views/ServicePackages';
import { timestampToDate, twoDecimals, canAccess } from 'utils/helpers';


import styles from './styles';

class Container extends React.Component {
  state = {
    isAdmin: false,
  }

  componentDidMount() {
    const isAdmin = canAccess('administrator.layout');
    this.setState({ isAdmin });

    this.props.store.getAll();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  prepareTableColumns() {
    
    const columns = [
      'ID',
      'LINK',
      'Užsakymas',
      'Būsena',
      'Įmonė',
      'Sukūrė',
      'Licencijos',
      'Terminas',
      'Įvestas',
      'Lic. galioja nuo',
      'Lic. galioja iki',
      'Suma, EUR',
    ];

    return columns;
  }

  prepareTableData(records) {
    const data = _.map(records, record => {
      let row = [];

      const showDates = record.status !== 'draft' && record.status !== 'pending';

      row.push(record._id);
      row.push(`/orders/${record._id}`);
      row.push(record.number);
      row.push(this.renderStatus(record.status))
      row.push(record.company ? record.company.name : '-');
      row.push(this.renderOwner(record));
      row.push(this.renderLicenceCount(record));
      row.push(`${record.months} mėn.`);
      row.push(timestampToDate(record.createdAt));
      row.push(showDates ? timestampToDate(record.date) : null);
      row.push(showDates ? timestampToDate(record.expiresAt) : null);
      row.push(twoDecimals(record.totals.grandTotal));
      
      return row;
    });

    return data;
  }

  renderOwner(record) {
    return (
      <span title={_.get(record, 'owner.user.email', '-')}>
        {_.get(record, 'owner.user.fullName', '-')}
      </span>
    )
  }

  renderLicenceCount({ totalLicences, licences }) {
    const usedLicences = licences.length;

    return (
      <span
        title={`Naudojama ${usedLicences} lic. iš ${totalLicences}`}
      >
        {`${usedLicences} / ${totalLicences}`}
      </span>
    )
  }

  renderStatus(value) {
    let status = value;
    let color = 'black';

    switch (value) {
      case 'pending':
        status = 'Laukiama administratoriaus patvirtinimo';
        color = 'blue';
        break;

      case 'expired':
        status = 'Nebegalioja';
        color = 'red';
        break;
      
      case 'paid':
        status = 'Aktyvus';
        color = 'green';
        break;

      case 'draft':
        status = 'Nepateiktas';
        color = 'black';
        break;

      default:
        status = value || '-';
        break;
    }

    return <span style={{ fontWeight: 'bold', color }}>{status}</span>;
  }

  renderSummary() {
    const { summary } = this.props.store;
    const { isAdmin } = this.state;

    if (isAdmin) {
      return (
        <Typography variant="h6" style={{ textAlign: 'center', padding: 10 }}>
          Aktyvios licencijos rodomos tik įmonės administratoriui
        </Typography>
      )
    }

    if (!summary.length) {
      return (
        <Typography variant="h6" style={{ textAlign: 'center', padding: 10 }}>
          Nėra aktyvių licencijų
        </Typography>
      )
    }

    const columns = [
      'ID',
      'LINK',
      'Paslauga',
      'Paketas',
      'Funkcionalumas',
      'Užsakymas',
      'Terminas',
      'Galioja iki',
      'Viso lic.',
      'Priskirtos lic.',
      'Nepriskirtos lic.',
    ];

    let data = [];
    
    _.forEach(summary, service => {
      _.forEach(service.packages, servicePackage => {
        _.forEach(servicePackage.items, item => {
          let row = [];

          const freeLicences = _.toNumber(item.totalLicences) - _.toNumber(item.usedLicences);

          row.push(item._id);
          row.push(`/orders/${item.orderId}`);
          row.push(service.title);
          row.push(servicePackage.title);
          row.push(item.details.join('. '));
          row.push(item.orderNumber);
          row.push(`${item.months} mėn.`);
          row.push(timestampToDate(item.expiresAt));
          row.push(item.totalLicences);
          row.push(item.usedLicences);
          row.push(freeLicences);

          data.push(row);
        });
      });
    });

    return (
      <DataTable 
        key={data.length}
        columns={columns}
        data={data}
        rootStyle={{ border: '0px' }}
      />
    )
  }

  render() {
    const { classes, store } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(store.records);

    return (
      <ServicePackages title="Paslaugų užsakymai">
        <div className={classes.wrap}>
          <DataTable 
            key={data.length}
            columns={columns}
            data={data}
            createUrl="/orders/create"
          />
        </div>

        <div className={classes.wrap} style={{ marginTop: 20 }}>
          <CommonPanel
            title="Aktyvios licencijos"
            noPadding
          >
            {this.renderSummary()}
          </CommonPanel>
        </div>
      </ServicePackages>
    )
  }
}

export default compose(withStyles(styles))(observer(Container));