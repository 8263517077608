import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
  Button,
} from '@material-ui/core';

import { canAccess, toast, getCurrentAccount } from 'utils/helpers';
import styles from './styles';

import {
  CommonPanel,
} from 'components';

import Users from 'views/Users';
import UserForm from './components/UserForm';
import UserAccounts from './components/UserAccounts';
import AccountDetails from './components/AccountDetails';

import { getOne, resetForm, updateOne, createOne, getCurrent, hardDelete } from 'views/Users/_actions';

class UserDetails extends Component {
  state = {
    isProfile: false,
  }

  componentDidMount() {
    const { location, match, dispatch } = this.props;

    if (match.params.id) {
      // Comes from user list
      dispatch(getOne(match.params.id));
    } else if (location.pathname == '/profile') {
      this.setState({ isProfile: true });

      // Comes from current user profile link
      dispatch(getCurrent());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetForm());
  }

  renderUserDetails(user) {

    return (
      <UserForm onSubmit={values => this.onSubmit(values)} user={user} />
    );
  }

  onSubmit(values) {
    const { dispatch } = this.props;

    if (values._id) {
      // Update
      dispatch(updateOne(values, this.state.isProfile));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  onHardDelete() {
    const { dispatch, single } = this.props;
    const { email } = single.user;

    const emailConfirm = window.prompt('Norėdami patvirtinti ištrynima įvesite vartotojo el. pašto adresą');

    if (email == emailConfirm) {
      dispatch(hardDelete(single.user._id));
    } else if (emailConfirm) {
      toast('El. pašto adresai nesutampa', 'error');
    }
  }

  submitForm() {
    document
      .getElementById('userForm')
      .dispatchEvent(new Event('submit', { cancelable: true }));
  }

  render() {
    const { classes, isLoading, single, history } = this.props;
    const { user } = single;
    const currentAccount = getCurrentAccount();

    return (
      <Users title={user ? `Vartotojas ${user.firstName} ${user.lastName}` : 'Naujas vartotojas'}>
        
        <div className={classes.content}>
          {isLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.wrap}>
              {/* User form */}
              <CommonPanel 
                noPadding
                footer={(
                  <React.Fragment>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={event => this.submitForm(event)}
                    >
                      Išsaugoti
                    </Button>

                    {user && canAccess('administrator.layout') ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.onHardDelete.bind(this)}
                        style={{ marginLeft: 10, backgroundColor: 'red', float: 'right' }}
                      >
                        Pašalinti vartotoją ir susijusius įrašus
                      </Button>
                    ) : null}
                  </React.Fragment>
                )}
              >
                {this.renderUserDetails(user)}
              </CommonPanel>

              <div className={classes.accountsPanel}>
                <AccountDetails accountId={currentAccount._id} />
              </div>

              {/* Accounts (related) */}
              {user ? (
                <div className={classes.accountsPanel}>
                  <UserAccounts
                    current={user}
                    records={[...user.accounts]}
                  />
                </div>
              ) : null}

              {user ? (
                <div className={classes.btnJoin}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.push('/companies/join')}
                  >
                    Prisijungti prie įmonės / Registruoti įmonę
                  </Button>
                </div>
              ) : null}
            </div>
          )}
        </div>

      </Users>
    )
  }
}

const mapStateToProps = ({ users, dispatch }) => {
  return {
    ...users,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter,
)(UserDetails);