import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withStyles,
  CircularProgress,
  Typography,
  Grid,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import Norms from 'views/Norms';
import { CommonPanel, NormSearch, NormVersionPicker } from 'components';


import { twoOrMoreDecimals } from 'utils/helpers';
import styles from './styles';

class NormTreeTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paddingTop: 86,
    }

    this.elRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);

    // this.loadData();
  }

  async loadData() {
    await this.props.store.loadData();

    // Set padding for mobile view
    try {
      const paddingTop = window.document.querySelector('.sticky-table-header').offsetHeight;
      this.setState({ paddingTop });
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
    
    this.props.store.reset();
  }

  handleScroll = _.debounce(() => {
    const node = this.elRef.current;

    if (node !== undefined) {
      const header = node.querySelector('.sticky-table-header');
      const fromViewTop = node.getBoundingClientRect().top;
      const topbarHeight = document.querySelector('.top-navbar').offsetHeight;

      node.style.paddingTop = `${header.offsetHeight}px`;

      if (fromViewTop < 0) {
        header.style.top = Math.abs(fromViewTop - topbarHeight) + 'px';
      } else {
        header.style.top = '0px';
      }
    }
  })

  onTreeRowClick(item) {
    this.props.store.handleExpand(item);
  }

  onParameterRowClick(item) {
    this.props.store.handleParameterExpand(item);
  }

  onSearchResultSelected({ codePath }) {
    
    this.props.store.expandPath(codePath.split(';'));
  }

  // renderDetailsRow({ details, level }) {
  renderDetailsRow({ code, details }) {
    const { classes } = this.props;
    
    const contents = (
      <React.Fragment>
        <div style={{ marginBottom: 10, textAlign: 'center' }}>
          <Typography variant="h6">
            {details.title}
          </Typography>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Resursai"
            collapsible
            collapsed={details.resources.length === 0}
            disabled={details.resources.length === 0}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.resources.length ? (
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>Kodas</th>
                    <th>Pavadinimas</th>
                    <th>Matas</th>
                    <th>Norma</th>
                    <th>Kaina</th>
                    <th>Suma</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(details.resources, resource => (
                    <tr key={`${resource.code}-resource`}>
                      <td>{resource.code}</td>
                      <td>{resource.title}</td>
                      <td>{resource.unit}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.norm)}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.price)}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.amount)}</td>
                    </tr>
                  ))}

                  {details.parameters.length ? (
                    <React.Fragment>
                      <tr>
                        <td colSpan={6} className={classes.parametersHeaderRow}>
                          Pokyčio parametrai
                        </td>
                      </tr>

                      {_.map(details.parameters, parameter => (
                        <React.Fragment key={`${parameter.code}-parameter`}>
                          <tr onClick={() => this.onParameterRowClick(parameter)}>
                            <td className={classes.parameterCode}>{parameter.code}</td>
                            <td colSpan={5}>{parameter.title}</td>
                          </tr>
                          {parameter.resources && parameter.expanded ? (
                            _.map(parameter.resources, resource => (
                              <tr key={`${parameter.code}-${resource.code}-resource`}>
                                <td className={classes.textCenter}>{resource.code}</td>
                                <td>{resource.title}</td>
                                <td>{resource.unit}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.norm)}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.price)}</td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.amount)}</td>
                              </tr>
                            ))
                          ) : null}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : null}
                </tbody>
              </table>
            ) : null}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Kainos sudėtis"
            collapsible
            collapsed
            disabled={details.prices.length === 0}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.prices.length ? (
              <table className={this.props.classes.table}>
                <thead>
                  <tr>
                    <th>Kainos sudėties aprašymas</th>
                    <th>Kaina</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(details.prices, price => (
                    <tr
                      key={`${price.title}-price`}
                      className={_.some([
                        'statybos kaina',
                        'tiesioginės išlaidos',
                        'netiesioginės išlaidos',
                        'darbo vieneto kaina',
                      ], t => price.title.toLowerCase().includes(t)) ? classes.rowPriceTotal : null}
                    >
                      <td>{price.title}</td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(price.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Darbų sudėtis"
            collapsible
            collapsed
            disabled={!details.details}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.details}
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Koeficientai"
            collapsible
            collapsed
            disabled={!details.coefficients}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.coefficients && details.coefficients.length ? (
              <>
                <Typography variant="caption" display="block" style={{ textAlign: 'right'}}>
                  K1 - darbo sąnaudos, 
                  K2 - mechanizmai, 
                  K3 - medžiagos
                </Typography>

                <table className={this.props.classes.table}>
                  <thead>
                    <tr>
                      <th>Koeficientų taikymo sąlygos</th>
                      <th>K1</th>
                      <th>K2</th>
                      <th>K3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(details.coefficients, (coefficient, coefIdx) => (
                      <tr key={`${coefIdx}-coefficient`}>
                        <td>{coefficient.title}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k1)}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k2)}</td>
                        <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(coefficient.k3)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : null}
          </CommonPanel>
        </div>

      </React.Fragment>
    );

    return (
      <tr
        key={`${code}-details`}
        className={[this.props.classes.detailsRow, 'tree-details'].join(' ')}
      >
        <td />
        <td colSpan={3}>
          {contents}
        </td>
      </tr>
    )
  }

  renderFirstColumn({ code, lastLevel, expanded }) {
    const { classes } = this.props;
    return (
      <td className={classes.firstColumn} style={{ width: '20%' }}>
        {!lastLevel && <span className={classes.expander}>{expanded ? '-' : '+'}</span>}
        {code}
      </td>
    )
  }

  renderTableRow(item, isLast) {
    let rows = [];

    const mainRow = (
      <tr
        key={item.code}
        onClick={() => this.onTreeRowClick(item)}
        className={[
          item.expanded ? [this.props.classes.expandedRow, 'tree-expanded'].join(' ') : '', 
          `code-${item.code}`.replace('#', ''), 
          `tree-node level-${item.level} ${isLast ? 'level-last' : ''}`,
        ].join(' ')}
      >
        {this.renderFirstColumn(item)}
        <td>{item.title}</td>
        <td style={{ width: '10%', textAlign: 'right' }}>{item.price ? twoOrMoreDecimals(item.price) : null}</td>
        <td style={{ width: '10%', textAlign: 'center' }}>{item.unit}</td>
      </tr>
    )
    rows.push(mainRow);

    if (item.expanded && !item.details) {
      const childRows = _.map(item.children, (child, idx) => this.renderTableRow(child, (idx + 1) === item.children.length && !child.expanded))
      rows = _.concat(rows, childRows);
    } else if (item.expanded && item.details) {
      const detailsRow = this.renderDetailsRow(item);
      rows.push(detailsRow);
    }

    return rows;
  }

  renderData() {
    const { data } = this.props.store;

    const rows = _.map(data, item => this.renderTableRow(item));
    // const rows = _.map(_.keys(data), key => this.renderTableRow(data[key]));

    return rows;
  }

  renderSearch() {
    const { version } = this.props.store;
    return (
      <NormSearch
        onSelect={this.onSearchResultSelected.bind(this)}
        inputStyle={{ marginTop: 5 }}
        version={version ? version.code : null}
      />
    );
  }

  renderVersions() {
    const { store } = this.props;

    return (
      <NormVersionPicker
        value={store.version}
        name="version"
        onChange={({ value }) => store.setVersion(value)}
        label="Versija"
        style={{ marginTop: 5, background: '#fff' }}
        defaultFirst
      />
    );
  }

  render() {
    const { classes, store } = this.props;

    return (
      <Norms title="Normatyvų paieška">
        <div className={classes.wrap}>

          <div ref={this.elRef} className="sticky-table-wrap" style={{ paddingTop: this.state.paddingTop }}>
            
            <div className="sticky-table-header">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      {this.renderSearch()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {this.renderVersions()}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.stickyTableWrap}>
                  <table className={classes.table} style={{ tableLayout: 'fixed' }}>
                    <thead>
                      <tr style={{ height: 46 }}>
                        <th style={{ width: '20%' }}>Darbo kodas</th>
                        <th>Pavadinimas</th>
                        <th style={{ width: '10%' }}>Darbo vnt. kaina</th>
                        <th style={{ width: '10%' }}>Matas</th>
                      </tr>
                    </thead>
                  </table>
                </Grid>
              </Grid>
            </div>

            <div className={classes.tableWrap}>
              <table className={[classes.table, 'norms-tree'].join(' ')} style={{ tableLayout: 'fixed' }}>
                <tbody>
                  {this.renderData()}
                </tbody>
              </table>
            </div>
          </div>

          {store.loading ? (
            <div className={classes.loaderWrap}>
              <CircularProgress />
            </div> 
          ) : null}
        </div>
      </Norms>
    )
  }
}

export default compose(withStyles(styles))(observer(NormTreeTableContainer));