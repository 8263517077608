import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Auth as AuthLayout } from 'layouts';
import styles from './styles';
import { 
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  withStyles,
} from '@material-ui/core';

import { register, checkEmail, termsAgreed, activate } from 'views/Users/_actions';

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    isActivating: false,
  };

  componentWillMount() {
    if (this.props.app.user) {
      this.props.history.push('/');
    }

    const { match } = this.props;
    if (match.params.hash) {
      this.setState({ isActivating: true });
      
      const hash = match.params.hash;
      this.props.dispatch(activate(hash));
    }
  }

  handleFieldChange = (field, value) => {
    this.setState({ 
      [field]: value,
    });
  }

  handleSignUp() {
    const { dispatch } = this.props;
    const { email, password, firstName, lastName } = this.state;

    if (email && password && firstName && lastName) {
      dispatch(register({ email, password, firstName, lastName }));
    }
  }

  handleEmailCheck() {
    const { dispatch } = this.props;
    const { email } = this.state;

    if (!email) {
      return;
    }
    console.log({props: this.props});
    dispatch(checkEmail(email));
  }


  render() {
    const { classes } = this.props;
    const { email, password, passwordConfirm, firstName, lastName } = this.state;

    if (this.state.isActivating) {
      return (
        <AuthLayout
          title="Aktyvuojama..."
          loading
        />
      )
    }

    return (
      <AuthLayout title="Registracija">
        <Typography variant="body1" style={{ marginTop: 5, opacity: 0.8 }}>
          Nerekomenduojame naudoti interneto naršyklių versijų, kurių atnaujinimus gamintojas nutraukė (pvz.: Internet Explorer)
        </Typography>

        <div className={classes.root}>
          <div className={classes.fields}>
            {!this.props.app.canRegister ? (
              <React.Fragment>
                <TextField
                  className={classes.textField}
                  label="El. p. adresas"
                  name="email"
                  onChange={event =>
                    this.handleFieldChange('email', event.target.value)
                  }
                  type="text"
                  value={email}

                  autoComplete="off"
                />

                <FormControlLabel
                  style={{ marginTop: 10 }}
                  control={
                    <Checkbox
                      checked={this.props.app.termsAgreed}
                      onChange={() => this.props.dispatch(termsAgreed(!this.props.app.termsAgreed))}
                      value="true"
                      color="primary"
                    />
                  }
                  label={(
                    <Typography>
                      Su BIMGates <a target="_blank" href="https://bimgates.lt/naudojimosi-teises-ir-taisykles/">taisyklėmis susipažinau ir sutinku</a>. Šis patvirtinimas reiškia elektroninės sutarties su paslaugų teikėju sudarymą. Atsisakymo tvarka įvardinta prie sąlygų.
                    </Typography>
                  )}
                />

                <Button
                  className={classes.SignUpButton}
                  color="primary"
                  onClick={() => this.handleEmailCheck()}
                  size="large"
                  variant="contained"
                  disabled={!this.props.app.termsAgreed}
                >
                  Toliau
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TextField
                  className={classes.textField}
                  label="Vardas"
                  name="firstName"
                  onChange={event =>
                    this.handleFieldChange('firstName', event.target.value)
                  }
                  type="text"
                  value={firstName}
                  autoComplete="off"
                />
                <TextField
                  className={classes.textField}
                  label="Pavardė"
                  name="lastName"
                  onChange={event =>
                    this.handleFieldChange('lastName', event.target.value)
                  }
                  type="text"
                  value={lastName}
                  autoComplete="off"
                />
                <TextField
                  className={classes.textField}
                  label="El. p. adresas"
                  name="email"
                  disabled
                  onChange={event =>
                    this.handleFieldChange('email', event.target.value)
                  }
                  type="text"
                  value={email}
                  autoComplete="off"
                />
                <TextField
                  className={classes.textField}
                  label="Slaptažodis"
                  name="password"
                  onChange={event =>
                    this.handleFieldChange('password', event.target.value)
                  }
                  type="password"
                  value={password}
                  autoComplete="new-password"
                />
                <TextField
                  className={classes.textField}
                  label="Slaptažodžio patvirtinimas"
                  name="passwordConfirm"
                  onChange={event =>
                    this.handleFieldChange('passwordConfirm', event.target.value)
                  }
                  type="password"
                  value={passwordConfirm}
                  autoComplete="new-password-confirm"
                />
                <Button
                  className={classes.SignUpButton}
                  color="primary"
                  onClick={() => this.handleSignUp()}
                  size="large"
                  variant="contained"
                >
                  Registruotis
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter,
)(SignUp);
