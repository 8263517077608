import React, { Component } from 'react';
import _ from 'lodash';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { CommonPanel, FileLink } from 'components';

import styles from './styles';

class NtkResults extends Component {

  renderMaterials({ materials, variants, engineeringRanges }) {
    if (engineeringRanges) {
      return _.map(_.keys(engineeringRanges), range => {
        return (
          <TableRow key={range}>
            <TableCell className="headcol">{range}</TableCell>
            {_.map(variants, variant => {
              return (
                <TableCell key={`${range}-${variant}`}>{engineeringRanges[range][variant]}</TableCell>
              );
            })}
          </TableRow>
        );
      })
    }

    if (materials) {
      return _.map(materials, ({ type, values }) => (
        <TableRow key={type}>
          <TableCell className="headcol">{type}</TableCell>
          <TableCell colSpan={variants.length}>{values.join(', ').toLowerCase()}</TableCell>
        </TableRow>
      ));
    }
  }

  renderTable() {
    const { data, materials, buildingType, type, combinations, files, classes } = this.props;
    const isBuilding = type.toLowerCase() != 'ntk inzineriniai';

    // Collect unique variants (defines column count)
    const variants = _.map(data.variants, item => item.range);

    if (!variants || variants.length == 0) {
      return (
        <div className={classes.noData}>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            Nėra duomenų pagal pasirinktus parametrus
          </Typography>
        </div>
      );
    }

    let engineeringRanges = {};
    // Get engineering ranges (for engineering structures only)
    if (_.filter(combinations, item => item.type).length) {
      _.forEach(combinations, ({ code, type, value }) => {
        if (!engineeringRanges[type]) {
          engineeringRanges[type] = {};
        }

        engineeringRanges[type][code] = value;
      });
    } else {
      engineeringRanges = null;
    }    

    let prices = [];
    let coefficients = {};
    let correctionValues = {};
    let constructions = {};
    let count = 0; // To prevent non-unique index error in rendering

    _.forEach(variants, (variant) => {
      // Get prices per variant
      const item = _.find(data.variants, { range: variant });
      const price = item.price;
      const tableSuffix = item.tableSuffix;
      prices.push({ variant, price, tableSuffix });

      // Get coefficients per variant
      _.forEach(item.coefficients, ({ title, value }) => {
        if (value) {
          if (coefficients[title]) {
            coefficients[title].push({ variant, value, key: count });
          } else {
            coefficients[title] = [{ variant, value, key: count }];
          }
          count++;
        }
      });

      // Get correction values per variant
      _.forEach(item.coefficients, ({ title, price }) => {
        if (price) {
          if (correctionValues[title]) {
            correctionValues[title].push({ variant, price, key: count });
          } else {
            correctionValues[title] = [{ variant, price, key: count }];
          }
          count++;
        }
      });

      // Get constructions per variant
      _.forEach(item.constructions, ({ title, value }) => {
        if (constructions[title]) {
          constructions[title].push({ variant, value, key: count });
        } else {
          constructions[title] = [{ variant, value, key: count }];
        }
        count++;
      });
    });

    return (
      // <div style={{ position: 'relative', }}>
      <div className="ntk-table">
        <Table size="small" className={classes.table}>
          <TableBody>
            {/* Selection details */}
            <TableRow className={classes.rowSeparator}>
              <TableCell className="headcol bg-grey">Statinio paskirtis</TableCell>
              <TableCell colSpan={variants.length}>
                {buildingType ? (buildingType.titleFull || buildingType.title) : '-'}
              </TableCell>
            </TableRow>

            {/* Pricing table */}
            {isBuilding ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">Kainyno nuoroda</TableCell>
                <TableCell colSpan={variants.length}>{data.pricingTable}</TableCell>
              </TableRow>
            ) : (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">Kainyno nuoroda {data.pricingTable}-</TableCell>
                {_.map(prices, ({ tableSuffix }) => <TableCell key={tableSuffix}>{tableSuffix}</TableCell>)}
              </TableRow>
            )}

            {files ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell  className="headcol bg-grey">&nbsp;</TableCell>
                <TableCell colSpan={variants.length}>
                  {_.map(files, ({ fileName, contentType, content }) => (
                    <span key={fileName} style={{ marginRight: 10 }}>
                      <FileLink
                        fileName={fileName}
                        contentType={contentType}
                        content={content}
                      />
                    </span>
                  ))}
                </TableCell>
              </TableRow>
            ) : null}

            {/* Constructions */}
            <TableRow className={classes.rowSeparator}>
              <TableCell className="headcol bg-grey">Konstrukcijos</TableCell>
              <TableCell colSpan={variants.length}>&nbsp;</TableCell>
            </TableRow>

            {this.renderMaterials({ materials, variants, engineeringRanges })}
            
            {/* Amounts and prices */}
            {isBuilding ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">
                  Statybos kaina pagal statinio tūrį
                </TableCell>
                <TableCell colSpan={variants.length}>&nbsp;</TableCell>
              </TableRow>
            ) : null}

            {isBuilding ? (
              <TableRow>
                <TableCell  className="headcol">Statinio tūris, m<sup>3</sup></TableCell>
                {_.map(prices, ({ variant }) => (
                  <TableCell key={variant}>{variant}</TableCell>
                ))}
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell className="headcol"><strong>Statybos kaina, EUR/{buildingType.unit}</strong></TableCell>
              {_.map(prices, ({ variant, price }) => (
                <TableCell key={variant + price}><strong>{price}</strong></TableCell>
              ))}
            </TableRow>

            {/* Coefficients */}
            {_.keys(coefficients).length ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">Statinio kainos pataisos koeficientai</TableCell>
                <TableCell colSpan={variants.length}>&nbsp;</TableCell>
              </TableRow>
            ) : null}

            {_.map(_.keys(coefficients), (title) => (
              <TableRow key={title}>
                <TableCell className="headcol">{title}</TableCell>
                {_.map(coefficients[title], ({ value, key}) => (
                  <TableCell key={key}>{value}</TableCell>
                ))}
              </TableRow>
            ))}

            {/* Correction values */}
            {_.keys(correctionValues).length ? (
              <TableRow className={classes.rowSeparator}>
                <TableCell className="headcol bg-grey">Statinio kainos pataisos rodikliai</TableCell>
                <TableCell colSpan={variants.length}>&nbsp;</TableCell>
              </TableRow>
            ) : null}

            {_.map(_.keys(correctionValues), (title) => (
              <TableRow key={title}>
                <TableCell  className="headcol">{title}</TableCell>
                {_.map(correctionValues[title], ({ key, price }) => (
                  <TableCell key={key}>{price}</TableCell>
                ))}
              </TableRow>
            ))}

            {/* Constructions */}
            {_.keys(constructions).length ? (
              isBuilding ? (
                <TableRow className={classes.rowSeparator}>
                  <TableCell className="headcol bg-grey">Konstrukcijos</TableCell>
                  <TableCell colSpan={variants.length}>Konstrukcijų lyginamasis svoris kainoje (visa kaina=100%)</TableCell>
                </TableRow>
              ) : (
                <TableRow className={classes.rowSeparator}>
                  <TableCell className="headcol bg-grey">Konstrukcijos</TableCell>
                  <TableCell colSpan={variants.length}>&nbsp;</TableCell>
                </TableRow>
              )
            ) : null}

            {_.map(_.keys(constructions), (title) => (
              <TableRow key={title}>
                <TableCell  className="headcol">{title}</TableCell>
                {isBuilding ? (
                  _.map(constructions[title], ({ key, value }) => (
                    <TableCell key={key}>{value}</TableCell>
                  ))
                ) : (
                  <TableCell  colSpan={variants.length} />
                )}
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </div>
      // </div>
    );
  }

  render() {
    return (
      <CommonPanel
        title="Nekilnojamojo turto atkūrimo kaštų (statybinės vertės) kainynas"
        noPadding
      >
        {this.renderTable()}
      </CommonPanel>
    );
  }
}

export default withStyles(styles)(NtkResults);
