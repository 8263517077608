import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import { Form } from 'react-final-form';

import styles from './styles';
import fields from './fields';

import { CommonPanel } from 'components';
import { FormField } from 'components';
import Classifier from 'views/Classifier';
import { getOne, updateOne, createOne, resetForm } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { classifierForm } from './_reducers';
store.injectReducer('classifierForm', classifierForm);


class ClassifierForm extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;

    if (match.params.id) {
      dispatch(getOne(match.params.id));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetForm());
  }


  validate(values) {
    let errors = {};

    if (!values.code) {
      errors.code = 'Required';
    }

    if (!values.title) {
      errors.title = 'Required';
    }

    return errors;
  }

  onSubmit(values) {
    const { dispatch } = this.props;

    if (values._id) {
      // Update
      dispatch(updateOne(values));
    } else {
      // Create
      dispatch(createOne(values));
    }
  }

  submitForm() {
    document
      .getElementById('classifierForm')
      .dispatchEvent(new Event('submit', { cancelable: true }));
  }


  render() {
    const { classes, record, isLoading } = this.props;
    console.log(record, isLoading);
   

    return (
      <Classifier
        title="Klasifikatoriaus forma"
      >
        
        <CommonPanel>
          <Form
            initialValues={record}
            onSubmit={this.onSubmit.bind(this)}
            validate={this.validate.bind(this)}
            render={({ handleSubmit }) => (
              <form
                id="classifierForm"
                className={classes.form}
                onSubmit={handleSubmit}
              >
                
                {_.map(fields, field => <FormField key={field.name} {...field}/>)}
                
              </form>
            )}
          />

          <Button
            color="primary"
            variant="contained"
            onClick={event => this.submitForm(event)}
          >
            Save
          </Button>

        </CommonPanel>

      </Classifier>
    );
  }
}

const mapStateToProps = ({ classifierForm, dispatch }) => {
  return { 
    ...classifierForm,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ClassifierForm);