import RecoveryPriceList from './components/RecoveryPriceList';
import RecoveryPriceForm from './components/RecoveryPriceForm';

export default [
  {
    key: 'list',
    component: RecoveryPriceList,
    path: '/recovery-price',
    public: false,
  },
  {
    key: 'create',
    component: RecoveryPriceForm,
    path: '/recovery-price/create',
    public: false,
  },
  {
    key: 'edit',
    component: RecoveryPriceForm,
    path: '/recovery-price/:id',
    public: false,
  }
];