import _ from 'lodash';

import accountConstants from './_constants';
import { accountService } from './_services';
import { history } from 'utils/history';
import { toast } from 'views/App/_actions';
import { canAccess, getCurrentAccount } from 'utils/helpers';

const listPath = '/accounts';

export const getAll = () => async (dispatch) => {
  dispatch({ type: accountConstants.GETALL_REQUEST });

  try {
    const accounts = await accountService.getAll();

    dispatch({ type: accountConstants.GETALL_SUCCESS, accounts });
  } catch (error) {
    dispatch({ type: accountConstants.GETALL_FAILURE, error });
  }
}

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: accountConstants.GETONE_REQUEST });

  try {
    const account = await accountService.getOne(id, { simple: true });

    dispatch({ type: accountConstants.GETONE_SUCCESS, account });
  } catch (error) {
    dispatch({ type: accountConstants.GETONE_FAILURE, error });
  }
}

export const updateOne = (details) => async (dispatch) => {
  dispatch({ type: accountConstants.UPDATE_REQUEST });
  
  try {
    await accountService.updateOne(details);

    // Updated successfully
    dispatch({ type: accountConstants.UPDATE_SUCCESS });
    history.push(canAccess('accounts.list') ? listPath : '/');
    toast('Duomenys atnaujinti', 'success');
  } catch (error) {
    // Update failed
    dispatch({ type: accountConstants.UPDATE_FAILURE });
    toast('Nepavyko atnaujinti', 'error');
  }
}

export const createOne = (details) => async (dispatch) => {
  dispatch({ type: accountConstants.CREATE_REQUEST });

  try {
    await accountService.createOne(details);

    // account created successfully
    dispatch({ type: accountConstants.CREATE_SUCCESS });
    history.push(listPath);
    toast('Created successfully', 'success');
  } catch (error) {
    // account create failed
    dispatch({ type: accountConstants.CREATE_FAILURE });
    toast('Failed to create', 'error');
  }
}

export const deleteOne = (id) => async (dispatch) => {
  dispatch({ type: accountConstants.DELETE_ONE_REQUEST });

  try {
    await accountService.deleteOne(id);

    dispatch({ type: accountConstants.DELETE_ONE_SUCCESS });
    toast('Paskyra pašalinta', 'success');

    const currentAccount = getCurrentAccount();

    if (id === currentAccount._id) {
      // Deleting current account - logoff
      localStorage.removeItem('jwt');
      localStorage.removeItem('state');
      window.location.href = '/';
    } else {
      history.push(listPath);
    }
  } catch (error) {
    dispatch({ type: accountConstants.DELETE_ONE_FAILURE, error });
  }
}

export const setValue = (data) => (dispatch) => {
  dispatch({ type: accountConstants.SET_VALUE, data });
}

export const resetForm = () => (dispatch) => {
  dispatch({ type: accountConstants.RESET_FORM });
}

export const batchDelete = (ids) => async (dispatch) => {
  dispatch({ type: accountConstants.GETALL_REQUEST });
  
  try {
    _.forEach(ids, async (id) => {
      await accountService.deleteOne(id);
    });

    dispatch({ type: accountConstants.DELETE_SUCCESS });
    toast('Pažymėti įrašai pašalinti', 'success');
  } catch (error) {
    dispatch({ type: accountConstants.FAILURE, error });
    toast('Nepavyko pašalinti', 'error');
  }
}