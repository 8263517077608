import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValueField } from 'components';
import _ from 'lodash';
import numeral from 'numeral';
import {
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Checkbox,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import {
  CommonPanel,
} from 'components';

import styles from './styles';

class CorrectionValues extends Component {

  state = {
    customTitle: '',
    customValue: {
      price: '',
      quantity: '',
    },
  }

  onChange = (name, { field, value }) => {
    console.log('onChange@CorrectionValues', name, value);

    const { corrections, handleChange } = this.props;

    let target = _.find(corrections, { title: name });

    if (target) {
      // Update existing
      target[field] = value;
    } else {
      // Insert new
      corrections.push({
        enabled: true,
        normQuantity: value.quantity,
        quantity: value.quantity,
        price: value.price,
        title: name,
        custom: true,
      });
    }

    handleChange({
      name: 'corrections',
      value: [...corrections],
    });
  };

  onAddCustomClick = () => {
    const { customTitle, customValue } = this.state;
    if (!customTitle || !customValue) {
      return;
    }

    this.onChange(customTitle, { name: customTitle, value: customValue });

    // Reset inputs
    this.setState({
      customTitle: '',
      customValue: {
        price: '',
        quantity: '',
      },
    });
  }

  removeCustom = (item) => {
    const { corrections, handleChange } = this.props;

    _.remove(corrections, { ...item });

    handleChange({
      name: 'corrections',
      value: [...corrections],
    });
  }

  renderTable() {
    const { classes, corrections } = this.props;
    const { customTitle, customValue } = this.state;

    return (
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.correctionValues} colSpan={2}>Pavadinimas</TableCell>

              <TableCell className={classes.correctionValues}>Vnt. kaina</TableCell>
            
              <TableCell className={classes.correctionValues} colSpan={2}>Kiekis</TableCell>
            </TableRow>
          </TableHead>
          {corrections.length ? (
            <TableBody>

              {_.map(corrections, (item) => (
                <TableRow key={`${item.title}`}>
                  <TableCell>
                    <Checkbox
                      checked={item.enabled}
                      color="primary"
                      onChange={(e) => this.onChange(item.title, { field: 'enabled', value: e.target.checked })}
                    />
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                
                  <TableCell>
                    {numeral(item.price).format('0.00')} EUR
                  </TableCell>
                  <TableCell component="td" scope="row">

                    <ValueField
                      style={{ width: '90px' }}
                      name="quantity"
                      value={item.quantity}
                      defaultValue={item.normQuantity}
                      onBlur={({ value }) => this.onChange(item.title, { field: 'quantity', value: value })}
                    />

                  </TableCell>
                  <TableCell>
                    {item.custom ? (
                      <IconButton onClick={() => this.removeCustom(item)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          ) : null}
          <TableFooter>
            <TableRow>

              <TableCell className={classes.customCell} colSpan={2}>
                <TextField
                  className={classes.customTextField}
                  label="Pavadinimas"
                  value={customTitle}
                  onChange={(e) => this.setState({ customTitle: e.target.value })}
                  margin="dense"
                  variant="outlined"
                />
              </TableCell>

              <TableCell className={classes.customCell}>
                <TextField
                  className={classes.textField}
                  type="number"
                  label="Vnt. kaina"
                  value={customValue.price}
                  onChange={(e) => this.setState({
                    customValue: {
                      price: e.target.value,
                      quantity: this.state.customValue.quantity,
                    }
                  })}
                  margin="dense"
                  variant="outlined"
                />
              </TableCell>

              <TableCell className={classes.customCell}>
                <TextField
                  className={classes.textField}
                  type="number"
                  label="Kiekis"
                  value={customValue.quantity}
                  onChange={(e) => this.setState({
                    customValue: {
                      quantity: e.target.value,
                      price: this.state.customValue.price,
                    }
                  })}
                  margin="dense"
                  variant="outlined"
                />
              </TableCell>

              <TableCell>
                <IconButton onClick={this.onAddCustomClick.bind(this)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </TableCell>

            </TableRow>
          </TableFooter>
        </Table>

        <Table className={classes.table} size="small">
        
          <TableBody />
        </Table>

      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <CommonPanel
        className={classes.root}
        title={<FormattedMessage id="buildingCorrectionValues" defaultMessage="Pataisos rodikliai" />}
        noPadding
      >

        <div style={{ overflowX: 'auto' }}>
          {this.renderTable()}
        </div>

      </CommonPanel>
    );
  }
}

export default withStyles(styles)(CorrectionValues);
