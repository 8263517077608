import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import { IntlProvider, addLocaleData } from 'react-intl';
import ltLocaleData from 'react-intl/locale-data/lt';
import enLocaleData from 'react-intl/locale-data/en';
import translations from 'i18n/locales';

import { ThemeProvider } from '@material-ui/styles';
import theme from 'theme';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { history } from 'utils/history';
import { SHOW_LOCALE_ERRORS } from 'utils/config';

// Routes
import Routes from '../../Routes';

addLocaleData(ltLocaleData);
addLocaleData(enLocaleData);

class App extends Component {
  localeErrorHandler(err) {
    if (SHOW_LOCALE_ERRORS) {
      console.error(err);
    }
  }

  render() {
    const { activeLocale } = this.props;
    const messages = translations[activeLocale];

    return (
      <IntlProvider
        locale={activeLocale}
        key={activeLocale}
        messages={messages}
        onError={this.localeErrorHandler.bind(this)}
      >
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Routes />
          </Router>

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { ...app };
}

export default connect(mapStateToProps)(App);